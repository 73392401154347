import React, { FunctionComponent } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Space,
} from 'antd';
import { fullWidthLayout } from '../../config/form';
import { FormProvider, useForm } from 'react-hook-form';
import { usePreservedFormState } from '../../hooks/use-preserved-form-state';
import { TabbedFormProps } from '../../interfaces/tabbed-form.props';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import { FormControl } from '../form-controls/FormControl';
import yup from '../../config/yup';
import { PageWrapper } from '../shared/styled';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import { CreateUserDto } from '@tek-crm/backend/dist/application/user/dto/create-user.dto';
import { UpdateUserDto } from '@tek-crm/backend/dist/application/user/dto/update-user.dto';
import { create, update } from '../../api/users.api';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

const CheckboxGroup = styled(Checkbox.Group)`
  & {
    .ant-checkbox-group-item {
      display: block;
      margin-right: 0;
    }
  }
`;

const createDataProcessor = item => (acc, [domain, attributes]) => {
  if (!Array.isArray(attributes) || attributes.length === 0) {
    return acc;
  }

  for (const attributeName of attributes) {
    let id;

    if (!acc[domain]) {
      acc[domain] = [];
    }

    if (item) {
      const found = item.accessAttributes.find(
        accessAttribute =>
          accessAttribute.name === attributeName &&
          accessAttribute.domain === domain,
      );

      id = found ? found.id : undefined;
    }

    const attributeDto = { id, name: attributeName, domain };

    acc[domain].push(attributeDto);
  }

  return acc;
};

const userToFormData = user => {
  return {
    ...user,
    accessAttributes: user.accessAttributes.reduce((acc, attribute) => {
      if (!acc[attribute.domain]) {
        acc[attribute.domain] = [];
      }

      acc[attribute.domain].push(attribute.name);

      return acc;
    }, {}),
  };
};

const defaultValues = {
  name: '',
  email: '',
  password: '',
  accessAttributes: {
    TechnicalProject: ['view'],
    Act: ['view-psi', 'view-vkt'],
    Supply: ['view'],
    Regulation: ['view'],
    Counterparty: ['view'],
    Tool: ['view'],
    User: ['view'],
  },
};

const schema = yup.object().shape({
  name: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  email: yup
    .string()
    .email('Необходимо указать email')
    .required('Обязательное поле'),
  password: yup
    .string()
    .min(6, 'Длина пароля не может быть меньше 6 символов')
    .max(128, 'Максимальная длина - 128 символов')
    .when('$operation', (operation, schema) =>
      operation === 'create'
        ? schema.required('Необходимо указать пароль')
        : schema,
    ),
});

const useValidationResolver = createYupValidationResolverHook(schema);

export const UserForm: FunctionComponent<TabbedFormProps> = ({
  show,
  item,
  closeTab,
  tabKey,
  refresh,
}) => {
  const resolver = useValidationResolver();
  const methods = useForm<CreateUserDto | UpdateUserDto>({
    defaultValues: item ? userToFormData(item) : defaultValues,
    resolver,
    context: {
      operation: item ? 'update' : 'create',
    },
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.User,
    requestedPermission: 'manage',
  });

  usePreservedFormState({ show, watch: methods.watch, reset: methods.reset });

  const submitForm = useSubmitFormHandler({
    formState: methods.formState,
    handleSubmit: methods.handleSubmit,
    onValid: async data => {
      const processedData = Object.entries(data.accessAttributes).reduce(
        createDataProcessor(item),
        {},
      );

      !item
        ? await create({ ...data, accessAttributes: processedData } as any)
        : await update(item.id, { ...data, accessAttributes: processedData });

      notification.success({
        message: 'Успешно!',
        description: `Пользователь был успешно ${
          item ? 'сохранён' : 'обновлён'
        }`,
      });
    },
    onInvalid: () => {},
    closeTab: () => {
      methods.reset();
      refresh();
      closeTab(tabKey)();
    },
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Form
          autoComplete="new-password"
          {...fullWidthLayout}
          onFinish={submitForm}
        >
          <FormControl
            label="Имя"
            name="name"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Email"
            name="email"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Пароль"
            name="password"
            as={
              <Input.Password
                autoComplete="new-password"
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            }
          />

          <Divider>Права</Divider>

          <Row>
            <Col span={8}>
              <FormControl
                label="Доступ к тех. заданиям"
                name="accessAttributes.TechnicalProject"
                as={
                  <CheckboxGroup
                    options={[
                      { label: 'Просмотр', value: 'view' },
                      {
                        label: 'Управление',
                        value: 'manage',
                      },
                      { label: 'Печать', value: 'print' },
                      {
                        label: 'Просмотр документов',
                        value: 'view-doc',
                      },
                      {
                        label: 'Управление документами',
                        value: 'manage-doc',
                      },
                      {
                        label: 'Печать паспорта продукции',
                        value: 'print-passport',
                      },
                    ]}
                  />
                }
              />
            </Col>
            <Col span={8}>
              <FormControl
                label="Доступ к актам"
                name="accessAttributes.Act"
                as={
                  <CheckboxGroup
                    options={[
                      { label: 'Просмотр акта ПСИ', value: 'view-psi' },
                      {
                        label: 'Управление актом ПСИ',
                        value: 'manage-psi',
                      },
                      { label: 'Печать акта ПСИ', value: 'print-psi' },
                      { label: 'Просмотр акта ВКТ', value: 'view-vkt' },
                      {
                        label: 'Управление актом ВКТ',
                        value: 'manage-vkt',
                      },
                      { label: 'Печать акта ВКТ', value: 'print-vkt' },
                    ]}
                  />
                }
              />
            </Col>
            <Col span={8}>
              <FormControl
                label="Доступ к поставкам"
                name="accessAttributes.Supply"
                as={
                  <CheckboxGroup
                    options={[
                      { label: 'Просмотр', value: 'view' },
                      {
                        label: 'Управление',
                        value: 'manage',
                      },
                      {
                        label: 'Просмотр документов',
                        value: 'view-doc',
                      },
                      {
                        label: 'Управление документами',
                        value: 'manage-doc',
                      },
                    ]}
                  />
                }
              />
            </Col>
            <Col span={8}>
              <FormControl
                label="Доступ к регламентам"
                name="accessAttributes.Regulation"
                as={
                  <CheckboxGroup
                    options={[
                      { label: 'Просмотр', value: 'view' },
                      {
                        label: 'Управление',
                        value: 'manage',
                      },
                    ]}
                  />
                }
              />
            </Col>
            <Col span={8}>
              <FormControl
                label="Доступ к контрагентам"
                name="accessAttributes.Counterparty"
                as={
                  <CheckboxGroup
                    options={[
                      { label: 'Просмотр', value: 'view' },
                      {
                        label: 'Управление',
                        value: 'manage',
                      },
                    ]}
                  />
                }
              />
            </Col>
            <Col span={8}>
              <FormControl
                label="Доступ к инструментам НК"
                name="accessAttributes.Tool"
                as={
                  <CheckboxGroup
                    options={[
                      { label: 'Просмотр', value: 'view' },
                      {
                        label: 'Управление',
                        value: 'manage',
                      },
                    ]}
                  />
                }
              />
            </Col>
            <Col push={8} span={8}>
              <FormControl
                label="Доступ к пользователям"
                name="accessAttributes.User"
                as={
                  <CheckboxGroup
                    options={[
                      { label: 'Просмотр', value: 'view' },
                      {
                        label: 'Управление',
                        value: 'manage',
                      },
                    ]}
                  />
                }
              />
            </Col>
          </Row>

          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              block
              /*loading={methods.formState.isSubmitting}*/
              disabled={methods.formState.isSubmitting || !managePermission}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>
            {closeTab && (
              <Button block danger type="dashed" onClick={closeTab(tabKey)}>
                Закрыть
              </Button>
            )}
          </Space>
        </Form>
      </FormProvider>
    </PageWrapper>
  );
};
