import React, {
  forwardRef,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { Empty, Select, Spin } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { SelectProps } from 'antd/es/select';

export interface SearchSelectProps extends SelectProps<any> {
  name?: string;
  url: string;
  labelProperty?: string;
}

export const SearchSelect: FunctionComponent<SearchSelectProps> = forwardRef(
  ({ url, labelProperty = 'name', ...props }, ref) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const loadOptions = useCallback(
      async (query?: string) => {
        setLoading(true);
        try {
          const { data } = await axios.get(url, {
            params: { query },
          });
          setOptions(
            data.map((item) => ({
              label: item[labelProperty],
              value: item.id,
            })),
          );
        } catch (e) {
          setOptions([]);
        }
        setLoading(false);
      },
      [url],
    );
    const debouncedLoadOptions = useDebouncedCallback(loadOptions, 200);
    const handleSearch = useCallback(
      async (searchQuery) => {
        if (searchQuery === '') {
          debouncedLoadOptions.cancel();
          await debouncedLoadOptions.callback();
          return;
        }

        await debouncedLoadOptions.callback(searchQuery);
      },
      [debouncedLoadOptions],
    );
    const handleBlur = useCallback(() => handleSearch(''), [handleSearch]);

    useEffect(() => {
      loadOptions();
    }, [loadOptions]);

    return (
      <Select
        ref={ref as any}
        {...props}
        showSearch
        allowClear
        filterOption={false}
        notFoundContent={
          loading ? (
            <Spin size="small" />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        onSearch={handleSearch}
        onBlur={handleBlur}
        options={options}
        placeholder="Начните вводить название"
      />
    );
  },
);
