import React, {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
} from 'react';

interface ListFormProviderProps {
  state?: ListFormState;
  dispatch?: Dispatch<any>;
}

interface ListFormState {
  [key: string]: number;
}

const initialState: ListFormState = {};
const store = createContext<ListFormProviderProps>(initialState);
const { Provider } = store;

export const ListFormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state: ListFormState, action): ListFormState => {
      switch (action.type) {
        case 'LF/set-index':
          const { key, index } = action.payload;
          return { ...state, [key]: index };
        default:
          throw new Error();
      }
    },
    initialState,
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const withFormProvider = (Comp) => (props) => (
  <ListFormProvider>
    <Comp {...props} />
  </ListFormProvider>
);

export const useFormIndex = (
  key: string,
): [number, (index: number) => void] => {
  const { state, dispatch } = useContext(store);

  return useMemo(() => {
    return [
      state[key] === undefined ? 0 : state[key],
      (index: number) =>
        dispatch({ type: 'LF/set-index', payload: { key, index } }),
    ];
  }, [state[key], key]);
};
