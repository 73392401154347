import React from 'react';
import { Page } from '../../components/page/Page';
import { TabProps, useTabs } from '../../hooks/use-tabs';
import { usePaginationData } from '../../hooks/use-pagination-data';
import { API_URL } from '../../api/routes';
import { ToolsTable } from '../../components/tools/ToolsTable';
import { ToolForm } from '../../components/tools/ToolForm';
import { TabForms } from '../../components/shared/TabForms';

const defaultTabs: TabProps[] = [
  {
    key: 'list',
    type: 'list',
    label: 'Список',
    closable: false,
  },
];

export const ToolsPage = () => {
  const { state, onTableChange, refresh } = usePaginationData(
    `${API_URL}/tools`,
  );

  const { TabList, activeKey, closeTab, openTab } = useTabs({
    defaultTabs,
    defaultActiveKey: 'list',
    createLabel: (item) =>
      item ? `Инструмент "${item.name}"` : 'Новый инструмент НК',
    url: `${API_URL}/tools`,
  });

  return (
    <Page
      title="Инструменты НК"
      subTitle="Просмотр, добавление и редактирование инструментов, использующихся в процессе неразрушающего контроля"
      TabList={TabList}
    >
      <ToolsTable
        state={state}
        onTableChange={onTableChange}
        show={'list' === activeKey}
        openTab={openTab}
        refresh={refresh}
      />

      <TabForms
        type={['add', 'edit']}
        excludeKeys={['list']}
        renderItem={(item, { key }) => (
          <ToolForm
            refresh={refresh}
            closeTab={closeTab}
            key={key}
            item={item}
            tabKey={key}
            show={key === activeKey}
          />
        )}
      />
    </Page>
  );
};
