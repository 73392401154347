import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './root-reducer';
import { createLogger } from 'redux-logger';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import { setUseProxies } from 'immer';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from 'redux-state-sync';

setUseProxies(true);

const persistConfig = {
  storage,
  key: 'root',
  whitelist: ['auth', 'tutorial', 'subscription'],
};

const middlewares = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false,
});

let logger = createLogger({
  level: 'info',
  collapsed: true,
});

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

if (process.env.NODE_ENV !== 'development') {
  middlewares.push(createSentryMiddleware(Sentry));
}

middlewares.push(
  createStateSyncMiddleware({
    prepareState: state => ({ auth: state.auth }),
    predicate: action => action.type.includes('auth/'),
  }),
);

const persistedReducer = persistReducer(persistConfig, (state: any, action) => {
  if (action.type === 'RESET') {
    storage.removeItem('persist:root');
    state = undefined;
  }
  return rootReducer(state, action);
});

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV === 'development',
});

initStateWithPrevTab(store);

export const persistor = persistStore(store);
export default store;
