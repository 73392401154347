import React, { FunctionComponent } from 'react';
import { Badge } from 'antd';
import styled from 'styled-components';

const BadgeContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
`;

interface ItemCountProps {
  count: number;
  type: 'danger' | 'warning' | 'info';
}

const typeToColor = {
  danger: { backgroundColor: '#ff4d4f' },
  warning: { backgroundColor: '#fa8c16' },
  info: {
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'black',
  },
};

export const ItemCount: FunctionComponent<ItemCountProps> = ({
  count,
  type,
}) => {
  const style = typeToColor[type] || {};
  return (
    <BadgeContainer>
      <Badge showZero count={count} style={style} />
    </BadgeContainer>
  );
};
