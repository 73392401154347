import { API_URL } from './routes';
import axios from 'axios';
import { CreateRegulationDto } from '@tek-crm/backend/dist/application/regulation/dto/create-regulation.dto';
import { UpdateRegulationDto } from '@tek-crm/backend/dist/application/regulation/dto/update-regulation.dto';

export const create = async (formData: CreateRegulationDto) => {
  const { data } = await axios.post(`${API_URL}/regulation/create`, formData);
  return data;
};

export const update = async (id, formData: UpdateRegulationDto) => {
  const { data } = await axios.post(
    `${API_URL}/regulation/${id}/update`,
    formData,
  );
  return data;
};

export const remove = async (id: string) => {
  const { data } = await axios.delete(`${API_URL}/regulation/${id}`);
  return data;
};
