import React, { FunctionComponent, useMemo } from 'react';
import { Button, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons/lib';
import { TableComponentProps } from '../../interfaces/table-component-props.interface';
import { ToolDto } from '@tek-crm/backend/dist/application/tools/dto/tool.dto';
import { useRemoveHandler } from '../../hooks/use-remove-handler';
import { remove } from '../../api/users.api';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

const useUsersColumns = ({ pagination, openTab, refresh }) => {
  const viewPermission = useAccessRule({
    domain: AccessAttributeDomain.User,
    requestedPermission: 'view',
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.User,
    requestedPermission: 'manage',
  });

  const createRemoveHandler = useRemoveHandler({
    remove,
    refresh,
    description: 'Пользователь был успешно удалён',
  });

  return useMemo(
    () => [
      {
        title: 'Название',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Действия',
        key: 'action',
        fixed: 'right' as const,
        width: 100,
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="dashed"
              disabled={!viewPermission}
              icon={<EditOutlined />}
              onClick={() => openTab({ type: 'edit', key: record.id })}
            />
            <Button
              danger
              disabled={!managePermission}
              type="dashed"
              icon={<DeleteOutlined />}
              onClick={createRemoveHandler(record.id)}
            />
          </Space>
        ),
      },
    ],
    [pagination, openTab],
  );
};

export const UsersTable: FunctionComponent<TableComponentProps<ToolDto>> = ({
  state,
  onTableChange,
  show,
  openTab,
  refresh,
}) => {
  const columns = useUsersColumns({
    pagination: state.pagination,
    openTab,
    refresh,
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.User,
    requestedPermission: 'manage',
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Button
          block
          type="primary"
          disabled={!managePermission}
          onClick={() => openTab({ key: 'add' })}
        >
          Добавить
        </Button>
        <Table
          scroll={{ x: 700 }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={state.data}
          pagination={state.pagination}
          loading={state.loading}
          onChange={onTableChange}
        />
      </Space>
    </PageWrapper>
  );
};
