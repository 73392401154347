import React, { FunctionComponent, useMemo } from 'react';
import { Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { FormControl } from '../form-controls/FormControl';

export interface PrefixProp {
  prefix?: string;
}

const Group = styled(Input.Group)`
  &&& * {
    border-radius: 0;
  }
`;

const useElementsFieldsList = ({
  type,
  elements,
  prefix,
  register,
  getValues,
}) => {
  const addonList = elements.map(({ addon }) => addon);
  return useMemo(() => {
    if (type === 'detail-steel-grade') {
      return [];
    }

    return elements.map((data, index) => {
      const isRange = getValues(`${prefix}.elements[${index}].addon`) === '-';
      return (
        <>
          <Form.Item label={data.name} key={data.id}>
            <Group>
              <Row gutter={0}>
                <Col span={6}>
                  <FormControl
                    name={`${prefix}.elements[${index}].addon`}
                    as={
                      <Select
                        allowClear
                        options={[
                          { label: 'Больше', value: '>' },
                          { label: 'Больше или равно', value: '>=' },
                          { label: 'Меньше', value: '<' },
                          { label: 'Меньше или равно', value: '<=' },
                          { label: 'Равно', value: '==' },
                          { label: 'Промежуток', value: '-' },
                        ]}
                        style={{ width: '100%' }}
                      />
                    }
                  />
                </Col>
                <Col span={isRange ? 9 : 18}>
                  <FormControl
                    name={`${prefix}.elements[${index}].value`}
                    as={
                      <InputNumber
                        step={0.01}
                        precision={3}
                        autoComplete="new-password"
                        style={{ width: '100%' }}
                      />
                    }
                  />
                </Col>
                {isRange && (
                  <Col span={9}>
                    <FormControl
                      name={`${prefix}.elements[${index}].valueOther`}
                      as={
                        <InputNumber
                          step={0.01}
                          precision={3}
                          autoComplete="new-password"
                          style={{ width: '100%' }}
                        />
                      }
                    />
                  </Col>
                )}
              </Row>
            </Group>
          </Form.Item>
          <input
            ref={register}
            hidden
            value={data.name}
            name={`${prefix}.elements[${index}].name`}
            autoComplete="new-password"
          />
        </>
      );
    });
  }, [prefix, type, JSON.stringify(addonList)]);
};

const SteelGradeMainFields: FunctionComponent<PrefixProp & {
  index: number;
}> = ({ prefix }) => {
  const { getValues, register, watch } = useFormContext();
  const elements = watch(`${prefix}.elements`);
  const type = watch(`type`);
  const namePrefix = useMemo(
    () => (prefix && prefix.length > 0 ? `${prefix}.` : ''),
    [prefix],
  );

  const elementsList = useElementsFieldsList({
    type,
    elements,
    prefix,
    register,
    getValues,
  });

  return (
    <>
      <FormControl
        label="Название"
        name={`${namePrefix}name`}
        as={<Input autoComplete="new-password" />}
      />

      {elementsList.length !== 0 && (
        <>
          <Divider>Химический состав</Divider>

          {elementsList}
        </>
      )}
    </>
  );
};

export const SteelGradeForm = ({ index }) => {
  return (
    <>
      <Divider>Редактирование марки стали №{index + 1}</Divider>
      <SteelGradeMainFields index={index} prefix={`steelGrades[${index}]`} />
    </>
  );
};
