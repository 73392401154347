import React, { FunctionComponent, useMemo } from 'react';
import { Divider, Input, InputNumber } from 'antd';
import { SearchSelect } from '../form-controls/SearchSelect';
import { API_URL } from '../../api/routes';
import { useFormContext } from 'react-hook-form';
import { FormControl } from '../form-controls/FormControl';

export interface PrefixProp {
  prefix?: string;
}

const DetailMainFields: FunctionComponent<PrefixProp> = ({ prefix }) => {
  const { watch } = useFormContext();
  const namePrefix = useMemo(
    () => (prefix && prefix.length > 0 ? `${prefix}.` : ''),
    [prefix],
  );
  const regulationId = watch(`${namePrefix}regulationId`);
  const steelRegulationId = watch(`${namePrefix}steelRegulationId`);

  return (
    <>
      <FormControl
        label="Название"
        name={`${namePrefix}name`}
        as={<Input autoComplete="new-password" style={{ width: '100%' }} />}
      />

      <FormControl
        label="Количество"
        name={`${namePrefix}count`}
        as={
          <InputNumber autoComplete="new-password" style={{ width: '100%' }} />
        }
      />

      <FormControl
        label="ГОСТ/ТУ на поставку"
        name={`${namePrefix}regulationId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/find-by-query?type=workpiece`}
          />
        }
      />

      <FormControl
        label="Класс прочности"
        name={`${namePrefix}strengthClassId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/find-strength-class-by-query`}
          />
        }
      />

      <FormControl
        label="ГОСТ/ТУ на сталь"
        name={`${namePrefix}steelRegulationId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/find-by-query?type=detail-steel-grade`}
          />
        }
      />

      <FormControl
        label="Марка стали"
        name={`${namePrefix}steelGradeId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/${steelRegulationId}/find-steel-grade-by-query`}
          />
        }
      />
    </>
  );
};

export const DetailForm = ({ index }) => {
  return (
    <>
      <Divider>Редактирование позиции поставки №{index + 1}</Divider>

      <DetailMainFields prefix={`details[${index}]`} />
    </>
  );
};
