import { useEffect, useState } from 'react';
import { usePrevious } from './use-previous';
import { useRouteMatch } from 'react-router';

export const usePreservedFormState = ({ show, watch, reset, origin }: any) => {
  const [internalState, setInternalState] = useState(false);
  const prevShow = usePrevious(show);
  const watchedValues = watch();
  const match = useRouteMatch<{ key: string; id: string }>();

  useEffect(() => {
    if (!show && prevShow) {
      setInternalState(watchedValues);
    }

    if (prevShow === false && show && internalState) {
      reset(internalState);
    }
  }, [JSON.stringify(watchedValues), show, internalState]);
};
