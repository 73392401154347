import {
  SubmitErrorHandler,
  SubmitHandler,
  UseFormMethods,
} from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { extractErrorInfo } from '../utils/extractError';

export type SubmitFormHandlerHookOptions<T> = Pick<
  UseFormMethods<T>,
  'formState' | 'handleSubmit'
> & {
  closeTab: () => void;
  onValid: SubmitHandler<T>;
  onInvalid?: SubmitErrorHandler<T>;
};

const defaultOnInvalid: SubmitErrorHandler<any> = errors => {
  notification.error({
    message: 'Ошибка!',
    description: 'Не удалось сохранить форму, заполнены не все поля.',
  });
};

export const useSubmitFormHandler = <T>({
  formState,
  handleSubmit,
  onValid,
  onInvalid = defaultOnInvalid,
  closeTab,
}: SubmitFormHandlerHookOptions<T>) => {
  const [shouldReset, setShouldReset] = useState(false);
  useEffect(() => {
    if (formState.isSubmitSuccessful && shouldReset) {
      closeTab();
    }
  }, [formState, shouldReset]);

  return useCallback(
    handleSubmit(async (...args) => {
      try {
        await onValid(...args);
        setShouldReset(true);
      } catch (e) {
        setShouldReset(false);
        notification.error(extractErrorInfo(e));
      }
    }, onInvalid),
    [onValid],
  );
};
