import React, { useMemo, useState } from 'react';
import { Col, Divider, Form, notification, Row, Tabs } from 'antd';
import { layout } from '../../config/form';
import {
  acceptanceTestReportToFormData,
  createAcceptanceTestReport,
} from './helpers';
import { UltrasonicQaInfoFields } from '../shared/UltrasonicQaInfoFields';
import { DpiQaInfoFields } from '../shared/DpiQaInfoFields';
import { AcceptanceTestReportMainFields } from './AcceptanceTestReportMainFields';
import { FormProvider, useForm } from 'react-hook-form';
import { GeometryParamsFields } from '../params/GeometryParamsFields';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import {
  createCertificate,
  updateCertificate,
} from '../../api/technical-project.api';
import yup from '../../config/yup';
import {
  discrepancyInfoSchema,
  dpiQaInfoSchema,
  geometryParamsSchema,
  ultrasonicQaInfoSchema,
} from '../../config/validation';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import { WeldingQaTable } from './WeldingQaTable';

const typeSwitcher = whenType => (type, schema) => {
  return whenType === type
    ? schema.required()
    : yup
        .mixed()
        .nullable()
        .notRequired();
};

const schema = yup.object({
  actNumber: yup.string().required(),
  type: yup.string().required(),
  date: yup.string().required(),
  vickersHardness: yup
    .string()
    .when('type', typeSwitcher('BendAcceptanceTestReport')),
  residualFluxDensity: yup
    .string()
    .when('type', typeSwitcher('BendAcceptanceTestReport')),
  chamferType: yup
    .string()
    .when('type', typeSwitcher('BendAcceptanceTestReport')),
  bendingMode: yup
    .string()
    .when('type', typeSwitcher('BendAcceptanceTestReport')),
  productMarking: yup
    .string()
    .when('type', typeSwitcher('BendAcceptanceTestReport')),
  geometryParams: geometryParamsSchema.when(
    'type',
    typeSwitcher('BendAcceptanceTestReport'),
  ),
  discrepancyInfo: discrepancyInfoSchema,
  dpiQaInfo: yup.array().of(dpiQaInfoSchema),
  ultrasonicQaInfo: yup.array().of(ultrasonicQaInfoSchema),
  // qaResult: yup
  //   .array()
  //   .of(qaResultSchema)
  //   .when('type', typeSwitcher('WeldingAcceptanceTestReport')),
});

const useValidationResolver = createYupValidationResolverHook(schema);
const context = { type: 'product' };

//todo: метод обновления
export const AcceptanceTestReportForm = ({
  close,
  item,
  row,
  refresh,
  itemId = undefined,
}) => {
  const resolver = useValidationResolver();
  const initialValues = useMemo(
    () =>
      !row
        ? createAcceptanceTestReport(item)
        : acceptanceTestReportToFormData(item, row),
    [item.type],
  );

  const [view, setView] = useState(
    initialValues.type === 'BendAcceptanceTestReport'
      ? 'geometry'
      : 'ultrasonic',
  );

  const methods = useForm({
    defaultValues: initialValues,
    shouldUnregister: false,
    resolver,
    context,
  });
  const type = methods.watch('type');
  console.log(methods.errors);

  const submitForm = useSubmitFormHandler({
    formState: methods.formState,
    handleSubmit: methods.handleSubmit,
    onValid: async data => {
      if (!row) {
        await createCertificate(item.id, { ...data, itemId } as any);
      } else {
        await updateCertificate(item.id, row.id, {
          ...data,
          itemId,
        } as any);
      }

      notification.success({
        message: 'Успешно!',
        description: `Сертификат ПСИ был успешно ${
          item ? 'сохранён' : 'обновлён'
        }`,
      });
    },
    closeTab: async () => {
      methods.reset();
      await refresh();
      close();
    },
  });

  return (
    <FormProvider {...methods}>
      <Form autoComplete="new-password" {...layout} onFinish={submitForm}>
        <Row gutter={24}>
          <Col
            span={9}
            style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}
          >
            <AcceptanceTestReportMainFields close={close} type={type} />
          </Col>
          <Col span={15}>
            <Tabs activeKey={view} onChange={setView}>
              {type === 'BendAcceptanceTestReport' && (
                <Tabs.TabPane
                  forceRender
                  tab="Геометрические параметры"
                  key="geometry"
                >
                  <GeometryParamsFields
                    // regulation={regulation} todo: заменить на значения из ТЗ
                    type="product"
                    prefix="geometryParams"
                  />
                </Tabs.TabPane>
              )}

              <Tabs.TabPane forceRender tab="УЗК" key="ultrasonic">
                {type === 'WeldingAcceptanceTestReport' && (
                  <Divider>Основной металл</Divider>
                )}

                <UltrasonicQaInfoFields
                  prefix="ultrasonicQaInfo"
                  type="material"
                />

                {type === 'WeldingAcceptanceTestReport' && (
                  <>
                    <Divider>Сварной шов</Divider>
                    <UltrasonicQaInfoFields
                      prefix="ultrasonicQaInfo"
                      type="welding"
                    />
                  </>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane forceRender tab="ПВК" key="dpi">
                {type === 'WeldingAcceptanceTestReport' && (
                  <Divider>Основной металл</Divider>
                )}

                <DpiQaInfoFields prefix="dpiQaInfo" type="material" />

                {type === 'WeldingAcceptanceTestReport' && (
                  <>
                    <Divider>Сварной шов</Divider>
                    <DpiQaInfoFields prefix="dpiQaInfo" type="welding" />
                  </>
                )}
              </Tabs.TabPane>

              {type === 'WeldingAcceptanceTestReport' && (
                <Tabs.TabPane
                  forceRender
                  tab="Результаты контроля"
                  key="result"
                >
                  <WeldingQaTable />
                </Tabs.TabPane>
              )}
            </Tabs>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
