import React from 'react';
import { Form, Input, Table } from 'antd';
import { DatePicker } from './DatePicker';
import { FormControl } from '../form-controls/FormControl';

interface Item {
  key: string;
}

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType?: string;
  record: Item;
  index: number;
  children: React.ReactNode;
  dataSource: any[];
  prefix: string;
}

const renderInput = ({ idx, dataIndex, type, prefix }) => {
  let input;

  switch (type) {
    case 'date':
      input = <DatePicker />;
      break;
    default:
      input = <Input placeholder="Введите значение" style={{ margin: 0 }} />;
  }

  return (
    <FormControl
      formItemProps={{ style: { margin: 0 } }}
      name={`${prefix}[${idx}].${dataIndex}`}
      as={input}
    />
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  dataSource,
  prefix,
  ...restProps
}) => {
  if (!dataSource) {
    return null;
  }

  const idx = dataSource.indexOf(record);

  return (
    <td {...restProps}>
      {editing
        ? renderInput({ idx, dataIndex, type: inputType, prefix })
        : children}
    </td>
  );
};

export const EditableTable = ({ dataSource, columns, prefix }) => {
  const [form] = Form.useForm();

  const mergedColumns = columns.map(col => {
    return {
      ...col,
      onCell: (record: Item, rowIndex) => ({
        record,
        dataSource,
        prefix,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        editing: col.editable,
        index: rowIndex,
      }),
    };
  });

  return (
    <Form autoComplete="new-password" form={form} component={false}>
      <Table
        bordered
        scroll={{ x: 500 }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={dataSource}
        columns={mergedColumns}
        rowClassName="editable-row"
      />
    </Form>
  );
};
