import React, { FunctionComponent, useCallback } from 'react';
import { Button, Form, Input, notification, Select, Space } from 'antd';
import { fullWidthLayout } from '../../config/form';
import { FormProvider, useForm } from 'react-hook-form';
import { usePreservedFormState } from '../../hooks/use-preserved-form-state';
import { TabbedFormProps } from '../../interfaces/tabbed-form.props';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import { create, update } from '../../api/counterparty.api';
import { extractErrorInfo } from '../../utils/extractError';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import { FormControl } from '../form-controls/FormControl';
import yup from '../../config/yup';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

const defaultValues = {
  name: '',
  type: '',
  address: '',
};

const schema = yup.object().shape({
  name: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  address: yup.string().max(255),
});

const useValidationResolver = createYupValidationResolverHook(schema);

export const CounterpartyForm: FunctionComponent<TabbedFormProps> = ({
  show,
  tabKey,
  item,
  closeTab,
  refresh,
}) => {
  const resolver = useValidationResolver();
  const methods = useForm<any>({
    defaultValues: item ?? defaultValues,
    resolver,
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Counterparty,
    requestedPermission: 'manage',
  });

  usePreservedFormState({ show, watch: methods.watch, reset: methods.reset });

  const submitForm = useSubmitFormHandler({
    formState: methods.formState,
    handleSubmit: methods.handleSubmit,
    onValid: useCallback(
      async data => {
        try {
          !item ? await create(data) : await update(item.id, data);
          notification.success({
            message: 'Успешно!',
            description: `Контрагент был успешно ${
              item ? 'сохранён' : 'обновлён'
            }`,
          });
        } catch (e) {
          notification.error(extractErrorInfo(e));
        }
      },
      [item],
    ),
    onInvalid: () => {},
    closeTab: () => {
      methods.reset();
      refresh();
      closeTab(tabKey)();
    },
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Form
          {...fullWidthLayout}
          autoComplete="new-password"
          onFinish={submitForm}
        >
          <FormControl
            as={
              <Select
                options={[
                  { value: 'supplier', label: 'Поставщик' },
                  { value: 'manufacturer', label: 'Завод-изготовитель' },
                  { value: 'customer', label: 'Заказчик' },
                ]}
              />
            }
            name="type"
            label="Тип"
          />

          <FormControl
            label="Название"
            name="name"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Адрес"
            name="address"
            as={<Input autoComplete="new-password" />}
          />

          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              block
              /*loading={methods.formState.isSubmitting}*/
              disabled={methods.formState.isSubmitting || !managePermission}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>
            {closeTab && (
              <Button block danger type="dashed" onClick={closeTab(tabKey)}>
                Закрыть
              </Button>
            )}
          </Space>
        </Form>
      </FormProvider>
    </PageWrapper>
  );
};
