import React from 'react';
import { Page } from '../../components/page/Page';
import { TabProps, TabTypes, useTabs } from '../../hooks/use-tabs';
import { usePaginationData } from '../../hooks/use-pagination-data';
import { API_URL } from '../../api/routes';
import { TechnicalProjectTable } from '../../components/technical-project/TechnicalProjectTable';
import { TechnicalProjectForm } from '../../components/technical-project/TechnicalProjectForm';
import { TabForms } from '../../components/shared/TabForms';
import { TechnicalProjectOrderLineTable } from '../../components/technical-project/TechnicalProjectOrderLineTable';
import { format } from 'date-fns';
import { DocumentsList } from '../../components/document/DocumentsList';
import { DocumentType } from '@tek-crm/backend/dist/domain/document/interfaces/document.type';
import { useLocation } from 'react-router-dom';
import { PSITable } from '../../components/technical-project/PSITable';
import { ProductListTable } from '../../components/technical-project/ProductListTable';

const defaultTabs: TabProps<TabTypes | 'acts' | 'products'>[] = [
  {
    key: 'list',
    type: 'list',
    label: 'Список',
    closable: false,
  },
  {
    key: 'acts',
    type: 'acts',
    label: 'ПСИ',
    closable: false,
  },
  {
    key: 'products',
    type: 'products',
    label: 'Изделия',
    closable: false,
  },
];

const createLabel = (item, type) => {
  switch (type) {
    case 'add':
      return `Новое техническое задание`;
    case 'edit':
      return `Техническое задание от ${format(
        new Date(item.date),
        'dd.MM.yyyy',
      )}`;
    case 'psi':
      return `Акт ПСИ для ТЗ №${item.number}`;
    case 'order':
      return `Позиции заказа в ТЗ №${item.number}`;
    case 'documents':
      return `Документы в ТЗ №${item.number}`;
  }
};

const documentTypes = {
  [DocumentType.TechnicalProject]: 'Скан тех. задания',
  [DocumentType.AcceptanceTestReport]: 'Скан акта ПСИ',
  [DocumentType.ProductPassport]: 'Скан паспорта продукции',
};

export const TechnicalProjectPage = () => {
  const { search } = useLocation();

  const techProjectPagination = usePaginationData(
    `${API_URL}/technical-project${search}`,
  );

  const PSIPagination = usePaginationData(
    `${API_URL}/technical-project/psi-list`,
  );

  const productsPagination = usePaginationData(
    `${API_URL}/technical-project/products`,
  );

  const { TabList, activeKey, closeTab, openTab } = useTabs({
    defaultTabs,
    defaultActiveKey: 'list',
    createLabel,
    url: `${API_URL}/technical-project`,
  });

  return (
    <Page
      title="Технические задания"
      subTitle="Просмотр, редактирование и добавление технических заданий"
      TabList={TabList}
    >
      <TechnicalProjectTable
        state={techProjectPagination.state}
        onTableChange={techProjectPagination.onTableChange}
        show={'list' === activeKey}
        openTab={openTab}
        refresh={techProjectPagination.refresh}
      />

      <PSITable
        state={PSIPagination.state}
        onTableChange={PSIPagination.onTableChange}
        show={'acts' === activeKey}
        openTab={openTab}
        refresh={PSIPagination.refresh}
      />

      <ProductListTable
        state={productsPagination.state}
        onTableChange={productsPagination.onTableChange}
        show={'products' === activeKey}
        openTab={openTab}
        refresh={productsPagination.refresh}
      />

      <TabForms
        type={['add', 'edit']}
        excludeKeys={['list', 'order', 'documents']}
        url={`${API_URL}/technical-project`}
        renderItem={(item, tab, forceRender) => (
          <TechnicalProjectForm
            refresh={async () => {
              if (item) {
                forceRender({ id: item.id, key: `order/${item.id}` });
              }

              await techProjectPagination.refresh();
            }}
            closeTab={closeTab}
            key={tab.key}
            tabKey={tab.key}
            item={item}
            show={tab.key === activeKey}
          />
        )}
      />

      <TabForms
        type="order"
        excludeKeys={['list', 'add', 'edit', 'documents']}
        url={`${API_URL}/technical-project`}
        renderItem={(item, tab, forceRender) => (
          <TechnicalProjectOrderLineTable
            key={tab.key}
            item={item}
            show={tab.key === activeKey}
            forceRender={forceRender}
            openTab={openTab}
          />
        )}
      />

      <TabForms
        type="documents"
        excludeKeys={['list', 'add', 'edit', 'order']}
        url={`${API_URL}/technical-project`}
        renderItem={(item, tab, forceRender) => (
          <DocumentsList
            target="TechnicalProject"
            item={item}
            types={documentTypes}
            show={tab.key === activeKey}
            refresh={forceRender}
          />
        )}
      />
    </Page>
  );
};
