import React, { useMemo } from 'react';
import { Select } from 'antd';
import { SearchSelect } from '../form-controls/SearchSelect';
import { API_URL } from '../../api/routes';
import { useFormContext } from 'react-hook-form';
import { FormControl } from '../form-controls/FormControl';

export const DpiQaInfoFields = ({ prefix, type }) => {
  const { getValues, register } = useFormContext();
  const index = useMemo(
    () => getValues(prefix).findIndex(({ type: vType }) => vType === type),
    [],
  );
  const namePrefix = useMemo(() => `${prefix}[${index}]`, [index, prefix]);
  return (
    <>
      <input
        autoComplete="new-password"
        ref={register}
        hidden
        name={`${namePrefix}.type`}
        value={type}
      />

      <FormControl
        label="Регламент"
        name={`${namePrefix}.regulationId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/find-by-query?type=product`}
          />
        }
      />

      <FormControl
        label="Образец"
        name={`${namePrefix}.sampleId`}
        as={<SearchSelect url={`${API_URL}/tools/find-by-query?type=Sample`} />}
      />

      <FormControl
        label="Очиститель"
        name={`${namePrefix}.purifierId`}
        as={
          <SearchSelect url={`${API_URL}/tools/find-by-query?type=Purifier`} />
        }
      />

      <FormControl
        label="Пенетрант"
        name={`${namePrefix}.penetrantId`}
        as={
          <SearchSelect url={`${API_URL}/tools/find-by-query?type=Penetrant`} />
        }
      />

      <FormControl
        label="Проявитель"
        name={`${namePrefix}.developerId`}
        as={
          <SearchSelect url={`${API_URL}/tools/find-by-query?type=Developer`} />
        }
      />

      <FormControl
        label="Соответствие НТД"
        name={`${namePrefix}.compliance`}
        as={
          <Select
            defaultActiveFirstOption
            options={[
              { label: 'Соответствует', value: 1 },
              { label: 'Не соответствует', value: 0 },
            ]}
          />
        }
      />
    </>
  );
};
