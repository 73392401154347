import React, { useMemo } from 'react';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Space,
} from 'antd';
import { layout } from '../../config/form';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import {
  createDetailControlAct,
  updateDetailControlAct,
} from '../../api/supply.api';
import { FormControl } from '../form-controls/FormControl';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import yup from '../../config/yup';
import { createDetailControlActInitialValues } from './helpers';
import { DatePicker } from '../shared/DatePicker';
import { DiscrepancyInfoFields } from '../shared/DiscrepancyInfoFields';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { EditableTable } from '../shared/EditableTable';
import { HorizontalCell } from '../shared/HorizontalCell';
import { format } from 'date-fns';

const schema = yup.object({});
const useValidationResolver = createYupValidationResolverHook(schema);

export const DetailControlActForm = ({ close, item, row, refresh }) => {
  const resolver = useValidationResolver();
  const initialValues = useMemo(
    () =>
      item
        ? {
            ...item,
            date: new Date(item.date),
          }
        : createDetailControlActInitialValues(row),
    [],
  );

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: initialValues,
    resolver,
  });
  console.log(methods.errors);

  const submitForm = useSubmitFormHandler({
    formState: methods.formState,
    handleSubmit: methods.handleSubmit,
    onValid: async data => {
      if (!item) {
        await createDetailControlAct(row.id, {
          ...data,
          details: row.details,
        } as any);
      } else {
        await updateDetailControlAct(row.id, item.id, {
          ...data,
          details: row.details,
        } as any);
      }

      notification.success({
        message: 'Успешно!',
        description: `Сертификат ВКТ был успешно ${
          item ? 'сохранён' : 'обновлён'
        }`,
      });
    },
    closeTab: async () => {
      methods.reset();
      await refresh();
      close();
    },
  });

  return (
    <FormProvider {...methods}>
      <Form autoComplete="new-password" {...layout} onFinish={submitForm}>
        <Row gutter={24}>
          <Col
            span={9}
            style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}
          >
            <DetailControlActFormMainFields close={close} />
          </Col>
          <Col span={15}>
            <DetailControlLinesForm />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

const useDetailControlLineColumns = () =>
  useMemo(
    () => [
      {
        title: 'Наименование, маркировка продукции',
        dataIndex: 'name',
        fixed: 'left' as const,
        width: 150,
      },
      {
        title: (<HorizontalCell>Количество</HorizontalCell>) as any,
        dataIndex: 'count',
        fixed: 'left' as const,
        width: 50,
      },
      {
        title: '№ документа о качестве',
        dataIndex: 'certificateNumber',
        width: 150,
      },
      {
        title: 'НТД на продукцию',
        dataIndex: 'certificateType',
        editable: true,
        width: 150,
      },
      {
        title: 'Дата поступления продукции',
        dataIndex: 'receiptDate',
        width: 80,
        render: value => {
          if (value) {
            return format(new Date(value), 'dd.MM.yyyy');
          } else {
            return undefined;
          }
        },
      },
      {
        title: 'Заключение о годности',
        dataIndex: 'compliance',
        editable: true,
        width: 150,
      },
    ],
    [],
  );

export const DetailControlLinesForm = () => {
  const { getValues } = useFormContext();
  const columns = useDetailControlLineColumns();
  const dataSource = getValues('detailControlLines');

  return (
    <EditableTable
      prefix="detailControlLines"
      dataSource={dataSource}
      columns={columns}
    />
  );
};

export const DetailControlActFormMainFields = ({ close }) => {
  const methods = useFormContext();

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'manage-vkt',
  });

  return (
    <>
      <Divider>Основное</Divider>

      <FormControl
        label="Номер акта"
        name="actNumber"
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Дата проведения"
        name="date"
        as={<DatePicker style={{ width: '100%' }} />}
      />

      <Collapse ghost>
        <Collapse.Panel key="discrepancyInfo" header="Данные о несоответствии">
          <DiscrepancyInfoFields prefix="discrepancyInfo" />
        </Collapse.Panel>
      </Collapse>

      <Row justify="end" gutter={[20, 10]}>
        <Col>
          <Space>
            <Button
              /*loading={methods.formState.isSubmitting}*/
              disabled={methods.formState.isSubmitting || !managePermission}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>

            <Button danger type="dashed" onClick={close}>
              Закрыть
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
