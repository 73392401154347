import React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Button, Result } from 'antd';
import { Layout } from '../layout/Layout';
import { Page } from '../page/Page';

interface ErrorCatcherProps extends RouteComponentProps {}

interface ErrorCatcherState {
  hasError: boolean;
  count: number;
}

class ErrorCatcher extends React.Component<
  ErrorCatcherProps,
  ErrorCatcherState
> {
  private unlisten;

  constructor(props) {
    super(props);
    this.state = { hasError: false, count: 0 };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidCatch(error, errorInfo) {
    // if (process.env.NODE_ENV !== 'development') {
    // if (!this.props.history.location.pathname.match('error')) {
    //   this.props.history.replace('/error');
    // }
    // }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout>
          <Page>
            <Result
              status="500"
              title="Системный сбой"
              subTitle="Что-то сломалось, попробуйте обновить страницу. При повторном возникновении - свяжитесь с разработчиком"
              extra={
                <Button type="primary" onClick={() => window.location.reload()}>
                  Обновить
                </Button>
              }
            />
          </Page>
        </Layout>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(ErrorCatcher);
