import { Input } from 'antd';
import React from 'react';
import { FormControl } from '../form-controls/FormControl';

export const DiscrepancyInfoFields = ({ prefix }) => {
  return (
    <>
      <FormControl
        label="Причина"
        name={`${prefix}.reason`}
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Виновник"
        name={`${prefix}.causer`}
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Коррекция"
        name={`${prefix}.correction`}
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Корректирующие действия"
        name={`${prefix}.correctiveActions`}
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Отметка о выполнении КД"
        name={`${prefix}.correctionMark`}
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Отметка о повторном контроле"
        name={`${prefix}.repeatedQAMark`}
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Отметка о результативности КД"
        name={`${prefix}.correctiveActionsScore`}
        as={<Input autoComplete="new-password" />}
      />
    </>
  );
};
