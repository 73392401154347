import { WorkpieceDto } from '@tek-crm/backend/dist/application/supply/dto/workpiece.dto';
import { createEmptyElement } from '../regulation/helpers';
import { createSteelParams, createWeldingParams } from '../../utils/helpers';
import { StringValues } from '../../interfaces/string-values.type';
import { CreateWorkpieceDto } from '@tek-crm/backend/dist/application/supply/dto/create-workpiece.dto';
import { CreateDetailDto } from '@tek-crm/backend/dist/application/supply/dto/create-detail.dto';
import {
  CreateDetailControlActDto,
  CreateDetailControlLineDto,
  DetailDto,
  SupplyDto,
} from '@tek-crm/backend/dist/application/supply/dto';
import { createDiscrepancyInfo } from '../technical-project/helpers';

export const createEmptyWorkpiece = (): StringValues<CreateWorkpieceDto> => ({
  serialNumbers: [],
  items: [
    {
      meltingNumber: '',
      serialNumber: '',
      hydraulicPressure: '',
      wallThickness: '',
      nominalDiameter: '',
      length: '',
    },
  ],
  regulationId: '',
  steelRegulationId: '',
  steelGradeId: '',
  strengthClassId: '',
});

export const createDetailControlLine = (
  row: SupplyDto,
  detail: DetailDto,
  count: number,
  index: number,
): StringValues<CreateDetailControlLineDto> => {
  return {
    name: detail.name,
    certificateType: '',
    lineNumber: index.toString(),
    count: count.toString(),
    certificateNumber: row.qualityCertificate.number,
    receiptDate: new Date(),
    compliance: '',
  };
};

export const createDetailControlActInitialValues = (
  row: SupplyDto,
): StringValues<CreateDetailControlActDto> => ({
  detailControlLines: row.details
    .reduce((acc, detail) => {
      const data = acc.find(({ detail: det }) => det.lineId === detail.lineId);

      if (data) {
        data.count++;
      } else {
        acc.push({
          detail,
          count: 1,
        });
      }

      return acc;
    }, [])
    .map(({ detail, count }, index) =>
      createDetailControlLine(row, detail, count, index),
    ),
  detailIds: row.details.map(detail => detail.id),
  supplierId: row.supplier.id,
  actNumber: '',
  date: new Date(),
  discrepancyInfo: createDiscrepancyInfo(),
});

export const createEmptyDetail = (): StringValues<CreateDetailDto> => ({
  name: '',
  count: '',
  regulationId: '',
  steelRegulationId: '',
  steelGradeId: '',
  strengthClassId: '',
});

export const createReceiptControlActInitialValues = (items: WorkpieceDto[]) => {
  const item = items[0];

  return {
    steelParams: createSteelParams(),
    weldingParams: createWeldingParams(),
    elements: item.steelGrade.elements.map(element =>
      createEmptyElement(element.name, element.order),
    ),
  };
};
