import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { StringValues } from '../interfaces/string-values.type';
import { useFormContext } from 'react-hook-form';
import { ExclamationOutlined } from '@ant-design/icons';

interface UseManageChildListParams<T> {
  key: string;
  setIndex: Dispatch<SetStateAction<number>>;
  createEmpty: (index: number) => StringValues<T>;
  createItemTitle?: (item: T, index: number) => string;
}

const calculateNewIndex = (currentIndex, indexToRemove) => {
  if (currentIndex === 0) {
    return currentIndex;
  }

  if (currentIndex >= indexToRemove) {
    return currentIndex - 1;
  }

  return currentIndex;
};

export const useManageChildList = <T extends any>(
  { key, setIndex, createEmpty, createItemTitle }: UseManageChildListParams<T>,
  deps: any[] = [],
) => {
  const { reset, errors, getValues } = useFormContext();
  const [internalIndex, setInternalIndex] = useState(0);
  const handleEdit = useCallback(
    (index: number) => () => {
      setIndex(index);
      setInternalIndex(index);
    },
    [],
  );

  const itemTitleFactory = useCallback(
    (item, index) => {
      let hasError = !!errors[key];
      if (hasError) {
        hasError = !!errors[key][index];
      }
      const label = createItemTitle(item, index);
      return hasError
        ? (((
            <>
              {label}
              <ExclamationOutlined style={{ color: 'red' }} />
            </>
          ) as unknown) as string)
        : label;
    },
    [key, errors],
  );

  const handleAdd = useCallback(() => {
    const fields = getValues();
    reset({ ...fields, [key]: [...fields[key], createEmpty(internalIndex)] });
    setIndex(fields[key].length);
    setInternalIndex(fields[key].length);
  }, [createEmpty]);

  const handleRemove = useCallback(
    (index) => () => {
      const fields = getValues();
      const filteredValues = fields[key].filter((value, i) => i !== index);
      const newIndex = calculateNewIndex(internalIndex, index);
      setInternalIndex(newIndex);
      setIndex(newIndex);
      // IT magic, вероятно связано с тем, как работает react-hook-form
      requestAnimationFrame(() => {
        reset({ ...fields, [key]: filteredValues });
      });
    },
    [internalIndex],
  );

  return useMemo(
    () => ({
      handleEdit,
      handleAdd,
      handleRemove,
      index: internalIndex,
      itemTitleFactory,
    }),
    [internalIndex, itemTitleFactory, ...deps],
  );
};
