import { TablePaginationConfig } from 'antd/es/table';
import { createSlice } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import axios from 'axios';
import { SorterResult } from 'antd/es/table/interface';
import { useRouteMatch } from 'react-router';

export interface PaginationDataState<T> {
  data: T[];
  loading: boolean;
  pagination: TablePaginationConfig;
}

export interface PaginationData<T> {
  state: PaginationDataState<T>;
  onTableChange: (...args: any) => any;
  refresh: () => Promise<void>;
}

const sortMap = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const usePaginationData = <T extends any = any>(
  url,
  { page = 1, pageSize = 10, additionalParams = {} } = {
    page: 1,
    pageSize: 10,
  },
): PaginationData<T> => {
  const match = useRouteMatch<{ key: string; id: string }>();
  const { onPageChange, setLoading, reducer, initialState } = useMemo(() => {
    const initialState: PaginationDataState<T> = {
      data: [],
      loading: true,
      pagination: {
        current: page,
        pageSize,
      },
    };
    const {
      actions: { onPageChange, setLoading },
      reducer,
    } = createSlice({
      name: 'pagination',
      initialState,
      reducers: {
        setLoading: (state, action) => {
          state.loading = action.payload;
        },
        onPageChange: (state, action) => {
          const { data, pagination } = action.payload;
          state.data = data;
          state.pagination = pagination;
        },
      },
    });

    return { onPageChange, setLoading, reducer, initialState };
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    fetchRemote(state.pagination);
  }, []);

  useEffect(() => {
    if ('list' === match.params.key) {
      fetchRemote(state.pagination);
    }
  }, [match.params.key]);

  const fetchRemote = useCallback(
    async (
      pagination: TablePaginationConfig,
      sortOrder?,
      sortField?,
      filters?,
    ) => {
      dispatch(setLoading(true));

      try {
        const { data } = await axios.post(url, {
          offset: pagination.current - 1,
          limit: pagination.pageSize,
          sortOrder: sortMap[sortOrder],
          sortField,
          additionalParams,
          filters,
        });
        dispatch(
          onPageChange({
            pagination: { ...pagination, total: data.total },
            data: data.items,
          }),
        );
      } catch (e) {}
      dispatch(setLoading(false));
    },
    [url],
  );

  const onTableChange = useCallback(
    async (
      pagination: TablePaginationConfig,
      filters,
      sorter: SorterResult<any>,
      extra,
    ) => {
      const { field: sortField, order: sortOrder } = sorter;
      await fetchRemote(pagination, sortOrder, sortField, filters);
    },
    [],
  );

  const refresh = useCallback(async () => {
    await fetchRemote(state.pagination);
  }, [state.pagination]);

  return useMemo(
    () => ({
      state,
      onTableChange,
      refresh,
    }),
    [state, onTableChange, refresh],
  );
};
