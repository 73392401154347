import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EditableTable } from '../shared/EditableTable';
import { HorizontalCell } from '../shared/HorizontalCell';

export const WeldingQaTable = () => {
  const { getValues } = useFormContext();

  const columns = [
    {
      title: '№ партии, детали',
      dataIndex: 'batchDetailNumber',
      fixed: 'left' as const,
      width: 150,
    },
    {
      title: (<HorizontalCell>№ стыка</HorizontalCell>) as any,
      dataIndex: 'jointNumber',
      fixed: 'left' as const,
      width: 50,
    },
    {
      title: 'ФИО, клеймо сварщика',
      dataIndex: 'welderName',
      editable: true,
      width: 200,
    },
    {
      title: 'Типоразмер, материал',
      dataIndex: 'standardSize',
      editable: true,
      width: 150,
    },
    {
      title: 'Дата сварки',
      dataIndex: 'weldDate',
      editable: true,
      inputType: 'date',
      width: 150,
    },
    {
      title: 'Тип сварки, материал',
      dataIndex: 'weldType',
      editable: true,
      width: 150,
    },
    {
      title: 'Описание дефектов',
      dataIndex: 'defects',
      editable: true,
      width: 250,
    },
    {
      title: 'Оценка качества',
      dataIndex: 'qualityControl',
      editable: true,
      width: 250,
    },
  ];
  const dataSource = getValues('qaResult');

  return (
    <EditableTable
      prefix="qaResult"
      dataSource={dataSource}
      columns={columns}
    />
  );
};
