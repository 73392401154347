import React, { FunctionComponent, useMemo } from 'react';
import { Button, Modal, Table } from 'antd';
import { TableComponentProps } from '../../interfaces/table-component-props.interface';
import { SupplyDto } from '@tek-crm/backend/dist/application/supply/dto/supply.dto';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { useModal } from '../../hooks/use-modal';
import { useColumnSearchProps } from '../../hooks/use-column-search-props';
import { DownloadButton } from '../shared/DownloadButton';
import { format } from 'date-fns';
import { API_URL } from '../../api/routes';
import { ReceiptControlActForm } from './ReceiptControlActForm';

const useSupplyColumns = ({ state, openTab, open, close, refresh }) => {
  const getColumnSearchProps = useColumnSearchProps();

  const printPermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'print-vkt',
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'manage-vkt',
  });

  return useMemo(() => {
    return [
      {
        title: 'Завод-изготовитель',
        ...getColumnSearchProps(['supply', 'manufacturer', 'name']),
      },
      {
        title: 'Дата',
        ...getColumnSearchProps(['supply', 'date'], 'date'),
      },
      {
        title: 'Номер сертификата',
        ...getColumnSearchProps(['supply', 'qualityCertificate', 'number']),
      },
      {
        title: 'Номер плавки',
        ...getColumnSearchProps('meltingNumber'),
      },
      {
        title: 'Серийный номер',
        ...getColumnSearchProps('serialNumber'),
      },
      {
        title: 'Акт ВКТ',
        dataIndex: 'receiptControlAct',
        render: (value, record) => {
          return value ? (
            <DownloadButton
              type="link"
              disabled={!printPermission}
              fileName={`Акт ВКТ №${
                record.receiptControlAct.actNumber
              } на поставку от ${format(
                new Date(record.supply.date),
                'dd.MM.yyyy',
              )}.docx`}
              href={`${API_URL}/supply/${record.supply.id}/docx/${record.receiptControlAct.id}`}
            >
              №{value.actNumber}
            </DownloadButton>
          ) : (
            <Button
              type="link"
              disabled={!managePermission}
              onClick={() =>
                open(
                  <ReceiptControlActForm
                    close={close}
                    item={record.supply}
                    row={record}
                    refresh={refresh}
                  />,
                )
              }
            >
              Не создан
            </Button>
          );
        },
      },
      {
        title: 'Номинальный диаметр',
        dataIndex: 'nominalDiameter',
      },
      {
        title: 'Толщина стенки',
        dataIndex: 'wallThickness',
      },
      {
        title: 'Длина',
        dataIndex: 'length',
      },
      {
        title: 'Остаток длины',
        dataIndex: 'remainingLength',
      },
    ];
  }, [state, openTab]);
};

export const LeftoversTable: FunctionComponent<TableComponentProps<
  SupplyDto
>> = ({ state, onTableChange, show, openTab, refresh }) => {
  const { content, visible, open, close } = useModal();
  const columns = useSupplyColumns({
    state,
    openTab,
    open,
    close,
    refresh,
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <Table
        scroll={{ x: 700 }}
        columns={columns}
        rowKey={record => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={state.loading}
        onChange={onTableChange}
      />

      <Modal
        maskClosable
        destroyOnClose
        width="90%"
        visible={visible}
        footer={null}
        onCancel={close}
      >
        {content}
      </Modal>
    </PageWrapper>
  );
};
