import React from 'react';
import { Page } from '../../components/page/Page';
import { TabProps, useTabs } from '../../hooks/use-tabs';
import { usePaginationData } from '../../hooks/use-pagination-data';
import { RegulationTable } from '../../components/regulation/RegulationTable';
import { RegulationForm } from '../../components/regulation/RegulationForm';
import { API_URL } from '../../api/routes';
import { TabForms } from '../../components/shared/TabForms';

const defaultTabs: TabProps[] = [
  {
    key: 'list',
    type: 'list',
    label: 'Список',
    closable: false,
  },
];

export const RegulationPage = () => {
  const { state, onTableChange, refresh } = usePaginationData(
    `${API_URL}/regulation`,
  );

  const { TabList, activeKey, closeTab, openTab } = useTabs({
    defaultTabs,
    defaultActiveKey: 'list',
    createLabel: (item) =>
      !item ? 'Новый регламент' : `Регламент "${item.name}"`,
    url: `${API_URL}/regulation`,
  });

  return (
    <Page
      title="Регламенты"
      subTitle="Просмотр, добавление и редактирование ГОСТов/ТУ, использующихся в рабочем процессе"
      TabList={TabList}
    >
      <RegulationTable
        state={state}
        onTableChange={onTableChange}
        show={'list' === activeKey}
        openTab={openTab}
        refresh={refresh}
      />

      <TabForms
        type={['add', 'edit']}
        excludeKeys={['list']}
        renderItem={(item, { key }) => (
          <RegulationForm
            refresh={refresh}
            closeTab={closeTab}
            key={key}
            item={item}
            tabKey={key}
            show={key === activeKey}
          />
        )}
      />
    </Page>
  );
};
