import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { config } from './features/config';
import { auth } from './features/auth';
import { tabsData } from './features/tabs-data';

const rehydrate = createReducer(false, {
  REHYDRATE: (state, action) => (state = true),
});

export const rootReducer = combineReducers({
  rehydrate,
  config,
  auth,
  tabsData,
});

export type RootStore = ReturnType<typeof rootReducer>;
