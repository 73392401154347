import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Menu, Modal, Space, Table } from 'antd';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons/lib';
import { TableComponentProps } from '../../interfaces/table-component-props.interface';
import { TechnicalProjectDto } from '@tek-crm/backend/dist/application/technical-project/dto/technical-project.dto';
import { format } from 'date-fns';
import { API_URL } from '../../api/routes';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { DownloadMenuItem } from '../shared/DownloadButton';
import { AcceptanceTestReportForm } from './AcceptanceTestReportForm';
import { useModal } from '../../hooks/use-modal';
import {
  findAcceptanceTestReport,
  getProject,
} from '../../api/technical-project.api';

const ActionContainer = ({ record, open, close, openTab, refresh }) => {
  const [atr, setAtr] = useState(undefined);
  const [item, setItem] = useState(undefined);

  const viewPermission = useAccessRule({
    domain: AccessAttributeDomain.TechnicalProject,
    requestedPermission: 'view',
  });

  const printPermission = useAccessRule({
    domain: AccessAttributeDomain.TechnicalProject,
    requestedPermission: 'print',
  });

  const documentPermission = useAccessRule({
    domain: AccessAttributeDomain.TechnicalProject,
    requestedPermission: 'view-doc',
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'manage-psi',
  });

  useEffect(() => {
    (async () => {
      if (atr || atr === null) {
        return;
      }

      const data = await findAcceptanceTestReport(record.id);

      if (!data || data === '') {
        setAtr(null);
      } else {
        setAtr(data);
      }
    })();
  }, [atr]);

  useEffect(() => {
    (async () => {
      if (item || item === null) {
        return;
      }

      const data = await getProject(record.id);

      if (!data || data === '') {
        setItem(null);
      } else {
        setItem(data);
      }
    })();
  }, [item]);

  return (
    <Space size="middle">
      <Button
        type="dashed"
        disabled={!viewPermission}
        icon={<EditOutlined />}
        onClick={() => openTab({ type: 'edit', key: record.id })}
      />
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {record.type === 'WeldingTechnicalProject' && (
              <Menu.Item
                key="1"
                disabled={atr ? !viewPermission : !managePermission}
                onClick={() =>
                  open(
                    <AcceptanceTestReportForm
                      close={close}
                      item={item}
                      row={atr}
                      refresh={refresh}
                    />,
                  )
                }
              >
                {atr ? 'Обновить акт ПСИ' : 'Добавить акт ПСИ'}
              </Menu.Item>
            )}
            {record.type === 'BendTechnicalProject' && (
              <Menu.Item
                key="2"
                onClick={() => openTab({ type: 'order', key: record.id })}
              >
                Позиции в заказе
              </Menu.Item>
            )}
            {record.type === 'WeldingTechnicalProject' && atr && (
              <DownloadMenuItem
                key="3"
                disabled={!printPermission}
                fileName={`Акт ПСИ №${atr.actNumber} для ТЗ №${record.number}.docx`}
                href={`${API_URL}/technical-project/${record.id}/docx/${atr.id}`}
              >
                Скачать акт ПСИ
              </DownloadMenuItem>
            )}
            {record.type === 'WeldingTechnicalProject' && atr && (
              <DownloadMenuItem
                key="14"
                disabled={!printPermission}
                fileName={`Паспорта продукции для ТЗ №${record.number}.zip`}
                href={`${API_URL}/technical-project/${record.id}/product-passport`}
              >
                Скачать паспорта продукции
              </DownloadMenuItem>
            )}
            <DownloadMenuItem
              key="3"
              disabled={!printPermission}
              fileName={`Техническое задание №${record.number}.docx`}
              href={`${API_URL}/technical-project/${record.id}/docx`}
            >
              Скачать тех. задание
            </DownloadMenuItem>

            <Menu.Item
              key="4"
              disabled={!documentPermission}
              onClick={() => openTab({ type: 'documents', key: record.id })}
            >
              Документы
            </Menu.Item>
          </Menu>
        }
      >
        <Button icon={<EllipsisOutlined />} />
      </Dropdown>
    </Space>
  );
};

const useTechnicalProjectColumns = ({
  state,
  openTab,
  open,
  close,
  refresh,
}) => {
  return useMemo(
    () => [
      {
        title: 'Тип',
        dataIndex: 'type',
        width: '15%',
        render: type => {
          switch (type) {
            case 'BendTechnicalProject':
              return 'Отводы гнутые';
            case 'WeldingTechnicalProject':
              return 'Сварка/нарезка';

            default:
              return 'Неизвестно';
          }
        },
      },
      {
        title: 'Номер',
        dataIndex: 'number',
        width: '15%',
      },
      {
        title: 'Дата',
        dataIndex: 'date',
        sorter: true,
        render: date => format(new Date(date), 'dd.MM.yyyy'),
      },
      {
        title: 'Контрагент',
        dataIndex: 'counterparty',
        render: counterparty => counterparty.name,
      },
      {
        title: 'Ответственный',
        dataIndex: 'responsible',
      },
      {
        title: 'Основание',
        dataIndex: 'basis',
      },
      {
        title: 'Действия',
        key: 'action',
        fixed: 'right' as const,
        width: 150,
        render: (text, record) => {
          return (
            <ActionContainer
              record={record}
              open={open}
              close={close}
              openTab={openTab}
              refresh={refresh}
            />
          );
        },
      },
    ],
    [state, openTab],
  );
};

export const TechnicalProjectTable: FunctionComponent<TableComponentProps<
  TechnicalProjectDto
>> = ({ state, onTableChange, show, openTab, refresh }) => {
  const { content, visible, open, close } = useModal();

  const columns = useTechnicalProjectColumns({
    state,
    openTab,
    open,
    close,
    refresh,
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.TechnicalProject,
    requestedPermission: 'manage',
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Button
          block
          type="primary"
          disabled={!managePermission}
          onClick={() => openTab({ key: 'add' })}
        >
          Добавить
        </Button>
        <Table
          scroll={{ x: 700 }}
          columns={columns}
          expandable={{
            expandedRowRender: record => (
              <p style={{ margin: 0 }}>Примечание: {record.notes}</p>
            ),
            rowExpandable: record => !!record.notes,
          }}
          rowKey={record => record.id}
          dataSource={state.data}
          pagination={state.pagination}
          loading={state.loading}
          onChange={onTableChange}
        />
      </Space>

      <Modal
        maskClosable
        destroyOnClose
        width="90%"
        visible={visible}
        footer={null}
        onCancel={close}
      >
        {content}
      </Modal>
    </PageWrapper>
  );
};
