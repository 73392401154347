import React, {
  Dispatch,
  FunctionComponent,
  memo,
  SetStateAction,
} from 'react';
import { SearchSelect } from '../form-controls/SearchSelect';
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd';
import { FormMainProps } from '../../interfaces/form-main-props.interface';
import { API_URL } from '../../api/routes';
import { useManageChildList } from '../../hooks/use-manage-child-list';
import { createOrderLine } from './helpers';
import { ChildList } from '../shared/ChildList';
import { useFormContext } from 'react-hook-form';
import { DatePicker } from '../shared/DatePicker';
import { FormControl } from '../form-controls/FormControl';
import { FormTabs } from '../shared/FormTabs';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { CreateOrderLineDto } from '@tek-crm/backend/dist/application/technical-project/dto/create/create-order-line.dto';
import { TechnicalProjectStatus } from '@tek-crm/backend/dist/domain/technical-project/technical-project.status';

export const TechnicalProjectMainFields: FunctionComponent<FormMainProps & {
  view: string;
  setView: Dispatch<SetStateAction<string>>;
}> = memo(({ view, tabKey, setIndex, setView, closeTab }) => {
  const { watch, formState, errors } = useFormContext<any>();
  const { order, type } = watch(['type', 'order']);

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.TechnicalProject,
    requestedPermission: 'manage',
  });

  const orderLinesManageMethods = useManageChildList<CreateOrderLineDto>(
    {
      key: 'order',
      setIndex,
      createItemTitle: (item, index) => `Позиция заказа №${index + 1}`,
      createEmpty: index => {
        return createOrderLine(
          type === 'BendTechnicalProject' ? 'bend' : 'welding',
          order.length + 1,
        );
      },
    },
    [type],
  );

  return (
    <>
      <Divider>Информация о тех. задании</Divider>

      <Collapse
        ghost
        defaultActiveKey={['main']}
        style={{ marginBottom: '20px' }}
      >
        <Collapse.Panel key="main" header="Основные поля">
          <FormControl
            label="Статус"
            name="status"
            as={
              <Select
                options={[
                  { label: 'В работе', value: TechnicalProjectStatus.WIP },
                  {
                    label: 'Ожидает ПСИ',
                    value: TechnicalProjectStatus.AWAIT_PSI,
                  },
                  {
                    label: 'Ожидает Пасспорта',
                    value: TechnicalProjectStatus.AWAIT_PASSPORT,
                  },
                  { label: 'В архиве', value: TechnicalProjectStatus.ARCHIVE },
                ]}
              />
            }
          />

          <FormControl
            label="Основание"
            name="basis"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Ответственный"
            name="responsible"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Номер ТЗ"
            name="number"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Дата поступления"
            name="date"
            as={<DatePicker style={{ width: '100%' }} />}
          />

          <FormControl
            label="Тип"
            name="type"
            as={
              <Select
                options={[
                  { label: 'Отводы гнутые', value: 'BendTechnicalProject' },
                  {
                    label: 'Сварка/нарезка',
                    value: 'WeldingTechnicalProject',
                  },
                ]}
              />
            }
          />

          <FormControl
            label="Контрагент"
            name="counterpartyId"
            as={
              <SearchSelect
                url={`${API_URL}/counterparty/find-by-query?type=customer`}
              />
            }
          />

          <FormControl
            label="Примечание"
            name="notes"
            as={<Input.TextArea autoComplete="new-password" />}
          />
        </Collapse.Panel>
      </Collapse>

      <Row justify="end" gutter={[20, 10]}>
        <Col flex="1 0">
          <Button
            onClick={() => {
              orderLinesManageMethods.handleAdd();
              setView('order');
            }}
            style={{ marginRight: 'auto' }}
          >
            Добавить позицию заказа
          </Button>
        </Col>
        <Col>
          <Space>
            <Button
              /*loading={formState.isSubmitting}*/
              disabled={formState.isSubmitting || !managePermission}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>
            {closeTab && (
              <Button danger type="dashed" onClick={closeTab(tabKey)}>
                Закрыть
              </Button>
            )}
          </Space>
        </Col>
      </Row>

      <FormTabs
        errors={errors}
        activeKey={view}
        onChange={viewKey => {
          setView(viewKey);
          setIndex(0);
        }}
      >
        <Tabs.TabPane tab="Заказ" key="order">
          <ChildList
            index={orderLinesManageMethods.index}
            dataSource={order}
            handleEdit={orderLinesManageMethods.handleEdit}
            handleRemove={orderLinesManageMethods.handleRemove}
            createItemTitle={orderLinesManageMethods.itemTitleFactory}
          >
            {item => (
              <>
                <Descriptions.Item label={<b>Наименование</b>}>
                  {item.name}
                </Descriptions.Item>
                <Descriptions.Item label={<b>Количество</b>}>
                  {item.params.count}
                </Descriptions.Item>
              </>
            )}
          </ChildList>
        </Tabs.TabPane>
      </FormTabs>
    </>
  );
});
