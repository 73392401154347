import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/root-reducer';

export const ProtectedRoute = ({
  children,
  domain = undefined,
  rule = undefined,
  ...props
}) => {
  const location = useLocation();
  const auth = useSelector((state: RootStore) => state.auth);
  const { user } = auth;

  if (!auth.authenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }

  if (
    domain &&
    !user.accessAttributes.some((attribute) => attribute.domain === domain)
  ) {
    return <Redirect to="/" />;
  }

  if (
    domain &&
    Array.isArray(rule) &&
    rule.length &&
    !user.accessAttributes.some(
      (attribute) =>
        attribute.domain === domain && rule.includes(attribute.name),
    )
  ) {
    return <Redirect to="/" />;
  }

  return <Route {...props}>{children}</Route>;
};
