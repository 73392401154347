import { useCallback } from 'react';
import { transformToNestObject } from 'react-hook-form';

export const createYupValidationResolverHook = (validationSchema) => () =>
  useCallback(async (data, context) => {
    try {
      const values = await validationSchema.validate(data, {
        abortEarly: false,
        context,
      });

      return {
        values,
        errors: {},
      };
    } catch (errors) {
      if (errors.inner === undefined) {
        throw errors;
      }

      return {
        values: {},
        errors: transformToNestObject(
          errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: currentError.message,
            }),
            {},
          ),
        ),
      };
    }
  }, []);
