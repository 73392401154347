import { StringValues } from '../../interfaces/string-values.type';
import { CreateRegulationDto } from '@tek-crm/backend/dist/application/regulation/dto/create-regulation.dto';
import { CreateSteelGradeDto } from '@tek-crm/backend/dist/application/regulation/dto/create-steel-grade.dto';
import { CreateElementDto } from '@tek-crm/backend/dist/application/regulation/dto/create-element.dto';
import { CreateStrengthClassDto } from '@tek-crm/backend/dist/application/strength-class/dto/create-strength-class.dto';

export const createEmptyRegulation = (): StringValues<CreateRegulationDto> => ({
  name: '',
  type: '',
  steelGrades: [createEmptySteelGrade()],
});

export const createEmptyStrengthClass = (): StringValues<CreateStrengthClassDto> => ({
  name: '',
  params: {
    flowStress: '',
    tensileStrength: '',
    relativeExtension: '',
    toughnessKCU: '',
    toughnessKCV: '',
    FStoT: '',
    remanence: '',
  },
});

export const createEmptySteelGrade = (): StringValues<CreateSteelGradeDto> => ({
  elements: [
    'C',
    'Mn',
    'Mo',
    'Si',
    'S',
    'P',
    'V',
    'B',
    'Cr',
    'Ni',
    'Cu',
    'N',
    'Al',
    'Ti',
    'Cэкв',
  ].map(createEmptyElement),
  name: '',
});

export const createEmptyElement = (
  name = '',
  order = 0,
): StringValues<CreateElementDto> & {
  key?: string;
} => ({
  key: Math.random().toString(16),
  name,
  order: order.toString(),
  value: '',
});
