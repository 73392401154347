import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { orderBy } from 'lodash';
import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { DatePicker } from '../shared/DatePicker';
import { DiscrepancyInfoFields } from '../shared/DiscrepancyInfoFields';
import { FormControl } from '../form-controls/FormControl';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

const { Text } = Typography;

export const ReceiptControlActMainFields = ({ close, supply, row }) => {
  const methods = useFormContext();

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'manage-vkt',
  });

  return (
    <>
      <Divider>Документация</Divider>

      <Descriptions bordered column={1} size="small">
        <Descriptions.Item label="Номер сертификата">
          {supply.qualityCertificate ? (
            supply.qualityCertificate.number
          ) : (
            <Text type="warning">Сертификат не загружен</Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Марка стали">
          {row.steelGrade.name}
        </Descriptions.Item>
        <Descriptions.Item label="Номер плавки">
          {(row.meltingNumber || []).join(', ')}
        </Descriptions.Item>
        <Descriptions.Item label="Номер трубы">
          {row.items.map(({ number }) => `№${number}`).join(', ')}
        </Descriptions.Item>
        <Descriptions.Item label="НТД на поставку">
          {row.regulation.name}
        </Descriptions.Item>
        <Descriptions.Item label="Количество, длина">
          {orderBy(row.items, 'number').map(({ number, length }) => (
            <Fragment key={number}>
              {`№${number}, длина: ${length}`}
              <br />
            </Fragment>
          ))}
        </Descriptions.Item>
      </Descriptions>

      <Divider>Основное</Divider>

      <FormControl
        label="Номер акта"
        name="actNumber"
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Дата проведения"
        name="date"
        as={<DatePicker style={{ width: '100%' }} />}
      />

      <FormControl
        label="Теоретический вес"
        name="theoreticalWeight"
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Визуальный контроль"
        name="visualControl"
        as={
          <Select
            defaultActiveFirstOption
            options={[
              { label: 'Соответствует', value: true as any },
              { label: 'Не соответствует', value: false as any },
            ]}
          />
        }
      />

      <Collapse ghost>
        <Collapse.Panel key="discrepancyInfo" header="Данные о несоответствии">
          <DiscrepancyInfoFields prefix="discrepancyInfo" />
        </Collapse.Panel>
      </Collapse>

      <Row justify="end" gutter={[20, 10]}>
        <Col>
          <Space>
            <Button
              /*loading={methods.formState.isSubmitting}*/
              disabled={methods.formState.isSubmitting || !managePermission}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>

            <Button danger type="dashed" onClick={close}>
              Закрыть
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
