import { StringValues } from '../interfaces/string-values.type';
import { CreateSteelParamsDto } from '../../../backend/dist/application/shared/dto/create-steel-params.dto';
import { CreateWeldingParamsDto } from '../../../backend/dist/application/shared/dto/create-welding-params.dto';
import { CreateGeometryParamsDto } from '../../../backend/dist/application/shared/dto/create-geometry-params.dto';
import { CreateParamsDto } from '@tek-crm/backend/dist/application/shared/dto/create-params.dto';

export const createSteelParams = (): StringValues<CreateSteelParamsDto> => ({
  flowStress: '',
  tensileStrength: '',
  relativeExtension: '',
  toughnessKCU: '',
  toughnessKCV: '',
  FStoT: '',
  strengthClass: '',
});

export const createWeldingParams = (): StringValues<
  CreateWeldingParamsDto
> => ({
  flowStress: '',
  tensileStrength: '',
  relativeExtension: '',
  toughnessKCU: '',
  toughnessKCV: '',
  remanence: '',
});

export const createEmptyParams = (): StringValues<CreateParamsDto> => ({
  flowStress: '',
  tensileStrength: '',
  relativeExtension: '',
  toughnessKCU: '',
  toughnessKCV: '',
  FStoT: '',
  remanence: '',
});

export const createGeometryParams = (): StringValues<
  CreateGeometryParamsDto
> => ({
  bendingAngle: '',
  bendingRadius: '',
  corrugationSize: '',
  wallThickness: '',
  obliqueCut: '',
  nominalDiameter: '',
  maxDiameter: '',
  minDiameter: '',
  ovality1end: '',
  ovality2end: '',
  ovalityBend: '',
  faceToFaceLengthA: '',
  faceToFaceLengthB: '',
  straightSectionA: '',
  straightSectionB: '',
});
