import { usePaginationData } from '../../hooks/use-pagination-data';
import { API_URL } from '../../api/routes';
import { Table } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { format } from 'date-fns';

const useDetailColumns = () => {
  return useMemo(
    () => [
      {
        title: 'Название',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Завод-изготовитель',
        dataIndex: 'supply',
        render: value => value.manufacturer.name,
      },
      {
        title: 'Дата',
        dataIndex: 'supply',
        render: value => format(new Date(value.date), 'dd.MM.yyyy'),
      },
      {
        title: 'Номер сертификата',
        dataIndex: 'supply',
        render: value =>
          (value.qualityCertificate || {}).number
            ? value.qualityCertificate.number
            : 'Не указан',
      },
    ],
    [],
  );
};

export const DetailList = ({ selectedRowKeys, handleChange, excludeIds }) => {
  const {
    state,
    onTableChange,
  } = usePaginationData(`${API_URL}/supply/details`, {
    additionalParams: { excludeIds },
  });
  const handleSelectionChange = useCallback(async rowKeys => {
    handleChange(rowKeys);
  }, []);

  const columns = useDetailColumns();

  const handleOnRow = useCallback(
    record => ({
      onClick: () =>
        handleChange((prev: string[]) => {
          const index = prev.indexOf(record.id);

          if (index >= 0) {
            return [...prev.slice(0, index), ...prev.slice(index + 1)];
          }

          return prev.concat(record.id);
        }),
    }),
    [],
  );

  const dataSource = useMemo(
    () => state.data.filter(({ id }) => !excludeIds.includes(id)),
    [state.data, excludeIds],
  );

  return (
    <Table
      scroll={{ x: 500 }}
      columns={columns}
      rowKey={record => record.id}
      dataSource={dataSource}
      pagination={state.pagination}
      loading={state.loading}
      onChange={onTableChange}
      onRow={handleOnRow}
      rowSelection={{
        preserveSelectedRowKeys: true,
        onChange: handleSelectionChange,
        selectedRowKeys,
      }}
    />
  );
};
