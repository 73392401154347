import React, { memo } from 'react';
import { Controller, useFormContext, UseFormMethods } from 'react-hook-form';
import { Form } from 'antd';
import { get, isEqual } from 'lodash';
import { FormItemProps as BaseFormItemProps } from 'antd/es/form';
import styled from 'styled-components';

const ControlWrapper = styled(Form.Item)`
  & {
    align-items: center;

    .ant-form-item-label {
      white-space: normal;
      line-height: 16px;

      label {
        height: auto;
      }
    }
  }

  &.ant-form-item-with-help {
    margin-bottom: 24px;

    .ant-form-item-explain {
      position: absolute;
      bottom: -24px;
    }
  }
`;

export interface FormControlProps {
  label?: string;
  name: string;
  as: JSX.Element;
  formItemProps?: BaseFormItemProps;
}

export interface FormItemProps
  extends UseFormMethods<Record<string, any>>,
    FormControlProps {
  error: string;
}

export const FormControl = memo<FormControlProps>(
  ({ formItemProps, ...props }) => {
    const methods = useFormContext();

    return (
      <FormItem
        {...methods}
        {...props}
        formItemProps={formItemProps}
        error={get(methods.formState.errors, props.name)}
      />
    );
  },
  (prev, next) => isEqual(prev.as, next.as) && prev.name === next.name,
);

export const FormItem = memo<FormItemProps>(
  ({ formItemProps, name, label, as, control, error }) => {
    return (
      <ControlWrapper
        {...{ ...formItemProps, label }}
        colon={false}
        help={error}
        validateStatus={error !== undefined ? 'error' : 'success'}
      >
        <Controller name={name} control={control} as={as} />
      </ControlWrapper>
    );
  },
  (prev, next) =>
    prev.error === next.error &&
    isEqual(prev.as, next.as) &&
    prev.name === next.name,
);
