import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { FormControl } from './FormControl';
import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

const Group = styled(Input.Group)`
  &&& * {
    border-radius: 0;
  }
`;

const InputGroupWrapper = styled(Form.Item)`
  & {
    .ant-form-item-label {
      white-space: normal;
      line-height: 16px;

      label {
        height: auto;
      }
    }
  }
`;

export const InputGroup = ({ prefix, label }) => {
  const { getValues } = useFormContext();
  const isRange = getValues(`${prefix}.addon`) === '-';
  return (
    <InputGroupWrapper label={label}>
      <Group>
        <Row gutter={0}>
          <Col span={6}>
            <FormControl
              name={`${prefix}.addon`}
              as={
                <Select
                  allowClear
                  options={[
                    { label: 'Больше', value: '>' },
                    { label: 'Больше или равно', value: '>=' },
                    { label: 'Меньше', value: '<' },
                    { label: 'Меньше или равно', value: '<=' },
                    { label: 'Равно', value: '==' },
                    { label: 'Промежуток', value: '-' },
                  ]}
                  style={{ width: '100%' }}
                />
              }
            />
          </Col>
          <Col span={isRange ? 9 : 18}>
            <FormControl
              name={`${prefix}.value`}
              as={
                <InputNumber
                  step={0.01}
                  precision={3}
                  autoComplete="new-password"
                  style={{ width: '100%' }}
                />
              }
            />
          </Col>
          {isRange && (
            <Col span={9}>
              <FormControl
                name={`${prefix}.valueOther`}
                as={
                  <InputNumber
                    step={0.01}
                    precision={3}
                    autoComplete="new-password"
                    style={{ width: '100%' }}
                  />
                }
              />
            </Col>
          )}
        </Row>
      </Group>
    </InputGroupWrapper>
  );
};
