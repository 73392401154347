import { Button, Checkbox, Col, Form, Input, Layout, Row } from 'antd';
import React, { useCallback } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/features/auth';
import { login as loginRequest } from '../../api/auth.api';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import yup from '../../config/yup';
import { useHistory } from 'react-router';
import {
  LoginContainer,
  LoginDescription,
  LoginForm,
  LoginHeader,
  LoginHeaderContainer,
} from './styled';
import { openErrorNotification, openSuccessNotification } from './utils';

const { Content, Footer } = Layout;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Необходимо указать email')
    .required('Необходимо указать email'),
  password: yup.string().required('Необходимо указать пароль'),
  rememberMe: yup.boolean(),
});

const useYupValidationResolver = createYupValidationResolverHook(
  validationSchema,
);

export const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const resolver = useYupValidationResolver();
  const { control, handleSubmit, errors } = useForm({ resolver });
  const submitLoginForm = useCallback(async data => {
    try {
      const response = await loginRequest(data);
      dispatch(login(response));
      openSuccessNotification();
    } catch (e) {
      openErrorNotification();
    }
  }, []);

  return (
    <LoginContainer>
      <Content style={{ padding: '100px 0' }}>
        <Row justify="center">
          <Col span={24}>
            <LoginHeaderContainer>
              <LoginHeader src="/img/logo-mini.png" />
              <LoginDescription>Система управления</LoginDescription>
            </LoginHeaderContainer>
          </Col>
          <Col span={24}>
            <div style={{ width: 400, margin: '0 auto' }}>
              <LoginForm onFinish={handleSubmit(submitLoginForm)}>
                <Form.Item
                  help={errors.login}
                  validateStatus={errors.login ? 'error' : 'success'}
                  style={{ width: '100%' }}
                >
                  <Controller
                    name="email"
                    control={control}
                    as={
                      <Input
                        size="large"
                        placeholder="Введите логин"
                        prefix={<UserOutlined />}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  help={errors.password}
                  validateStatus={errors.password ? 'error' : 'success'}
                  style={{ width: '100%' }}
                >
                  <Controller
                    name="password"
                    control={control}
                    as={
                      <Input.Password
                        size="large"
                        placeholder="Введите пароль"
                        prefix={<LockOutlined />}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item name="rememberMe">
                  <Controller
                    name="rememberMe"
                    control={control}
                    render={({ onChange, onBlur, value, name }) => (
                      <Checkbox
                        onChange={e => onChange(e.target.checked)}
                        checked={value}
                        name={name}
                        style={{ color: 'white' }}
                      >
                        Запомнить меня
                      </Checkbox>
                    )}
                  />
                </Form.Item>

                <Button block type="primary" htmlType="submit">
                  Войти
                </Button>
                <Button
                  block
                  type="link"
                  onClick={() => {
                    history.push('request-password-reset');
                  }}
                >
                  Восстановить пароль
                </Button>
              </LoginForm>
            </div>
          </Col>
        </Row>
      </Content>
      <Footer>
        <div style={{ color: 'white', textAlign: 'center' }}>
          TEK-CRM © Разработано в{' '}
          <a href="https://linkodium.com/" target="_blank">
            Linkodium
          </a>{' '}
          (2020)
        </div>
      </Footer>
    </LoginContainer>
  );
};
