import styled from 'styled-components';

export const HorizontalCell = styled.div`
  transform: rotate(-90deg);
  word-wrap: normal;
  white-space: nowrap;
  position: absolute;
  left: -5px;
  bottom: 25px;
`;
