import React, { FunctionComponent, useMemo } from 'react';
import { Button, Dropdown, Menu, Modal, Space, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons/lib';
import { TableComponentProps } from '../../interfaces/table-component-props.interface';
import { SupplyDto } from '@tek-crm/backend/dist/application/supply/dto/supply.dto';
import { format } from 'date-fns';
import { EllipsisOutlined } from '@ant-design/icons';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { SupplyType } from '@tek-crm/backend/dist/domain/supply/supply.type';
import { DownloadMenuItem } from '../shared/DownloadButton';
import { API_URL } from '../../api/routes';
import { useModal } from '../../hooks/use-modal';
import { DetailControlActForm } from './DetailControlActForm';

const useSupplyColumns = ({ state, openTab, open, close, refresh }) => {
  const viewPermission = useAccessRule({
    domain: AccessAttributeDomain.Supply,
    requestedPermission: 'view',
  });

  const documentPermission = useAccessRule({
    domain: AccessAttributeDomain.Supply,
    requestedPermission: 'view-doc',
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'manage-vkt',
  });

  const printPermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'print-vkt',
  });

  return useMemo(
    () => [
      {
        title: 'Дата',
        dataIndex: 'date',
        sorter: true,
        render: date => format(new Date(date), 'dd.MM.yyyy'),
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        render: type =>
          type === SupplyType.DETAILS ? 'Поставка деталей' : 'Поставка труб',
      },
      {
        title: 'Ответственный',
        dataIndex: 'responsible',
      },
      {
        title: 'Поставщик',
        dataIndex: 'supplier',
        render: counterparty => counterparty.name ?? 'Не указан',
      },
      {
        title: 'Завод-изготовитель',
        dataIndex: 'manufacturer',
        render: counterparty => counterparty.name ?? 'Не указан',
      },
      {
        title: 'Количество позиций',
        dataIndex: 'workpieces',
        render: (workpieces, record) => {
          const data =
            record.type === SupplyType.WORKPIECES
              ? record.workpieces
              : record.details;

          return (
            <Button
              type="link"
              onClick={() => openTab({ type: 'workpieces', key: record.id })}
            >
              {new Set(data.map(({ lineId }) => lineId)).size}
            </Button>
          );
        },
      },
      {
        title: 'Действия',
        key: 'action',
        fixed: 'right' as const,
        width: 150,
        render: (text, record) => {
          let controlAct;

          if (record.type === 'details') {
            const found = record.details.find(
              detail => detail.detailControlAct,
            );

            controlAct = found && found.detailControlAct;
          }

          return (
            <Space size="middle">
              <Button
                type="dashed"
                disabled={!viewPermission}
                icon={<EditOutlined />}
                onClick={() => openTab({ type: 'edit', key: record.id })}
              />
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    {record.type === 'workpieces' && (
                      <Menu.Item
                        key="1"
                        onClick={() =>
                          openTab({ type: 'workpieces', key: record.id })
                        }
                      >
                        Заготовки в поставке
                      </Menu.Item>
                    )}

                    {record.type === 'details' && (
                      <>
                        <Menu.Item
                          key="1"
                          disabled={
                            controlAct ? !viewPermission : !managePermission
                          }
                          onClick={() =>
                            open(
                              <DetailControlActForm
                                close={close}
                                item={controlAct}
                                row={record}
                                refresh={refresh}
                              />,
                            )
                          }
                        >
                          {controlAct ? 'Обновить акт ВКД' : 'Добавить акт ВКД'}
                        </Menu.Item>
                        {controlAct && (
                          <DownloadMenuItem
                            key="2"
                            disabled={!printPermission}
                            fileName={`Акт ВКД №${
                              controlAct.actNumber
                            } на поставку от ${format(
                              new Date(record.date),
                              'dd.MM.yyyy',
                            )}.docx`}
                            href={`${API_URL}/supply/${record.id}/detail-docx/${controlAct.id}`}
                          >
                            Скачать акт ВКД
                          </DownloadMenuItem>
                        )}
                      </>
                    )}

                    <Menu.Item
                      key="2"
                      disabled={!documentPermission}
                      onClick={() =>
                        openTab({ type: 'documents', key: record.id })
                      }
                    >
                      Документы
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button icon={<EllipsisOutlined />} />
              </Dropdown>
            </Space>
          );
        },
      },
    ],
    [state, openTab],
  );
};

export const SupplyTable: FunctionComponent<TableComponentProps<SupplyDto>> = ({
  state,
  onTableChange,
  show,
  openTab,
  refresh,
}) => {
  const { content, visible, open, close } = useModal();
  const columns = useSupplyColumns({
    state,
    openTab,
    open,
    close,
    refresh,
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Supply,
    requestedPermission: 'manage',
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Button
          block
          disabled={!managePermission}
          type="primary"
          onClick={() => openTab({ key: 'add' })}
        >
          Добавить
        </Button>
        <Table
          scroll={{ x: 700 }}
          columns={columns}
          rowKey={record => record.id}
          dataSource={state.data}
          pagination={state.pagination}
          loading={state.loading}
          onChange={onTableChange}
        />
      </Space>

      <Modal
        maskClosable
        destroyOnClose
        width="90%"
        visible={visible}
        footer={null}
        onCancel={close}
      >
        {content}
      </Modal>
    </PageWrapper>
  );
};
