import { API_URL } from './routes';
import axios from 'axios';
import { UpdateCounterpartyDto } from '@tek-crm/backend/dist/application/counterparty/dto/update-counterparty.dto';
import { CreateCounterpartyDto } from '@tek-crm/backend/dist/application/counterparty/dto/create-counterparty.dto';

export const create = async (formData: CreateCounterpartyDto) => {
  const { data } = await axios.post(`${API_URL}/counterparty/create`, formData);
  return data;
};

export const update = async (id: string, formData: UpdateCounterpartyDto) => {
  const { data } = await axios.post(
    `${API_URL}/counterparty/${id}/update`,
    formData,
  );
  return data;
};

export const remove = async (id: string) => {
  const { data } = await axios.delete(`${API_URL}/counterparty/${id}`);
  return data;
};
