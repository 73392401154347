import React, { FunctionComponent } from 'react';
import { Button, Form, Input, notification, Select, Space } from 'antd';
import { fullWidthLayout } from '../../config/form';
import { FormProvider, useForm } from 'react-hook-form';
import { usePreservedFormState } from '../../hooks/use-preserved-form-state';
import { TabbedFormProps } from '../../interfaces/tabbed-form.props';
import { CreateToolDto } from '@tek-crm/backend/dist/application/tools/dto/create-tool.dto';
import { UpdateToolDto } from '@tek-crm/backend/dist/application/tools/dto/update-tool.dto';
import { create, update } from '../../api/tools.api';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import { extractErrorInfo } from '../../utils/extractError';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import { FormControl } from '../form-controls/FormControl';
import yup from '../../config/yup';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

const defaultValues = {
  name: '',
  type: '',
};

const schema = yup.object().shape({
  name: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  type: yup
    .string()
    .max(128)
    .required('Не выбран тип инструмента НК'),
});

const useValidationResolver = createYupValidationResolverHook(schema);

export const ToolForm: FunctionComponent<TabbedFormProps> = ({
  show,
  item,
  closeTab,
  tabKey,
  refresh,
}) => {
  const resolver = useValidationResolver();
  const methods = useForm<CreateToolDto | UpdateToolDto>({
    defaultValues: item ?? defaultValues,
    resolver,
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Tool,
    requestedPermission: 'manage',
  });

  usePreservedFormState({ show, watch: methods.watch, reset: methods.reset });

  const submitForm = useSubmitFormHandler<CreateToolDto | UpdateToolDto>({
    formState: methods.formState,
    handleSubmit: methods.handleSubmit,
    onValid: async data => {
      try {
        !item ? await create(data as any) : await update(item.id, data);
        notification.success({
          message: 'Успешно!',
          description: `Инструмент НК был успешно ${
            item ? 'сохранён' : 'обновлён'
          }`,
        });
      } catch (e) {
        notification.error(extractErrorInfo(e));
      }
    },
    onInvalid: () => {},
    closeTab: () => {
      methods.reset();
      refresh();
      closeTab(tabKey)();
    },
  });

  const type = methods.watch('type');

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Form
          autoComplete="new-password"
          {...fullWidthLayout}
          onFinish={submitForm}
        >
          <FormControl
            label="Название"
            name="name"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Тип"
            name="type"
            as={
              <Select
                options={[
                  { label: 'Образец', value: 'Sample' },
                  { label: 'Очиститель', value: 'Purifier' },
                  { label: 'Пенетрант', value: 'Penetrant' },
                  { label: 'Проявитель', value: 'Developer' },
                  { label: 'Прибор', value: 'Device' },
                  { label: 'СОП', value: 'TuningSample' },
                  { label: 'ПЭП', value: 'Transducer' },
                ]}
              />
            }
          />

          {(type.includes('Sample') || ['Device'].includes(type)) && (
            <FormControl
              label="Сведения о поверке"
              name="verificationInformation"
              as={<Input autoComplete="new-password" />}
            />
          )}

          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              block
              /*loading={methods.formState.isSubmitting}*/
              disabled={methods.formState.isSubmitting || !managePermission}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>
            {closeTab && (
              <Button block danger type="dashed" onClick={closeTab(tabKey)}>
                Закрыть
              </Button>
            )}
          </Space>
        </Form>
      </FormProvider>
    </PageWrapper>
  );
};
