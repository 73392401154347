import React, {
  cloneElement,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import { ExclamationOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { TabsProps } from 'antd/es/tabs';
import { get } from 'lodash';

const TabNodeWrapper: FunctionComponent<{
  className: string;
  errors: any;
  tabKey: string;
}> = ({ children, className, errors, tabKey, ...props }): any => {
  const hasError = useMemo(() => !!get(errors, tabKey), [
    JSON.stringify(get(errors, tabKey)),
    tabKey,
  ]);

  return (
    <>
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? cloneElement(child, {
              ...props,
              className: `${child.props.className} ${className}`,
              children: (
                <>
                  {child.props.children}
                  {hasError && <ExclamationOutlined style={{ color: 'red' }} />}
                </>
              ),
            })
          : child,
      )}
    </>
  );
};

export const FormTabs: FunctionComponent<
  TabsProps & { errors: Record<string, any> }
> = ({ children, errors, ...props }) => {
  const renderTabBar = useCallback(
    (props, DefaultTabBar) => (
      <DefaultTabBar {...props}>
        {(node) => {
          return (
            <TabNodeWrapper
              key={node.key}
              tabKey={node.key}
              className={node.className}
              errors={errors}
            >
              {node}
            </TabNodeWrapper>
          );
        }}
      </DefaultTabBar>
    ),
    [errors],
  );

  return (
    <Tabs {...props} renderTabBar={renderTabBar}>
      {children}
    </Tabs>
  );
};
