import React, { useMemo } from 'react';
import { Input, Select } from 'antd';
import { SearchSelect } from '../form-controls/SearchSelect';
import { API_URL } from '../../api/routes';
import { useFormContext } from 'react-hook-form';
import { FormControl } from '../form-controls/FormControl';

export const UltrasonicQaInfoFields = ({ prefix, type }) => {
  const { watch, register } = useFormContext();
  const values = watch(prefix);
  const index = useMemo(
    () => values.findIndex(({ type: vType }) => vType === type),
    [],
  );
  const namePrefix = useMemo(() => `${prefix}[${index}]`, [index, prefix]);
  return (
    <>
      <Input
        autoComplete="new-password"
        hidden
        ref={register}
        name={`${namePrefix}.type`}
        value={type}
      />

      <FormControl
        label="Регламент"
        name={`${namePrefix}.regulationId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/find-by-query?type=product`}
          />
        }
      />

      <FormControl
        label="Прибор"
        name={`${namePrefix}.deviceId`}
        as={<SearchSelect url={`${API_URL}/tools/find-by-query?type=Device`} />}
      />

      <FormControl
        label="СОП"
        name={`${namePrefix}.sampleId`}
        as={
          <SearchSelect
            url={`${API_URL}/tools/find-by-query?type=TuningSample`}
          />
        }
      />

      {type !== 'welding' && (
        <>
          <FormControl
            label="ПЭП"
            name={`${namePrefix}.transducerId`}
            as={
              <SearchSelect
                url={`${API_URL}/tools/find-by-query?type=Transducer`}
              />
            }
          />

          <FormControl
            label="Площадь брака"
            name={`${namePrefix}.defectArea`}
            as={<Input autoComplete="new-password" />}
          />
        </>
      )}

      <FormControl
        label="Соответствие НТД"
        name={`${namePrefix}.compliance`}
        as={
          <Select
            defaultActiveFirstOption
            options={[
              { label: 'Соответствует', value: 1 },
              { label: 'Не соответствует', value: 0 },
            ]}
          />
        }
      />
    </>
  );
};
