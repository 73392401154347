import { Layout, PageHeader } from 'antd';
import React, { FunctionComponent } from 'react';

const { Content } = Layout;

//todo: сворачивать сайдер на мобиле

export interface PageProps {
  title?: string;
  subTitle?: string;
  TabList?: JSX.Element;
  description?: JSX.Element;
}

export const Page: FunctionComponent<PageProps> = ({
  title,
  subTitle,
  children,
  TabList,
  description,
}) => {
  return (
    <>
      <PageHeader
        style={{ backgroundColor: 'white' }}
        title={title}
        subTitle={subTitle}
        footer={TabList}
      >
        {description}
      </PageHeader>
      <Content style={{ padding: '0 50px' }}>
        <Layout
          className="site-layout-background"
          style={{ padding: '24px 0' }}
        >
          {children}
        </Layout>
      </Content>
    </>
  );
};
