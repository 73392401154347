import React, { useMemo } from 'react';
import { Button, Dropdown, Menu, Modal, Table } from 'antd';
import { ReceiptControlActForm } from './ReceiptControlActForm';
import { useModal } from '../../hooks/use-modal';
import { EllipsisOutlined } from '@ant-design/icons';
import { API_URL } from '../../api/routes';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { DownloadButton, DownloadMenuItem } from '../shared/DownloadButton';
import { format } from 'date-fns';
import { uniq } from 'lodash';

const useColumns = ({ item, open, close, forceRender }) => {
  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'manage-vkt',
  });
  const viewPermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'view-vkt',
  });
  const printPermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'print-vkt',
  });
  return useMemo(() => {
    return [
      {
        title: 'Марка стали',
        dataIndex: 'steelGrade',
        render: value => value.name,
      },
      {
        title: 'Акт ВКТ',
        dataIndex: 'receiptControlAct',
        render: (value, record) => {
          return value ? (
            <DownloadButton
              type="link"
              disabled={!printPermission}
              fileName={`Акт ВКТ №${
                record.receiptControlAct.actNumber
              } на поставку от ${format(
                new Date(item.date),
                'dd.MM.yyyy',
              )}.docx`}
              href={`${API_URL}/supply/${item.id}/docx/${record.receiptControlAct.id}`}
            >
              №{value.actNumber}
            </DownloadButton>
          ) : (
            <Button
              type="link"
              disabled={!managePermission}
              onClick={() =>
                open(
                  <ReceiptControlActForm
                    close={close}
                    item={item}
                    row={record}
                    refresh={forceRender}
                  />,
                )
              }
            >
              Не создан
            </Button>
          );
        },
      },
      {
        title: 'Номер плавки',
        dataIndex: 'meltingNumber',
        render: value => Array.from(new Set(value)).join(', '),
      },
      {
        title: 'Количество',
        dataIndex: 'count',
      },
      {
        title: 'Действия',
        key: 'action',
        fixed: 'right' as const,
        width: 150,
        render: (text, record) => (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  disabled={
                    record.receiptControlAct
                      ? !viewPermission
                      : !managePermission
                  }
                  onClick={() =>
                    open(
                      <ReceiptControlActForm
                        close={close}
                        item={item}
                        row={record}
                        refresh={forceRender}
                      />,
                    )
                  }
                >
                  {record.receiptControlAct
                    ? 'Обновить акт ВКТ'
                    : 'Добавить акт ВКТ'}
                </Menu.Item>
                {record.receiptControlAct && (
                  <DownloadMenuItem
                    key="2"
                    disabled={!printPermission}
                    fileName={`Акт ВКТ №${
                      record.receiptControlAct.actNumber
                    } на поставку от ${format(
                      new Date(item.date),
                      'dd.MM.yyyy',
                    )}.docx`}
                    href={`${API_URL}/supply/${item.id}/docx/${record.receiptControlAct.id}`}
                  >
                    Скачать акт ВКТ
                  </DownloadMenuItem>
                )}
              </Menu>
            }
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        ),
      },
    ];
  }, [item]);
};

export const SupplyWorkpiecesTable = ({ item, show, forceRender }) => {
  const { content, visible, open, close } = useModal();
  const columns = useColumns({ item, open, close, forceRender });
  const dataSource = useMemo(() => {
    return item.workpieces.reduce((acc, workpiece) => {
      const found = acc.find(
        aggregatedLine => aggregatedLine.lineId === workpiece.lineId,
      );

      if (!found) {
        return acc.concat({
          count: 1,
          lineId: workpiece.lineId,
          type: workpiece.type,
          receiptControlAct: workpiece.receiptControlAct,
          meltingNumber: [workpiece.meltingNumber],
          regulation: workpiece.regulation,
          number: workpiece.number,
          steelGrade: workpiece.steelGrade,
          strengthClass: workpiece.strengthClass,
          items: [workpiece],
        });
      }

      const index = acc.indexOf(found);

      return Object.assign(acc.slice(), {
        [index]: {
          ...found,
          meltingNumber: uniq(
            found.meltingNumber.concat(workpiece.meltingNumber),
          ),
          count: found.count + 1,
          items: found.items.concat(workpiece),
        },
      });
    }, []);
  }, [item]);

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <Table
        scroll={{ x: 700 }}
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        rowKey={record => record.id}
      />

      <Modal
        maskClosable
        destroyOnClose
        width="90%"
        visible={visible}
        footer={null}
        onCancel={close}
      >
        {content}
      </Modal>
    </PageWrapper>
  );
};
