import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './router/Routes';
import ru from 'antd/lib/locale/ru_RU';
import { ConfigProvider } from 'antd';
import { ErrorBoundary } from './components/shared/ErrorBoundary';
import './api/axios-config';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {});
}

const App = ({ store, persistor, basename }) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <CookiesProvider>
        <BrowserRouter basename={basename}>
          <ConfigProvider locale={ru}>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </ConfigProvider>
        </BrowserRouter>
      </CookiesProvider>
    </PersistGate>
  </Provider>
);

export default App;
