import React, { FunctionComponent, memo, useMemo, useState } from 'react';
import { Input, InputNumber, Tabs } from 'antd';
import { DatePicker } from '../shared/DatePicker';
import { FormControl } from '../form-controls/FormControl';
import { SearchSelect } from '../form-controls/SearchSelect';
import { API_URL } from '../../api/routes';
import { FormTabs } from '../shared/FormTabs';
import { useFormContext } from 'react-hook-form';
import { OrderItemTable } from './OrderItemTable';

export const WeldingOrderLineFields: FunctionComponent<any> = memo(
  ({ index }) => {
    const namePrefix = useMemo(() => (index > -1 ? `order[${index}]` : ''), [
      index,
    ]);

    const [view, setView] = useState('info');
    const { errors, watch } = useFormContext();
    const itemLength = watch(`${namePrefix}.params.length`);

    return (
      <FormTabs
        errors={errors}
        activeKey={view}
        onChange={viewKey => {
          setView(viewKey);
        }}
      >
        <Tabs.TabPane forceRender tab="Информация" key="info">
          <FormControl
            label="Наименование"
            name={`${namePrefix}.name`}
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Регламент"
            name={`${namePrefix}.regulationId`}
            as={
              <SearchSelect
                name={`${namePrefix}.regulationId`}
                url={`${API_URL}/regulation/find-by-query?type=product`}
              />
            }
          />

          <FormControl
            label="Количество, шт"
            name={`${namePrefix}.params.count`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Длина"
            name={`${namePrefix}.params.length`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Количество стыков (на деталь)"
            name={`${namePrefix}.params.jointsCount`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Количество патрубков (на деталь)"
            name={`${namePrefix}.params.branchPipeCount`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Дата поступления деталей"
            name={`${namePrefix}.params.arrivalOfParts`}
            as={<DatePicker style={{ width: '100%' }} />}
          />

          <FormControl
            label="Дата поступления трубы"
            name={`${namePrefix}.params.arrivalOfTube`}
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Перечень работ"
            name={`${namePrefix}.params.workDescription`}
            as={<Input.TextArea autoComplete="new-password" />}
          />

          <FormControl
            label="Примечание"
            name={`${namePrefix}.params.notes`}
            as={<Input.TextArea autoComplete="new-password" />}
          />
        </Tabs.TabPane>
        <Tabs.TabPane forceRender tab="Заготовки" key="workpieces">
          <OrderItemTable
            index={index}
            namePrefix={namePrefix}
            itemLength={itemLength}
          />
        </Tabs.TabPane>
      </FormTabs>
    );
  },
);
