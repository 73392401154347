import React, { useEffect, useState } from 'react';
import { count } from '../../api/technical-project.api';
import { Button, Card, List } from 'antd';
import { Link } from 'react-router-dom';
import { ItemCount } from './ItemCount';
import { TechnicalProjectStatus } from '@tek-crm/backend/dist/domain/technical-project/technical-project.status';

export const TechnicalProjectsCard = () => {
  const [countStatusMap, setCountStatusMap] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const data = await count();
        setCountStatusMap(data);
      } catch (e) {}
    })();
  }, []);

  return (
    <Card title="Технические задания">
      <List itemLayout="horizontal">
        <List.Item
          actions={[
            <Link to="/technical-project/list?status=work-in-progress">
              Перейти
            </Link>,
          ]}
        >
          <List.Item.Meta
            title="ТЗ в работе"
            description="Список технических заданий, по которым ведутся активные дейсвтя, ожидается поставка или уточняются детали заказа"
          />
          <ItemCount
            count={countStatusMap[TechnicalProjectStatus.WIP]}
            type="danger"
          />
        </List.Item>
        <List.Item
          actions={[
            <Link to="/technical-project/list?status=await-psi">Перейти</Link>,
          ]}
        >
          <List.Item.Meta
            title="Ожидают ПСИ"
            description="Технические задания, находящиеся на этапе проведения приёмо-сдаточных испытаний"
          />
          <ItemCount
            count={countStatusMap[TechnicalProjectStatus.AWAIT_PSI]}
            type="warning"
          />
        </List.Item>
        <List.Item
          actions={[
            <Link to="/technical-project/list?status=await-passport">
              Перейти
            </Link>,
          ]}
        >
          <List.Item.Meta
            title="Ожидают паспорта продукции"
            description="Завершающий этап производства по техническому заданию, ожидается передача паспортов продукции заказчику"
          />
          <ItemCount
            count={countStatusMap[TechnicalProjectStatus.AWAIT_PASSPORT]}
            type="warning"
          />
        </List.Item>
        <List.Item
          actions={[
            <Link to="/technical-project/list?status=archive">Перейти</Link>,
          ]}
        >
          <List.Item.Meta
            title="Архив"
            description="Завершенные технические задания на производство"
          />
          <ItemCount
            count={countStatusMap[TechnicalProjectStatus.ARCHIVE]}
            type="info"
          />
        </List.Item>
      </List>
    </Card>
  );
};
