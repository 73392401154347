import { Button, Col, Form, Input, Layout, Row } from 'antd';
import React, { useCallback } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { requestPasswordReset } from '../../api/auth.api';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import yup from '../../config/yup';
import { useHistory } from 'react-router';
import {
  LoginContainer,
  LoginDescription,
  LoginForm,
  LoginHeader,
  LoginHeaderContainer,
  Title,
} from './styled';
import { openErrorNotification, openSuccessNotification } from './utils';

const { Content, Footer } = Layout;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Необходимо указать email')
    .required('Необходимо указать email'),
});

const useYupValidationResolver = createYupValidationResolverHook(
  validationSchema,
);

export const RequestPasswordResetPage = () => {
  const history = useHistory();
  const resolver = useYupValidationResolver();
  const { control, handleSubmit, errors } = useForm({ resolver });
  const submitForm = useCallback(async data => {
    try {
      await requestPasswordReset(data.email);
      openSuccessNotification(
        'На указанный email были высланы инструкции по сбросу пароля',
      );
    } catch (e) {
      openErrorNotification('Не удалось найти пользователя с указанным email');
    }
  }, []);

  return (
    <LoginContainer>
      <Content style={{ padding: '100px 0' }}>
        <Row justify="center">
          <Col span={24}>
            <LoginHeaderContainer>
              <LoginHeader src="/img/logo-mini.png" />
              <LoginDescription>Система управления</LoginDescription>
            </LoginHeaderContainer>
          </Col>
          <Col span={24}>
            <div style={{ width: 400, margin: '0 auto' }}>
              <LoginForm onFinish={handleSubmit(submitForm)}>
                <Title>Запрос сброса пароля</Title>
                <Form.Item
                  help={errors.email}
                  validateStatus={errors.email ? 'error' : 'success'}
                  style={{ width: '100%' }}
                >
                  <Controller
                    name="email"
                    control={control}
                    as={
                      <Input
                        size="large"
                        placeholder="Введите email"
                        prefix={<UserOutlined />}
                      />
                    }
                  />
                </Form.Item>

                <Button block type="primary" htmlType="submit">
                  Сбросить пароль
                </Button>
                <Button
                  block
                  type="link"
                  onClick={() => {
                    history.push('/login');
                  }}
                >
                  Назад
                </Button>
              </LoginForm>
            </div>
          </Col>
        </Row>
      </Content>
      <Footer>
        <div style={{ color: 'white', textAlign: 'center' }}>
          TEK-CRM © Разработано в{' '}
          <a href="https://linkodium.com/" target="_blank">
            Linkodium
          </a>{' '}
          (2020)
        </div>
      </Footer>
    </LoginContainer>
  );
};
