import React, { useMemo, useState } from 'react';
import { Button, Dropdown, Menu, Modal, Table } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { AcceptanceTestReportForm } from './AcceptanceTestReportForm';
import { useModal } from '../../hooks/use-modal';
import { API_URL } from '../../api/routes';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { DownloadButton, DownloadMenuItem } from '../shared/DownloadButton';

const useOrderLineColumns = () => {
  return useMemo(() => {
    return [
      {
        title: 'Имя',
        dataIndex: 'name',
      },
      {
        title: 'Количество',
        dataIndex: 'params',
        render: (params) => params.count,
      },
      {
        title: 'Регламент',
        dataIndex: 'regulation',
        render: (regulation) => regulation.name,
      },
    ];
  }, []);
};

const useWeldingOrderLineColumns = ({ item, forceRender, open, close }) => {
  const printPermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'print-psi',
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'manage-psi',
  });

  const viewPermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'view-psi',
  });

  const printPassportPermission = useAccessRule({
    domain: AccessAttributeDomain.TechnicalProject,
    requestedPermission: 'print-passport',
  });

  return useMemo(() => {
    return [
      {
        title: 'Имя',
        dataIndex: 'name',
      },
      {
        title: 'Количество',
        dataIndex: 'params',
        render: (params) => params.count,
      },
      {
        title: 'Регламент',
        dataIndex: 'regulation',
        render: (regulation) => regulation.name,
      },
      {
        title: 'Действия',
        key: 'action',
        fixed: 'right' as const,
        width: 100,
        render: (text, record) => {
          const hasAcceptanceTestReport = record.items.every(
            (item) => item.acceptanceTestReport,
          );
          const acceptanceTestReport =
            record.items.find(
              (item) => hasAcceptanceTestReport && item.acceptanceTestReport,
            ) ?? {};
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    disabled={
                      hasAcceptanceTestReport
                        ? !viewPermission
                        : !managePermission
                    }
                    onClick={() =>
                      open(
                        <AcceptanceTestReportForm
                          itemId={record.id}
                          close={close}
                          item={item}
                          row={acceptanceTestReport}
                          refresh={forceRender}
                        />,
                      )
                    }
                  >
                    {hasAcceptanceTestReport
                      ? 'Обновить акт ПСИ'
                      : 'Добавить акт ПСИ'}
                  </Menu.Item>
                  {hasAcceptanceTestReport && (
                    <DownloadMenuItem
                      key="3"
                      disabled={!printPermission}
                      fileName={`Акт ПСИ №${acceptanceTestReport.actNumber} для ТЗ №${item.number}.docx`}
                      href={`${API_URL}/technical-project/${item.id}/docx/${acceptanceTestReport.id}`}
                    >
                      Скачать акт ПСИ
                    </DownloadMenuItem>
                  )}
                  {hasAcceptanceTestReport && (
                    <DownloadMenuItem
                      key="3"
                      disabled={!printPassportPermission}
                      fileName={`Паспорт продукции №${record.serialNumber} для ТЗ №${item.number}.docx`}
                      href={`${API_URL}/technical-project/${item.id}/product-passport/${record.id}`}
                    >
                      Скачать паспорт продукции
                    </DownloadMenuItem>
                  )}
                </Menu>
              }
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          );
        },
      },
    ];
  }, [item, forceRender, open, close]);
};

const useNestedTableColumns = ({ item, open, close, forceRender, openTab }) => {
  const printPermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'print-psi',
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'manage-psi',
  });

  const viewPermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'view-psi',
  });

  const printPassportPermission = useAccessRule({
    domain: AccessAttributeDomain.TechnicalProject,
    requestedPermission: 'print-passport',
  });

  return useMemo(
    () => [
      {
        title: 'Серийный номер',
        dataIndex: 'serialNumber',
        render: (value) => value.toString().padStart(3, '0'),
      },
      {
        title: 'Акт ПСИ',
        dataIndex: 'acceptanceTestReport',
        render: (value, record) => {
          if (!record.workpiece) {
            return (
              <Button
                danger
                type="link"
                onClick={() => openTab({ type: 'edit', key: item.id })}
              >
                Необходимо добавить заготовки
              </Button>
            );
          }

          return value ? (
            <DownloadButton
              type="link"
              disabled={!printPermission}
              fileName={`Акт ПСИ №${record.acceptanceTestReport.actNumber} для ТЗ №${item.number}.docx`}
              href={`${API_URL}/technical-project/${item.id}/docx/${record.acceptanceTestReport.id}`}
            >
              №{value.actNumber}
            </DownloadButton>
          ) : (
            <Button
              type="link"
              disabled={!managePermission}
              onClick={() =>
                open(
                  <AcceptanceTestReportForm
                    itemId={record.id}
                    close={close}
                    item={item}
                    row={record.acceptanceTestReport}
                    refresh={forceRender}
                  />,
                )
              }
            >
              Не создан
            </Button>
          );
        },
      },
      {
        title: 'Действия',
        key: 'action',
        fixed: 'right' as const,
        width: 100,
        render: (text, record) => {
          if (!record.workpiece) {
            return null;
          }

          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    disabled={
                      record.acceptanceTestReport
                        ? !viewPermission
                        : !managePermission
                    }
                    onClick={() =>
                      open(
                        <AcceptanceTestReportForm
                          itemId={record.id}
                          close={close}
                          item={item}
                          row={record.acceptanceTestReport}
                          refresh={forceRender}
                        />,
                      )
                    }
                  >
                    {record.acceptanceTestReport
                      ? 'Обновить акт ПСИ'
                      : 'Добавить акт ПСИ'}
                  </Menu.Item>
                  {record.acceptanceTestReport && (
                    <DownloadMenuItem
                      key="3"
                      disabled={!printPermission}
                      fileName={`Акт ПСИ №${record.acceptanceTestReport.actNumber} для ТЗ №${item.number}.docx`}
                      href={`${API_URL}/technical-project/${item.id}/docx/${record.acceptanceTestReport.id}`}
                    >
                      Скачать акт ПСИ
                    </DownloadMenuItem>
                  )}
                  {record.acceptanceTestReport && (
                    <DownloadMenuItem
                      key="3"
                      disabled={!printPassportPermission}
                      fileName={`Паспорт продукции №${record.serialNumber} для ТЗ №${item.number}.docx`}
                      href={`${API_URL}/technical-project/${item.id}/product-passport/${record.id}`}
                    >
                      Скачать паспорт продукции
                    </DownloadMenuItem>
                  )}
                </Menu>
              }
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          );
        },
      },
    ],
    [],
  );
};

const BendOrderLineTable = ({ item, open, close, forceRender, openTab }) => {
  const columns = useOrderLineColumns();
  const nestedColumns = useNestedTableColumns({
    item,
    open,
    close,
    forceRender,
    openTab,
  });
  const [expandedRows, setExpandedRows] = useState([]);

  return (
    <Table
      scroll={{ x: 700 }}
      pagination={false}
      columns={columns}
      dataSource={item.order}
      onRow={(record) => ({
        onClick: () =>
          setExpandedRows((prev) => {
            if (prev.includes(record.id)) {
              return prev.filter((id) => id !== record.id);
            }

            return prev.concat(record.id);
          }),
      })}
      rowKey={(record) => record.id}
      expandable={{
        expandedRowKeys: expandedRows,
        onExpand: (expanded, record) =>
          setExpandedRows((prev) => {
            if (!expanded) {
              return prev.filter((id) => id !== record.id);
            }
            return prev.concat(record.id);
          }),
        expandedRowRender: (record) => (
          <Table
            scroll={{ x: 700 }}
            pagination={false}
            columns={nestedColumns}
            dataSource={record.items}
            rowKey={(record) => record.id}
          />
        ),
      }}
    />
  );
};

const WeldingOrderLineTable = ({ item, forceRender, open, close }) => {
  const columns = useWeldingOrderLineColumns({
    item,
    forceRender,
    open,
    close,
  });

  return (
    <Table
      scroll={{ x: 700 }}
      pagination={false}
      columns={columns}
      dataSource={item.order}
      rowKey={(record) => record.id}
    />
  );
};

export const TechnicalProjectOrderLineTable = ({
  item,
  show,
  forceRender,
  openTab,
}) => {
  const { content, visible, open, close } = useModal();

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      {item.type === 'BendTechnicalProject' ? (
        <BendOrderLineTable
          item={item}
          forceRender={forceRender}
          open={open}
          close={close}
          openTab={openTab}
        />
      ) : (
        <WeldingOrderLineTable
          item={item}
          forceRender={forceRender}
          open={open}
          close={close}
        />
      )}

      <Modal
        maskClosable
        destroyOnClose
        width="90%"
        visible={visible}
        footer={null}
        onCancel={close}
      >
        {content}
      </Modal>
    </PageWrapper>
  );
};
