import axios from 'axios';
import { API_URL } from './routes';
import { LoginDto } from '@tek-crm/backend/dist/application/auth/dto/login.dto';

export const login = async (credentials: LoginDto) => {
  const { data } = await axios.post(`${API_URL}/auth/login`, credentials);

  return data;
};

export const requestPasswordReset = async (email: string) => {
  const { data } = await axios.post(`${API_URL}/auth/request-password-reset`, {
    email,
  });

  return data;
};

export const resetPassword = async (
  token: string,
  password: string,
  passwordRepeat: string,
) => {
  const { data } = await axios.post(
    `${API_URL}/auth/reset-password`,
    {
      password,
      passwordRepeat,
    },
    { params: { token } },
  );

  return data;
};
