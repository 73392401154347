import React from 'react';
import { Page } from '../../components/page/Page';
import { TabProps, useTabs } from '../../hooks/use-tabs';
import { usePaginationData } from '../../hooks/use-pagination-data';
import { API_URL } from '../../api/routes';
import { CounterpartyTable } from '../../components/counterparty/CounterpartyTable';
import { CounterpartyForm } from '../../components/counterparty/CounterpartyForm';
import { TabForms } from '../../components/shared/TabForms';

const defaultTabs: TabProps[] = [
  {
    key: 'list',
    type: 'list',
    label: 'Список',
    closable: false,
  },
];

export const CounterpartyPage = () => {
  const { state, onTableChange, refresh } = usePaginationData(
    `${API_URL}/counterparty`,
  );

  const { TabList, activeKey, closeTab, openTab } = useTabs({
    defaultTabs,
    defaultActiveKey: 'list',
    createLabel: (item) =>
      item ? `Контрагент "${item.name}"` : 'Новый контрагент',
    url: `${API_URL}/counterparty`,
  });

  return (
    <Page
      title="Контрагенты"
      subTitle="Просмотр, добавление и редактирование контрагентов компании"
      TabList={TabList}
    >
      <CounterpartyTable
        state={state}
        onTableChange={onTableChange}
        show={'list' === activeKey}
        openTab={openTab}
        refresh={refresh}
      />

      <TabForms
        type={['add', 'edit']}
        excludeKeys={['list']}
        renderItem={(item, { key }) => (
          <CounterpartyForm
            closeTab={closeTab}
            refresh={refresh}
            key={key}
            item={item}
            tabKey={key}
            show={key === activeKey}
          />
        )}
      />
    </Page>
  );
};
