import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { TabProps, TabTypes } from '../../hooks/use-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../redux/root-reducer';
import { createSelector } from '@reduxjs/toolkit';
import { refreshTab, tabLoadingSelector } from '../../redux/features/tabs-data';
import { Skeleton } from 'antd';
import { PageWrapper } from './styled';

export interface TabFormsProps<Types extends TabTypes> {
  type: Types | Types[];
  renderItem: (
    item: any,
    tab: TabProps,
    forceRender?: (params?: { id?: string; key?: string }) => void,
  ) => JSX.Element;
  excludeKeys: string[];
  url?: string;
}

export interface TabbedFormProps {
  item: any;
  refresh: ({ id, key }: { key?: string; id?: string }) => void;
  show: boolean;
}

const tabsOfATypeSelector = createSelector(
  (state: RootStore) => state.tabsData.tabs,
  (_, excludeKeys) => excludeKeys,
  (_, __, type) => type,
  (tabs, excludeKeys, type) =>
    tabs.filter(
      (tab) => !excludeKeys.includes(tab.type) && type.includes(tab.type),
    ),
);

export const TabForms: FunctionComponent<TabFormsProps<any>> = ({
  url,
  type,
  renderItem,
  excludeKeys,
}): any => {
  const dispatch = useDispatch();
  const { loading, activeKey } = useSelector(tabLoadingSelector);
  const tabsOfAType = useSelector((state: RootStore) =>
    tabsOfATypeSelector(
      state,
      excludeKeys,
      Array.isArray(type) ? type : [type],
    ),
  );

  const refresh = useCallback(
    async (id: string, key: string) => {
      if (id && key) {
        dispatch(refreshTab({ url, id, key }));
      }
    },
    [url],
  );

  return useMemo(
    () =>
      tabsOfAType.map((tab) => {
        const item = renderItem(
          tab.item,
          tab,
          (params = { id: tab.id, key: tab.key }) => {
            const { id, key } = params;
            refresh(id, key);
          },
        );
        return loading && tab.key === activeKey ? (
          <PageWrapper>
            <Skeleton active title={false} paragraph={{ rows: 20 }} />
          </PageWrapper>
        ) : (
          item
        );
      }),
    [tabsOfAType, loading, activeKey],
  );
};
