import { createSlice } from '@reduxjs/toolkit';
import { UserDto } from '@tek-crm/backend/dist/application/user/dto/user.dto';
import { AppThunk } from '../thunk/app-thunk.type';

export interface AuthStore {
  user: UserDto;
  authenticated: boolean;
  token: string;
  refreshToken: string;
}

const initialState: AuthStore = {
  authenticated: false,
  user: undefined,
  token: undefined,
  refreshToken: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const { user, jwtToken, refreshToken } = action.payload;
      state.authenticated = true;
      state.user = user;
      state.token = jwtToken;
      state.refreshToken = refreshToken;
    },
    logout: (state) => {
      return initialState;
    },
    saveTokens: (state, action) => {
      const { accessToken, refreshToken } = action.payload;

      state.token = accessToken;
      state.refreshToken = refreshToken;
    },
  },
});

export const logoutAndClearStorage = (): AppThunk => (dispatch) => {
  dispatch(logout());
  dispatch({ type: 'RESET' });
};

export const auth = authSlice.reducer;
export const { login, logout, saveTokens } = authSlice.actions;
