import React, { FunctionComponent, useMemo } from 'react';
import { Input } from 'antd';
import { PrefixProp } from '../workpiece/WorkpieceForm';
import { RegulationDto } from '@tek-crm/backend/dist/application/regulation/dto/regulation.dto';
import { createAddon } from '../../utils/create-addon';
import { FormControl } from '../form-controls/FormControl';

export const GeometryParamsFields: FunctionComponent<PrefixProp & {
  type: 'product' | 'supply';
  regulation?: RegulationDto;
}> = ({ prefix, type, regulation }) => {
  const namePrefix = useMemo(
    () => (prefix && prefix.length > 0 ? `${prefix}` : ''),
    [prefix],
  );

  return (
    <>
      {type === 'product' && (
        <>
          <FormControl
            label="Угол гиба"
            name={`${namePrefix}.bendingAngle`}
            as={
              <Input
                autoComplete="new-password"
                addonBefore={createAddon(
                  regulation,
                  `${namePrefix}.bendingAngle`,
                )}
              />
            }
          />

          <FormControl
            label="Радиус гиба"
            name={`${namePrefix}.bendingRadius`}
            as={
              <Input
                autoComplete="new-password"
                addonBefore={createAddon(
                  regulation,
                  `${namePrefix}.bendingRadius`,
                )}
              />
            }
          />

          <FormControl
            label="Размеры гофры"
            name={`${namePrefix}.corrugationSize`}
            as={
              <Input
                autoComplete="new-password"
                addonBefore={createAddon(
                  regulation,
                  `${namePrefix}.corrugationSize`,
                )}
              />
            }
          />

          <FormControl
            label="Косина реза"
            name={`${namePrefix}.obliqueCut`}
            as={
              <Input
                autoComplete="new-password"
                addonBefore={createAddon(
                  regulation,
                  `${namePrefix}.obliqueCut`,
                )}
              />
            }
          />
        </>
      )}

      {type !== 'product' && (
        <FormControl
          label="Толщина стенки"
          name={`${namePrefix}.wallThickness`}
          as={
            <Input
              autoComplete="new-password"
              addonBefore={createAddon(
                regulation,
                `${namePrefix}.wallThickness`,
              )}
            />
          }
        />
      )}

      <FormControl
        label="Номинальный диаметр"
        name={`${namePrefix}.nominalDiameter`}
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(
              regulation,
              `${namePrefix}.nominalDiameter`,
            )}
          />
        }
      />

      <FormControl
        label="Наружный диаметр, max"
        name={`${namePrefix}.maxDiameter`}
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `${namePrefix}.maxDiameter`)}
          />
        }
      />

      <FormControl
        label="Наружный диаметр, min"
        name={`${namePrefix}.minDiameter`}
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `${namePrefix}.minDiameter`)}
          />
        }
      />

      <FormControl
        label="Овальность, торец О1"
        name={`${namePrefix}.ovality1end`}
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `${namePrefix}.ovality1end`)}
          />
        }
      />

      <FormControl
        label="Овальность, торец О2"
        name={`${namePrefix}.ovality2end`}
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `${namePrefix}.ovality2end`)}
          />
        }
      />

      {type === 'product' && (
        <>
          <FormControl
            label="Овальность, гнутая часть"
            name={`${namePrefix}.ovalityBend`}
            as={
              <Input
                autoComplete="new-password"
                addonBefore={createAddon(
                  regulation,
                  `${namePrefix}.ovalityBend`,
                )}
              />
            }
          />

          <FormControl
            label="Строительная длина, А, мм"
            name={`${namePrefix}.faceToFaceLengthA`}
            as={
              <Input
                autoComplete="new-password"
                addonBefore={createAddon(
                  regulation,
                  `${namePrefix}.faceToFaceLengthA`,
                )}
              />
            }
          />

          <FormControl
            label="Строительная длина, B, мм"
            name={`${namePrefix}.faceToFaceLengthB`}
            as={
              <Input
                autoComplete="new-password"
                addonBefore={createAddon(
                  regulation,
                  `${namePrefix}.faceToFaceLengthB`,
                )}
              />
            }
          />

          <FormControl
            label="Прямой участок, A"
            name={`${namePrefix}.straightSectionA`}
            as={
              <Input
                autoComplete="new-password"
                addonBefore={createAddon(
                  regulation,
                  `${namePrefix}.straightSectionA`,
                )}
              />
            }
          />

          <FormControl
            label="Прямой участок, B"
            name={`${namePrefix}.straightSectionB`}
            as={
              <Input
                autoComplete="new-password"
                addonBefore={createAddon(
                  regulation,
                  `${namePrefix}.straightSectionB`,
                )}
              />
            }
          />
        </>
      )}
    </>
  );
};
