import { usePaginationData } from '../../hooks/use-pagination-data';
import { API_URL } from '../../api/routes';
import { Table } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useColumnSearchProps } from '../../hooks/use-column-search-props';

const useWorkpieceColumns = () => {
  const getColumnSearchProps = useColumnSearchProps();
  return useMemo(
    () => [
      {
        title: 'Завод-изготовитель',
        ...getColumnSearchProps(['supply', 'manufacturer', 'name']),
      },
      {
        title: 'Дата',
        ...getColumnSearchProps(['supply', 'date'], 'date'),
      },
      {
        title: 'Номер сертификата',
        ...getColumnSearchProps(['supply', 'qualityCertificate', 'number']),
      },
      {
        title: 'Номер плавки',
        ...getColumnSearchProps('meltingNumber'),
      },
      {
        title: 'Серийный номер',
        ...getColumnSearchProps('serialNumber'),
      },
      {
        title: 'Номинальный диаметр',
        dataIndex: 'nominalDiameter',
      },
      {
        title: 'Толщина стенки',
        dataIndex: 'wallThickness',
      },
      {
        title: 'Длина',
        dataIndex: 'length',
      },
      {
        title: 'Остаток длины',
        dataIndex: 'remainingLength',
      },
    ],
    [getColumnSearchProps],
  );
};

export const WorkpiecesList = ({
  itemLength,
  selectedRowKeys,
  handleChange,
  selectedOrderItems,
}) => {
  const { state, onTableChange } = usePaginationData(
    `${API_URL}/supply/workpieces`,
    {
      additionalParams: {
        requiredRemainingLength: itemLength * selectedOrderItems.length,
      },
    },
  );
  const handleSelectionChange = useCallback(async rowKeys => {
    handleChange(rowKeys);
  }, []);

  const columns = useWorkpieceColumns();

  const handleOnRow = useCallback(
    record => ({
      onClick: () =>
        handleChange(prev => {
          if (prev.includes(record.id)) {
            return prev;
          }

          return [record.id];
        }),
    }),
    [],
  );

  return (
    <Table
      scroll={{ x: 500 }}
      columns={columns}
      rowKey={record => record.id}
      dataSource={state.data}
      pagination={state.pagination}
      loading={state.loading}
      onChange={onTableChange}
      onRow={handleOnRow}
      rowSelection={{
        type: 'radio',
        preserveSelectedRowKeys: true,
        onChange: handleSelectionChange,
        selectedRowKeys,
      }}
    />
  );
};
