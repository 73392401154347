import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk } from '../thunk/app-thunk.type';
import { batch } from 'react-redux';
import { TabProps } from '../../hooks/use-tabs';
import React from 'react';
import { RootStore } from '../root-reducer';

export interface TabsDataState {
  tabs: TabProps[];
  activeKey?: string;
  loading: boolean;
}

const initialState: TabsDataState = {
  tabs: [],
  loading: false,
};

export const fetchTabData = createAsyncThunk(
  'tab/fetchData',
  async (args: any, thunkAPI) => {
    const { key, id, url, labelFactory } = args;
    const { data } = await axios.get(`${url}/${id}`);
    return { key, id, data, labelFactory };
  },
);

export const refreshTab = createAsyncThunk(
  'tab/refreshData',
  async (args: any, thunkAPI) => {
    const { url, id, key } = args;
    const { data } = await axios.get(`${url}/${id}`);
    return { key, data };
  },
);

export const tabLoadingSelector = createSelector(
  (state: RootStore) => state.tabsData.loading,
  (state: RootStore) => state.tabsData.activeKey,
  (loading, activeKey) => ({
    loading,
    activeKey,
  }),
);

const slice = createSlice({
  initialState,
  name: 'tabs-context',
  reducers: {
    setActiveKey: (state, action) => {
      state.activeKey = action.payload;
    },
    addTab: (state, action) => {
      state.tabs.push(action.payload);
    },
    removeTab: (state, action) => {
      state.tabs = state.tabs.filter(tab => tab.key !== action.payload);
    },
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchTabData.pending, (state, action) => {
      const { key, id, labelFactory } = action.meta.arg;

      state.loading = true;
      state.tabs.push({
        id,
        closable: true,
        label: labelFactory.loading(),
        key: `${key}/${id}`,
        type: key as any,
        item: undefined,
      });
    });

    builder.addCase(refreshTab.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchTabData.fulfilled, (state, action) => {
      const { data, key, id, labelFactory } = action.payload;
      const tab = state.tabs.find(t => t.key === `${key}/${id}`);

      tab.label = labelFactory(data, key);
      tab.item = data;

      state.loading = false;
    });

    builder.addCase(refreshTab.fulfilled, (state, action) => {
      const { data, key } = action.payload;
      const tab = state.tabs.find(t => t.key === key);
      const index = state.tabs.indexOf(tab);

      state.loading = false;
      state.tabs[index] = {
        ...tab,
        item: data,
      };
    });
  },
});

export const openInitialTab = ({
  url,
  key,
  id,
  labelFactory,
  defaultActiveKey,
  defaultTabs,
}): AppThunk => (dispatch, getState) => {
  const {
    tabsData: { tabs },
  } = getState();

  console.log({
    url,
    key,
    id,
    defaultActiveKey,
    defaultTabs,
    tabs,
  });

  batch(() => {
    for (const defaultTab of defaultTabs) {
      tabs.every(tab => tab.key !== defaultTab.key) &&
        dispatch(addTab(defaultTab));
    }
  });

  if (id) {
    batch(() => {
      dispatch(fetchTabData({ key, id, url, labelFactory }));
      dispatch(setActiveKey(`${key}/${id}`));
    });
    return;
  }

  if (
    key !== defaultActiveKey &&
    !tabs.find(tab => tab.key === key) &&
    !defaultTabs.find(tab => tab.key === key)
  ) {
    console.log('tobi pizda');
    batch(() => {
      dispatch(
        addTab({
          closable: true,
          label: labelFactory(undefined, key as any),
          key: key,
          type: key as any,
        }),
      );
      dispatch(setActiveKey(key));
    });
    return;
  }

  dispatch(setActiveKey(key ?? defaultActiveKey));
};

export const tabsData = slice.reducer;
export const { setActiveKey, addTab, removeTab, reset } = slice.actions;
