import React, { useCallback, useMemo } from 'react';
import { Avatar, Badge, Dropdown, Layout as ALayout, Menu } from 'antd';
import styled from 'styled-components';
import './Layout.css';
import { shade } from 'polished';
import { menu } from '../../config/menu';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { parse } from 'path-to-regexp';
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAndClearStorage } from '../../redux/features/auth';
import { RootStore } from '../../redux/root-reducer';

const { Sider, Footer } = ALayout;

const Logo = styled.img`
  width: 160px;
  margin: 20px;
`;

const MenuWrapper = styled.div`
  padding-top: 10px;

  && {
    .ant-menu-item {
      height: auto;
    }

    li,
    .ant-menu-submenu-title {
      * {
        padding: 0;
        margin: 0;
      }

      display: flex;
      flex-wrap: wrap;
      height: auto;
    }

    li {
      text-align: center;
      padding: 20px 0;
      margin: 0;

      span {
        flex: 1 1 100%;

        svg {
          width: 32px;
          height: 32px;
          margin-bottom: 10px;
        }

        :last-child {
          line-height: normal;
        }
      }

      :after {
        border: 4px solid #8b231d;
        border-radius: 15px;
      }
    }

    &&& .ant-menu-item-selected {
      background-color: ${shade(0.4, '#001529')};

      span svg {
        color: #8b231d;
      }
    }
  }
`;

const HeaderContainer = styled(ALayout.Header)`
  && {
    padding: 0;
    line-height: normal;
    height: 40px;
  }
`;

const Header = styled.div`
  && {
    height: 40px;
    position: relative;
    display: flex;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    z-index: 1;
    padding: 0 50px;
    justify-content: flex-end;
  }
`;

const HeaderButtons = styled.div``;

const ProfileDropdown = styled(Menu)`
  && {
    .ant-dropdown-menu-item {
      min-width: 160px;
    }
  }
`;

const ProfileName = styled.div`
  & {
    display: flex;
    align-items: center;
    min-width: 150px;
    height: 100%;
    padding: 0 12px;

    .ant-badge {
      margin-right: 10px;
    }

    :hover {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }
`;

const useMenuItemsProcessor = accessAttributes => {
  const itemProcessor = useCallback(
    items => {
      return items.map(item => {
        let result;

        if (
          item.items &&
          item.items.some(subItem =>
            accessAttributes.some(
              attribute => attribute.domain === subItem.domain,
            ),
          )
        ) {
          result = (
            <Menu.SubMenu key={item.page} icon={item.icon} title={item.title}>
              {itemProcessor(item.items)}
            </Menu.SubMenu>
          );
        } else if (
          item.domain === true ||
          (accessAttributes &&
            accessAttributes.some(
              attribute => attribute.domain === item.domain,
            ))
        ) {
          result = (
            <Menu.Item key={item.page} icon={item.icon}>
              <Link to={item.page}>{item.title}</Link>
            </Menu.Item>
          );
        } else {
          result = null;
        }

        return result;
      });
    },
    [accessAttributes],
  );

  return itemProcessor;
};

export const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [basePath] = useMemo(() => parse(match.path), [match.path]);
  const { header } = menu;
  const auth = useSelector((state: RootStore) => state.auth);
  const processMenuItems = useMenuItemsProcessor(auth.user.accessAttributes);
  return (
    <ALayout style={{ minHeight: '100%' }}>
      <Sider trigger={null} collapsible collapsed={false}>
        <Link to="/">
          <Logo src="/img/logo-mini.png" />
        </Link>
        <MenuWrapper>
          <Menu
            theme="dark"
            mode="inline"
            inlineIndent={0}
            selectedKeys={[basePath as any]}
            defaultOpenKeys={['dictionary']}
          >
            {processMenuItems(header.items)}
          </Menu>
        </MenuWrapper>
      </Sider>
      <ALayout>
        <HeaderContainer>
          <Header>
            <HeaderButtons>
              <Dropdown
                trigger={['click']}
                overlay={
                  <ProfileDropdown>
                    <ProfileDropdown.Item icon={<SettingOutlined />}>
                      Профиль
                    </ProfileDropdown.Item>
                    <ProfileDropdown.Divider />
                    <ProfileDropdown.Item
                      icon={<LogoutOutlined />}
                      onClick={() => dispatch(logoutAndClearStorage())}
                    >
                      Выйти
                    </ProfileDropdown.Item>
                  </ProfileDropdown>
                }
              >
                <ProfileName>
                  <Badge>
                    <Avatar
                      shape="circle"
                      size={25}
                      icon={<UserOutlined />}
                      style={{ background: '#994139' }}
                    />
                  </Badge>
                  {auth.user.name}
                </ProfileName>
              </Dropdown>
            </HeaderButtons>
          </Header>
        </HeaderContainer>
        {children}
        <Footer style={{ textAlign: 'center' }}>
          TEK-CRM © Разработано в{' '}
          <a href="https://linkodium.com/" target="_blank">
            Linkodium
          </a>{' '}
          (2020)
        </Footer>
      </ALayout>
    </ALayout>
  );
};
