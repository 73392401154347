import { notification } from 'antd';

export const openErrorNotification = (
  description: string = 'Не правильное имя пользователя или пароль',
) => {
  notification.error({
    message: 'Ошибка',
    description,
  });
};
export const openSuccessNotification = (message: string = 'Успешно') => {
  notification.success({
    message,
  });
};
