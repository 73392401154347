import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { UploadProps } from 'antd/lib/upload';
import { Button, notification, Upload } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import { DocumentDto } from '@tek-crm/backend/dist/application/document/dto/document.dto';
import axios from 'axios';
import styled from 'styled-components';
import { extractErrorInfo } from '../../utils/extractError';
import { DocumentType } from '@tek-crm/backend/dist/domain/document/interfaces/document.type';
import { UploadRequestOption } from 'rc-upload/lib/interface';

const StyledUpload = styled(Upload)`
  & {
    .ant-upload-select {
      width: 100%;
    }
  }
`;

const customRequest = (options: any): void => {
  const {
    onProgress,
    onSuccess,
    onError,
    file,
    filename,
    data,
    action,
  } = options;
  const { type, target, onSuccessfulUpload } = data as any;

  const formData = new FormData();
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: event => {
      onProgress({ percent: (event.loaded / event.total) * 100 }, file);
    },
  };

  formData.set('type', type);
  formData.set('target', target);
  formData.set(filename, file);

  axios
    .post(action, formData, config)
    .then(res => {
      onSuccess(res, file);
      onSuccessfulUpload(res.data);
    })
    .catch(err => {
      onError(err, err.response, file);
      notification.error(extractErrorInfo(err));
    });
};

const defaultProps: any = {
  customRequest,
  name: 'documents',
  multiple: true,
  showUploadList: false,
};

export interface UploadButtonProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  documentType: DocumentType;
  onSuccessfulUpload: (data: DocumentDto[]) => void;
  target: string;
}

export const UploadButton: FunctionComponent<UploadProps &
  UploadButtonProps> = ({
  documentType,
  onSuccessfulUpload,
  target,
  loading,
  setLoading,
  children,
  ...props
}) => {
  const [files, setFiles] = useState([]);

  const handleChange = useCallback(
    ({ fileList }) => {
      if (fileList.some(file => file.status !== 'done')) {
        if (!loading) {
          setLoading(true);
        }

        setFiles(fileList);
        return;
      }

      setFiles([]);
      setLoading(false);

      notification.success({
        message: 'Успешно!',
        description: 'Документы были загружены',
      });
    },
    [loading],
  );
  const handleError = useCallback(
    (...args) => {
      setLoading(false);

      if ((props as any).onError) {
        (props as any).onError(...args);
      }
    },
    [props],
  );

  return (
    <StyledUpload
      {...defaultProps}
      {...props}
      data={{ type: documentType, onSuccessfulUpload, target }}
      fileList={files}
      onChange={handleChange}
      onError={handleError}
    >
      <Button block type="link" icon={<FileImageOutlined />}>
        {children}
      </Button>
    </StyledUpload>
  );
};
