import React, {
  FunctionComponent,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Descriptions, Divider, Input, InputNumber, Tabs } from 'antd';
import { useFormContext } from 'react-hook-form';
import * as math from 'mathjs';
import { pick, throttle } from 'lodash';
import { CreateAcceptanceTestReportDto } from '@tek-crm/backend/dist/application/technical-project/dto/acceptance-test-report/create/create-acceptance-test-report.dto';
import { API_URL } from '../../api/routes';
import { SearchSelect } from '../form-controls/SearchSelect';
import { FormControl } from '../form-controls/FormControl';
import { usePrevious } from '../../hooks/use-previous';
import { FormTabs } from '../shared/FormTabs';
import { OrderItemTable } from './OrderItemTable';
import { CreateBendOrderParamsDto } from '@tek-crm/backend/dist/application/technical-project/dto/create/create-bend-order-params.dto';

const getValue = (val: any): number => {
  const num: number = Number(val).valueOf();

  if (!isNaN(num)) {
    return num;
  } else return 0;
};

const calculate = ({ namePrefix, values, setValue }) => {
  const {
    entries: [
      straightSectionB,
      lengthOfSweep,
      pureWeight,
      finishSizeL,
      roughSizeL,
      consumptionRateLength,
      consumptionRateWeight,
      faceToFaceLengthA,
      faceToFaceLengthB,
      bentPartLength,
      usedOnOrderWeight,
      usedOnOrderLength,
      totalBentPartLength,
    ],
  } = math.evaluate(`
        bendingAngle = ${getValue(values.bendingAngle)};
        allowance = ${getValue(values.allowance)};
        bendingRadius = ${getValue(values.bendingRadius)};
        straightSectionA = ${getValue(values.straightSectionA)};
        count = ${getValue(values.count)};
        nominalDiameter = ${getValue(values.nominalDiameter)};
        roughSizeDB = ${getValue(values.roughSizeDB)};
        roughSizeSH = ${getValue(values.roughSizeSH)};
        finishSizeDB = ${getValue(values.finishSizeDB)};
        finishSizeSH = ${getValue(values.finishSizeSH)};
        linearMetres = (roughSizeDB - roughSizeSH) * roughSizeSH * 0.02466;
        
        straightSectionB = straightSectionA
        lengthOfSweep = format(1 / (360/bendingAngle) * 2 * 3.14159 * nominalDiameter * bendingRadius + straightSectionA + straightSectionB, 3)
        pureWeight = format(linearMetres * lengthOfSweep / 1000, 3)
        finishSizeL = floor(lengthOfSweep)
        roughSizeL = floor(finishSizeL + allowance)
        consumptionRateLength = format((roughSizeL + 2) / 1000, 3)
        consumptionRateWeight = format(linearMetres * consumptionRateLength, 3)
        faceToFaceLengthA = floor(straightSectionA + ((nominalDiameter * bendingRadius) / tan(((180 - bendingAngle)/2) deg to rad)))
        faceToFaceLengthB = faceToFaceLengthA
        bentPartLength = lengthOfSweep - straightSectionA - straightSectionB
        usedOnOrderWeight = format(count * consumptionRateWeight, 3)
        usedOnOrderLength = format(count * consumptionRateLength, 3)
        totalBentPartLength = bentPartLength * count
    `);

  setValue(namePrefix, {
    ...values,
    straightSectionB,
    lengthOfSweep,
    pureWeight,
    finishSizeL,
    roughSizeL,
    consumptionRateLength,
    consumptionRateWeight,
    faceToFaceLengthA,
    faceToFaceLengthB,
    bentPartLength,
    usedOnOrderWeight,
    usedOnOrderLength,
    totalBentPartLength,
  });
};

const throttledCalculate = throttle(calculate, 200);

const useCalculatePipelineBendFields = ({ namePrefix, values }) => {
  const { setValue } = useFormContext();
  const prevPrefix = usePrevious(namePrefix);
  useEffect(() => {
    if (prevPrefix === namePrefix) {
      throttledCalculate({ namePrefix, values, setValue });
    }

    return throttledCalculate.cancel;
  }, [
    namePrefix,
    JSON.stringify(
      pick(values, [
        'bendingAngle',
        'allowance',
        'bendingRadius',
        'straightSectionA',
        'count',
        'nominalDiameter',
        'roughSizeDB',
        'roughSizeSH',
        'finishSizeDB',
        'finishSizeSH',
      ]),
    ),
  ]);
};

const CalculateContainer = ({ namePrefix }) => {
  const { watch } = useFormContext<CreateAcceptanceTestReportDto>();
  const values = watch<string, CreateBendOrderParamsDto>(namePrefix, {} as any);

  useCalculatePipelineBendFields({
    values,
    namePrefix,
  });

  return (
    <Descriptions bordered column={4} style={{ marginBottom: '20px' }}>
      <Descriptions.Item span={2} label="Стр. длина A">
        {values.faceToFaceLengthA}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Стр. длина B">
        {values.faceToFaceLengthB}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Норма расхода, кг">
        {values.consumptionRateWeight}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Норма расхода, м">
        {values.consumptionRateLength}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Расход на заказ, кг">
        {values.usedOnOrderWeight}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Расход на заказ, м">
        {values.usedOnOrderLength}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Черновой размер, L">
        {values.roughSizeL}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Чистовой размер, L">
        {values.finishSizeL}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Прямой участок B">
        {values.straightSectionB}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Длина развёртки">
        {values.lengthOfSweep}
      </Descriptions.Item>
      <Descriptions.Item span={4} label="Чистый вес">
        {values.pureWeight}
      </Descriptions.Item>
    </Descriptions>
  );
};

export const BendOrderLineFields: FunctionComponent<any> = memo(
  ({ index }) => {
    const namePrefix = useMemo(() => (index > -1 ? `order[${index}]` : ''), [
      index,
    ]);
    const [view, setView] = useState('info');
    const { watch, errors } = useFormContext();
    const orderLine = watch(namePrefix);
    const itemLength = watch(`${namePrefix}.params.roughSizeL`);

    return (
      <FormTabs
        errors={errors}
        activeKey={view}
        onChange={viewKey => {
          setView(viewKey);
        }}
      >
        <Tabs.TabPane forceRender tab="Информация" key="info">
          <CalculateContainer namePrefix={`${namePrefix}.params`} />

          <Divider>Данные позиции заказа</Divider>

          <FormControl
            label="Наименование"
            name={`${namePrefix}.name`}
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Регламент"
            name={`${namePrefix}.regulationId`}
            as={
              <SearchSelect
                name={`${namePrefix}.regulationId`}
                url={`${API_URL}/regulation/find-by-query?type=product`}
              />
            }
          />

          <FormControl
            label="Количество"
            name={`${namePrefix}.params.count`}
            as={
              <InputNumber
                disabled={orderLine.id}
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Прямой участок A"
            name={`${namePrefix}.params.straightSectionA`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Угол гиба"
            name={`${namePrefix}.params.bendingAngle`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="R гиба"
            name={`${namePrefix}.params.bendingRadius`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Номинальный диаметр"
            name={`${namePrefix}.params.nominalDiameter`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Припуск"
            name={`${namePrefix}.params.allowance`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Черновой размер, D/B"
            name={`${namePrefix}.params.roughSizeDB`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Черновой размер, S/H"
            name={`${namePrefix}.params.roughSizeSH`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Чистовой размер, D/B"
            name={`${namePrefix}.params.finishSizeDB`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />

          <FormControl
            label="Чистовой размер, S/H"
            name={`${namePrefix}.params.finishSizeSH`}
            as={
              <InputNumber
                autoComplete="new-password"
                style={{ width: '100%' }}
              />
            }
          />
        </Tabs.TabPane>
        <Tabs.TabPane forceRender tab="Заготовки" key="workpieces">
          <OrderItemTable
            index={index}
            namePrefix={namePrefix}
            itemLength={itemLength}
          />
        </Tabs.TabPane>
      </FormTabs>
    );
  },
  (prev, next) => prev.index === next.index,
);
