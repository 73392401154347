import { useCallback, useState } from 'react';

export const useModal = () => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(null);

  const open = useCallback((cnt = null) => {
    setContent(cnt);
    setVisible(true);
  }, []);

  const close = useCallback(() => {
    setVisible(false);
    setTimeout(() => setContent(null), 300);
  }, []);

  return {
    content,
    visible,
    open,
    close,
  };
};
