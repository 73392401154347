export const layout = {
  labelCol: {
    xxl: { span: 6 },
    xl: { span: 8 },
    lg: { span: 12 },
  },
  wrapperCol: {
    xxl: { span: 18 },
    xl: { span: 16 },
    lg: { span: 12 },
  },
};

export const fullWidthLayout = {
  labelCol: {
    xxl: { span: 6 },
    xl: { span: 4 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xxl: { span: 12 },
    xl: { span: 16 },
    lg: { span: 14 },
  },
};
