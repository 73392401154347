import React, { FunctionComponent, useMemo } from 'react';
import { InputNumber } from 'antd';
import { PrefixProp } from '../workpiece/WorkpieceForm';
import { RegulationDto } from '@tek-crm/backend/dist/application/regulation/dto/regulation.dto';
import { FormControl } from '../form-controls/FormControl';

export const SteelParamsFields: FunctionComponent<PrefixProp & {
  regulation?: RegulationDto;
}> = ({ prefix, regulation }) => {
  const namePrefix = useMemo(
    () => (prefix && prefix.length > 0 ? `${prefix}` : ''),
    [prefix],
  );

  return (
    <>
      <FormControl
        label="Предел текучести, σ0,2"
        name={`${namePrefix}.flowStress`}
        as={
          <InputNumber
            autoComplete="new-password"
            step={0.01}
            min={0}
            style={{ width: '100%' }}
          />
        }
      />

      <FormControl
        label="Временное сопротивление разрыву, σвр"
        name={`${namePrefix}.tensileStrength`}
        as={
          <InputNumber
            autoComplete="new-password"
            step={0.01}
            min={0}
            style={{ width: '100%' }}
          />
        }
      />

      <FormControl
        label="Относительное удлинение"
        name={`${namePrefix}.relativeExtension`}
        as={
          <InputNumber
            autoComplete="new-password"
            step={0.01}
            min={0}
            style={{ width: '100%' }}
          />
        }
      />

      <FormControl
        as={
          <InputNumber
            autoComplete="new-password"
            step={0.01}
            min={0}
            style={{ width: '100%' }}
          />
        }
        name={`${namePrefix}.toughnessKCU`}
        label="Ударная вязкость KCU"
      />

      <FormControl
        as={
          <InputNumber
            autoComplete="new-password"
            step={0.01}
            min={0}
            style={{ width: '100%' }}
          />
        }
        name={`${namePrefix}.toughnessKCV`}
        label="Ударная вязкость KCV"
      />

      <FormControl
        label="Предел текучести / Временное сопротивление разрыву (σ0,2 / σвр)"
        name={`${namePrefix}.FStoT`}
        as={
          <InputNumber
            autoComplete="new-password"
            step={0.01}
            min={0}
            style={{ width: '100%' }}
          />
        }
      />
    </>
  );
};
