import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { logoutAndClearStorage, saveTokens } from '../redux/features/auth';
import store from '../redux/store';
import { API_URL } from './routes';
import { notification } from 'antd';

export const accessTokenSelector = state =>
  state.auth ? state.auth.token : undefined;
export const refreshTokenSelector = state => state.auth.refreshToken;
export const setAuthHeader = token => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

store.subscribe(() => {
  const accessToken = accessTokenSelector(store.getState());
  setAuthHeader(accessToken);
});

const refreshAccessToken = async failedRequest => {
  const oldRefreshToken = refreshTokenSelector(store.getState());
  setAuthHeader(oldRefreshToken);

  return axios
    .get(`${API_URL}/auth/refresh`, { skipAuthRefresh: true } as any)
    .then(({ data }) => {
      store.dispatch(saveTokens(data));

      failedRequest.config.headers.Authorization = 'Bearer ' + data.accessToken;

      return Promise.resolve(failedRequest);
    })
    .catch(() => Promise.reject(failedRequest));
};

createAuthRefreshInterceptor(axios, refreshAccessToken, {
  pauseInstanceWhileRefreshing: true,
});

axios.interceptors.request.use(config => {
  config.headers.Authorization = axios.defaults.headers.common.Authorization;
  return config;
});

axios.interceptors.response.use(
  request => request,
  failedRequest => {
    if (
      failedRequest.response &&
      failedRequest.response.status === 401 &&
      failedRequest.response.config.url.match(/refresh/)
    ) {
      store.dispatch(logoutAndClearStorage());
      notification.warning({
        message: 'Время сессии истекло',
      });
    }

    return Promise.reject(failedRequest);
  },
);
