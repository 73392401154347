import { Button, Divider, Form, Input, notification, Space } from 'antd';
import { FormControl } from '../form-controls/FormControl';
import { ParamsFields } from '../params/ParamsFields';
import React, { FunctionComponent, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TabbedFormProps } from '../../interfaces/tabbed-form.props';
import { usePreservedFormState } from '../../hooks/use-preserved-form-state';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import { fullWidthLayout } from '../../config/form';
import { PageWrapper } from '../shared/styled';
import { createEmptyStrengthClass } from '../regulation/helpers';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { create, update } from '../../api/strength-class.api';
import yup from '../../config/yup';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';

const processData = data => {
  const { params, ...rest } = data;
  const processedParams = {};

  for (const [key, { addon, value, valueOther }] of Object.entries(
    params,
  ) as any) {
    processedParams[key] =
      addon !== '-' ? `${addon}${value}` : `${value}-${valueOther}`;
  }

  return { ...rest, params: processedParams };
};

const strengthClassToFormData = ({ params, ...rest }) => {
  const processedParams = {};
  for (const [key, data] of Object.entries<string>(params)) {
    if (!data) {
      processedParams[key] = {};
      continue;
    }

    let value, valueOther, addon;
    let valueMatches;
    const addonMatches = data.match(/([=<>-]+)/);

    if (addonMatches) {
      addon = addonMatches[0];
    }

    if (addon === '-') {
      valueMatches = data.match(/([\d+.?]+)-([\d+.?]+)/);
    } else {
      valueMatches = data.match(/[\d+.?]+/);
    }

    if (valueMatches && addon === '-') {
      [, value, valueOther] = valueMatches;
    } else if (valueMatches) {
      [value] = valueMatches;
    }

    processedParams[key] = {
      value,
      valueOther,
      addon,
    };
  }

  return { ...rest, params: processedParams };
};

const valueSchema = yup.object({
  addon: yup.string().required(),
  value: yup
    .number()
    .toNumOrNull()
    .required(),
  valueOther: yup.mixed().when('addon', (addonValue, schema) =>
    addonValue === '-'
      ? yup
          .number()
          .toNumOrNull()
          .required()
      : schema,
  ),
});

const schema = yup.object({
  name: yup.string().required(),
  params: yup.object({
    flowStress: valueSchema,
    tensileStrength: valueSchema,
    relativeExtension: valueSchema,
    toughnessKCU: valueSchema,
    toughnessKCV: valueSchema,
    FStoT: valueSchema,
    remanence: valueSchema,
  }),
});

const useValidationResolver = createYupValidationResolverHook(schema);

export const StrengthClassForm: FunctionComponent<TabbedFormProps> = ({
  show,
  item,
  closeTab,
  tabKey,
  refresh,
}) => {
  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Regulation,
    requestedPermission: 'manage',
  });

  const resolver = useValidationResolver();

  const methods = useForm<any>({
    defaultValues: item
      ? strengthClassToFormData(item)
      : createEmptyStrengthClass(),
    shouldUnregister: false,
    resolver,
  });
  console.log(methods.errors);

  usePreservedFormState({
    show,
    watch: methods.watch,
    reset: methods.reset,
    origin: item ? 'edit' : 'add',
  });

  const submitForm = useSubmitFormHandler({
    formState: methods.formState,
    handleSubmit: methods.handleSubmit,
    onValid: useCallback(
      async data => {
        const processed = processData(data);
        if (!item) {
          await create(processed);
        } else {
          await update(item.id, processed);
        }

        notification.success({
          message: 'Успешно!',
          description: `Класс прочности был успешно ${
            item ? 'сохранён' : 'обновлён'
          }`,
        });
      },
      [item],
    ),
    closeTab: () => {
      methods.reset();
      refresh();
      closeTab(tabKey)();
    },
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Form
          autoComplete="new-password"
          {...fullWidthLayout}
          onFinish={submitForm}
        >
          <FormControl
            as={<Input autoComplete="new-password" />}
            name={`name`}
            label="Название"
          />

          <Divider>Механические параметры</Divider>

          <ParamsFields prefix={`params`} />

          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              block
              /*loading={methods.formState.isSubmitting}*/
              disabled={methods.formState.isSubmitting || !managePermission}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>
            {closeTab && (
              <Button block danger type="dashed" onClick={closeTab(tabKey)}>
                Закрыть
              </Button>
            )}
          </Space>
        </Form>
      </FormProvider>
    </PageWrapper>
  );
};
