import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Form, Input, InputNumber, notification, Row, Tabs } from 'antd';
import { layout } from '../../config/form';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { GeometryParamsFields } from '../params/GeometryParamsFields';
import { ReceiptControlActMainFields } from './ReceiptControlActMainFields';
import { SteelParamsFields } from '../params/SteelParamsFields';
import { WeldingParamsFields } from '../params/WeldingParamsFields';
import { PrefixProp } from '../workpiece/WorkpieceForm';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import {
  createReceiptControlAct,
  updateReceiptControlAct,
} from '../../api/supply.api';
import { FormControl } from '../form-controls/FormControl';
import {
  createSteelParamsSchema,
  discrepancyInfoSchema,
  elementSchema,
  geometryParamsSchema,
  steelParamsSchema,
  weldingParamsSchema,
} from '../../config/validation';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import yup from '../../config/yup';
import { createReceiptControlActInitialValues } from './helpers';
import { FormTabs } from '../shared/FormTabs';
import * as math from 'mathjs';
import { ElementDto } from '@tek-crm/backend/dist/application/regulation/dto';
import { memoize } from 'lodash';
import { ParamsDto } from '@tek-crm/backend/dist/application/shared/dto';

const calculateEquivOver12 = ({
  C,
  Mn,
  Cr,
  Mo,
  V,
  Ni,
  Cu,
}: Record<string, number>): number => {
  return math.round(C + Mn / 6 + (Cr + Mo + V) / 5 + (Ni + Cu) / 15, 3);
};

const calculateEquivUnder12 = ({
  C,
  Mn,
  Cu,
  Cr,
  Si,
  Ni,
  Mo,
  V,
  B,
}: Record<string, number>): number => {
  return math.round(
    C + (Mn + Cu + Cr) / 20 + Si / 30 + Ni / 60 + Mo / 15 + V / 10 + 5 * B,
    3,
  );
};

const useCarbonEquivalentCalculator = ({ values, setValue, namePrefix }) => {
  const calcValues = useMemo(
    () => values.filter(({ name }) => name !== 'Cэкв'),
    [JSON.stringify(values)],
  );

  const [CEq, index] = useMemo(() => {
    const found = values.find(({ name }) => name === 'Cэкв');
    const index = values.indexOf(found);

    return [found, index];
  }, [JSON.stringify(calcValues)]);

  const valuesMap = useMemo(() => {
    if (!calcValues) {
      return undefined;
    }

    return calcValues.reduce((acc, { name, value }) => {
      acc[name] =
        typeof value === 'string'
          ? parseFloat(value.toString().replace(',', '.')) || 0
          : value;
      return acc;
    }, {});
  }, [JSON.stringify(calcValues)]);

  useEffect(() => {
    const { C, Mn, Cu, Cr, Si, Ni, Mo, V, B } = valuesMap;

    if (!C || !CEq) {
      return;
    }

    if (C > 0.12) {
      setValue(
        `${namePrefix}[${index}].value`,
        calculateEquivOver12({ C, Mn, Cr, Mo, V, Ni, Cu }),
      );
    } else {
      setValue(
        `${namePrefix}[${index}].value`,
        calculateEquivUnder12({ C, Mn, Cu, Cr, Si, Ni, Mo, V, B }),
      );
    }
  }, [valuesMap]);
};

const SteelGradeFields: FunctionComponent<PrefixProp & { grade?: any }> = ({
  prefix,
  grade,
}) => {
  const { register, watch, setValue } = useFormContext();
  const namePrefix = useMemo(
    () => (prefix && prefix.length > 0 ? `${prefix}` : ''),
    [prefix],
  );
  const values = watch(namePrefix);

  useCarbonEquivalentCalculator({ values, setValue, namePrefix });

  if (!grade) {
    return null;
  }

  return grade.elements.map((element, index) => (
    <Fragment key={element.id}>
      <input
        autoComplete="new-password"
        hidden
        ref={register}
        name={`${namePrefix}[${index}].name`}
        value={element.name}
      />
      <input
        autoComplete="new-password"
        hidden
        ref={register}
        name={`${namePrefix}[${index}].order`}
        value={index}
      />
      <FormControl
        label={element.name}
        name={`${namePrefix}[${index}].value`}
        as={
          <InputNumber
            step={0.01}
            min={0}
            readOnly={element.name === 'Cэкв'}
            disabled={element.name === 'Cэкв'}
            autoComplete="new-password"
            style={{ width: '100%' }}
          />
        }
      />
    </Fragment>
  ));
};

const parseElement = (element: ElementDto): { addon; value; valueOther } => {
  if (!element.value) {
    return {} as any;
  }

  let value, valueOther, addon;
  let valueMatch;
  const addonMatches = element.value.match(/([=<>-]+)/);

  if (addonMatches) {
    addon = addonMatches[0];
  }

  if (addon === '-') {
    valueMatch = element.value.match(/([\d+.?]+)-([\d+.?]+)/);
  } else {
    valueMatch = element.value.match(/[\d+.?]+/);
  }

  if (addon === '-' && valueMatch) {
    [, value, valueOther] = valueMatch;
  } else if (valueMatch) {
    [value] = valueMatch;
  }

  return { addon, value, valueOther };
};

const memoParseElement = memoize(parseElement, element => element.id);
const testElement = function(input) {
  let result;

  const { elements } = this.options.context as any;
  const element = elements.find(element => element.name === input.name);
  const { value, valueOther, addon } = memoParseElement(element);

  if (!value) {
    return true;
  }

  if (!input.value || input.value === '') {
    return this.createError({
      path: `${this.path}.value`,
      message: `Обязательное поле`,
    });
  }

  if (addon === '-') {
    result = math.evaluate(
      `${input.value} >= ${value} & ${valueOther} >= ${input.value}`,
    );
  } else {
    result = math.evaluate(`${input.value} ${addon} ${value}`);
  }

  if (!result) {
    return this.createError({
      path: `${this.path}.value`,
      message: `Введённое значение превышает значение НТД: "${element.value}"`,
    });
  }

  return true;
};

const parseParam = (param: string | undefined) => {
  if (!param) {
    return {};
  }

  let value, valueOther, addon;
  let valueMatch;
  const addonMatches = param.match(/([=<>-]+)/);

  if (addonMatches) {
    addon = addonMatches[0];
  }

  if (addon === '-') {
    valueMatch = param.match(/([\d+.?]+)-([\d+.?]+)/);
  } else {
    valueMatch = param.match(/[\d+.?]+/);
  }

  if (addon === '-' && valueMatch) {
    [, value, valueOther] = valueMatch;
  } else if (valueMatch) {
    [value] = valueMatch;
  }

  return { addon, value, valueOther };
};

const testParam = function(inputValue) {
  let result;

  const { params } = this.options.context as any;

  const paramValue = params[this.type];

  if (!paramValue) {
    return true;
  }

  const { value, valueOther, addon } = parseParam(paramValue);

  if (!inputValue || inputValue === '') {
    return this.createError({
      path: this.path,
      message: `Обязательное поле`,
    });
  }

  if (addon === '-') {
    result = math.evaluate(
      `${inputValue} >= ${value} & ${valueOther} >= ${inputValue}`,
    );
  } else {
    console.log(this, params);
    console.log(`${inputValue} ${addon} ${value}`);
    result = math.evaluate(`${inputValue} ${addon} ${value}`);
  }

  if (!result) {
    return this.createError({
      path: this.path,
      message: `Введённое значение превышает значение НТД: "${paramValue}"`,
    });
  }

  return true;
};

const memoTestElement = memoize(testElement);

const schema = yup.object({
  actNumber: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  date: yup.string().required('Обязательное поле'),
  theoreticalWeight: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  geometryParams: geometryParamsSchema,
  steelParams: createSteelParamsSchema(testParam),
  weldingParams: yup
    .mixed()
    .when('$tubeType', (type, schema) =>
      type === 'welded' ? weldingParamsSchema : schema.notRequired(),
    ),
  discrepancyInfo: discrepancyInfoSchema.notRequired(),
  elements: yup
    .array()
    .of(elementSchema.test('matchNTD', 'Не соответсвует НТД', memoTestElement))
    .min(1, 'Количество элементов должно быть больше нуля'),
});

const useValidationResolver = createYupValidationResolverHook(schema);
const context = { type: 'supply' };

export const ReceiptControlActForm = ({ close, item, row, refresh }) => {
  const resolver = useValidationResolver();
  const initialValues = useMemo(
    () =>
      row.receiptControlAct
        ? {
            ...row.receiptControlAct,
            date: new Date(row.receiptControlAct.date),
          }
        : createReceiptControlActInitialValues(row.items),
    [],
  );

  const [view, setView] = useState('geometryParams');
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: initialValues,
    resolver,
    context: {
      ...context,
      tubeType: row.type,
      elements: row.steelGrade.elements,
      params: row.strengthClass.params,
    },
  });
  console.log(methods.errors);

  const submitForm = useSubmitFormHandler({
    formState: methods.formState,
    handleSubmit: methods.handleSubmit,
    onValid: async data => {
      if (!row.receiptControlAct) {
        await createReceiptControlAct(item.id, {
          ...data,
          workpieces: row.items,
        } as any);
      } else {
        await updateReceiptControlAct(item.id, row.receiptControlAct.id, {
          ...data,
          workpieces: row.items,
        } as any);
      }

      notification.success({
        message: 'Успешно!',
        description: `Сертификат ВКТ был успешно ${
          item ? 'сохранён' : 'обновлён'
        }`,
      });
    },
    closeTab: () => {
      methods.reset();
      close();
      refresh();
    },
  });

  return (
    <FormProvider {...methods}>
      <Form autoComplete="new-password" {...layout} onFinish={submitForm}>
        <Row gutter={24}>
          <Col
            span={9}
            style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}
          >
            <ReceiptControlActMainFields
              close={close}
              supply={item}
              row={row}
            />
          </Col>
          <Col span={15}>
            <FormTabs
              errors={methods.errors}
              activeKey={view}
              onChange={setView}
            >
              <Tabs.TabPane tab="Геометрические параметры" key="geometryParams">
                <GeometryParamsFields type="supply" prefix="geometryParams" />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Основной металл" key="steelParams">
                <SteelParamsFields
                  regulation={row.strengthClass.params}
                  prefix="steelParams"
                />
              </Tabs.TabPane>

              {row.type === 'welded' && (
                <Tabs.TabPane tab="Сварное соединение" key="weldingParams">
                  <WeldingParamsFields
                    regulation={row.strengthClass.params}
                    prefix="weldingParams"
                  />
                </Tabs.TabPane>
              )}

              <Tabs.TabPane tab="Химический состав" key="elements">
                <SteelGradeFields prefix="elements" grade={row.steelGrade} />
              </Tabs.TabPane>
            </FormTabs>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
