import { Layout } from '../components/layout/Layout';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { SupplyPage } from '../pages/supply/SupplyPage';
import { RegulationPage } from '../pages/regulation/RegulationPage';
import { CounterpartyPage } from '../pages/counterparty/CounterpartyPage';
import { ToolsPage } from '../pages/tools/ToolsPage';
import { LoginPage } from '../pages/auth/LoginPage';
import { TechnicalProjectPage } from '../pages/technical-project/TechnicalProjectPage';
import { ProtectedRoute } from './ProtectedRoute';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../redux/root-reducer';
import { UsersPage } from '../pages/users/UsersPage';
import { DashboardPage } from '../pages/dashboard/DashboardPage';
import { logoutAndClearStorage } from '../redux/features/auth';
import { StrengthClassPage } from '../pages/strength-class/StrengthClassPage';
import { RequestPasswordResetPage } from '../pages/auth/RequestPasswordResetPage';
import { ResetPasswordPage } from '../pages/auth/ResetPasswordPage';

const TechnicalProjectRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Redirect exact to={`${path}/list`} from={path} />
        <Route path={`${path}/:key(list)`}>
          <TechnicalProjectPage />
        </Route>
        <Route path={`${path}/:key(acts)`}>
          <TechnicalProjectPage />
        </Route>
        <Route path={`${path}/:key(products)`}>
          <TechnicalProjectPage />
        </Route>
        <Route path={`${path}/:key(add)`}>
          <TechnicalProjectPage />
        </Route>
        <Route path={`${path}/:key(edit)/:id`}>
          <TechnicalProjectPage />
        </Route>
        <Route path={`${path}/:key(documents)/:id`}>
          <TechnicalProjectPage />
        </Route>
        <Route path={`${path}/:key(order)/:id`}>
          <TechnicalProjectPage />
        </Route>
      </Switch>
    </Layout>
  );
};

const SupplyRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Redirect exact to={`${path}/list`} from={path} />
        <Route path={`${path}/:key(list)`}>
          <SupplyPage />
        </Route>
        <Route path={`${path}/:key(leftovers)`}>
          <SupplyPage />
        </Route>
        <Route path={`${path}/:key(acts)`}>
          <SupplyPage />
        </Route>
        <Route path={`${path}/:key(add)`}>
          <SupplyPage />
        </Route>
        <Route path={`${path}/:key(edit)/:id`}>
          <SupplyPage />
        </Route>
        <Route path={`${path}/:key(documents)/:id`}>
          <SupplyPage />
        </Route>
        <Route path={`${path}/:key(workpieces)/:id`}>
          <SupplyPage />
        </Route>
      </Switch>
    </Layout>
  );
};

const RegulationRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Redirect exact to={`${path}/list`} from={path} />
        <Route path={`${path}/:key(list)`}>
          <RegulationPage />
        </Route>
        <Route path={`${path}/:key(add)`}>
          <RegulationPage />
        </Route>
        <Route path={`${path}/:key(edit)/:id`}>
          <RegulationPage />
        </Route>
      </Switch>
    </Layout>
  );
};

const CounterpartyRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Redirect exact to={`${path}/list`} from={path} />
        <Route path={`${path}/:key(list)`}>
          <CounterpartyPage />
        </Route>
        <Route path={`${path}/:key(add)`}>
          <CounterpartyPage />
        </Route>
        <Route path={`${path}/:key(edit)/:id`}>
          <CounterpartyPage />
        </Route>
      </Switch>
    </Layout>
  );
};

const ToolsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Redirect exact to={`${path}/list`} from={path} />
        <Route path={`${path}/:key(list)`}>
          <ToolsPage />
        </Route>
        <Route path={`${path}/:key(add)`}>
          <ToolsPage />
        </Route>
        <Route path={`${path}/:key(edit)/:id`}>
          <ToolsPage />
        </Route>
      </Switch>
    </Layout>
  );
};

const StrengthClassRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Redirect exact to={`${path}/list`} from={path} />
        <Route path={`${path}/:key(list)`}>
          <StrengthClassPage />
        </Route>
        <Route path={`${path}/:key(add)`}>
          <StrengthClassPage />
        </Route>
        <Route path={`${path}/:key(edit)/:id`}>
          <StrengthClassPage />
        </Route>
      </Switch>
    </Layout>
  );
};

const UsersRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Redirect exact to={`${path}/list`} from={path} />
        <Route path={`${path}/:key(list)`}>
          <UsersPage />
        </Route>
        <Route path={`${path}/:key(add)`}>
          <UsersPage />
        </Route>
        <Route path={`${path}/:key(edit)/:id`}>
          <UsersPage />
        </Route>
      </Switch>
    </Layout>
  );
};

export const Routes = () => {
  const auth = useSelector((state: RootStore) => state.auth);
  const dispatch = useDispatch();

  if (auth.authenticated && !auth.user) {
    dispatch(logoutAndClearStorage());
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      {auth.authenticated && <Redirect from="/login" to="/" />}

      <Route path="/login">
        <LoginPage />
      </Route>

      <Route path="/request-password-reset">
        <RequestPasswordResetPage />
      </Route>

      <Route path="/reset-password">
        <ResetPasswordPage />
      </Route>

      <ProtectedRoute exact path="/">
        <Layout>
          <DashboardPage />
        </Layout>
      </ProtectedRoute>

      <ProtectedRoute path="/technical-project">
        <TechnicalProjectRoutes />
      </ProtectedRoute>

      <ProtectedRoute path="/supply">
        <SupplyRoutes />
      </ProtectedRoute>

      <ProtectedRoute path="/regulation">
        <RegulationRoutes />
      </ProtectedRoute>

      <ProtectedRoute path="/counterparty">
        <CounterpartyRoutes />
      </ProtectedRoute>

      <ProtectedRoute path="/tools">
        <ToolsRoutes />
      </ProtectedRoute>

      <ProtectedRoute path="/strength-class">
        <StrengthClassRoutes />
      </ProtectedRoute>

      <ProtectedRoute domain="User" path="/users">
        <UsersRoutes />
      </ProtectedRoute>
    </Switch>
  );
};
