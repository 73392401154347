import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Descriptions, List, Space } from 'antd';
import { MetaWrapper } from '../styled/MetaWrapper';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export interface ChildListProps<T = any> {
  index?: number;
  dataSource: T[];
  children: (item: T) => JSX.Element;
  createItemTitle: (item: T, index: number) => string;
  handleEdit?: (index) => () => void;
  handleRemove?: (index) => () => void;
  renderButtons?: (item, index) => JSX.Element;
}

const ListItem = styled(List.Item as any)`
  && {
    padding: 12px;
    ${({ active }: any) => active && `background: #fafafa;`}
  }
`;

export const ChildList: FunctionComponent<ChildListProps> = ({
  index,
  dataSource,
  children,
  createItemTitle,
  handleEdit,
  handleRemove,
  renderButtons,
}) => {
  const [active, setActive] = useState<number>(index);

  useEffect(() => {
    setActive(index);
  }, [index]);

  if (!dataSource) {
    return null;
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(item, idx) =>
        item && (
          <ListItem active={active === idx}>
            <List.Item.Meta
              description={
                <MetaWrapper>
                  <Descriptions
                    title={createItemTitle(item, idx)}
                    column={{ xxl: 2, xl: 2, lg: 1, md: 1 }}
                    size="small"
                    layout="vertical"
                    extra={
                      renderButtons ? (
                        renderButtons(item, idx)
                      ) : (
                        <Space>
                          {handleEdit && (
                            <Button
                              icon={<EditOutlined />}
                              onClick={() => {
                                handleEdit(idx)();
                                setActive(idx);
                              }}
                            />
                          )}
                          {handleRemove && (
                            <Button
                              danger
                              type="dashed"
                              icon={<DeleteOutlined />}
                              disabled={dataSource.length === 1}
                              onClick={() => {
                                handleRemove(idx)();
                                setActive(active === idx ? active - 1 : active);
                              }}
                            />
                          )}
                        </Space>
                      )
                    }
                  >
                    {item ? children(item) : null}
                  </Descriptions>
                </MetaWrapper>
              }
            />
          </ListItem>
        )
      }
    />
  );
};
