import * as yup from 'yup';
import { NumberSchema } from 'yup';

yup.setLocale({
  mixed: {
    required: 'Обязательное поле',
  },
  string: { max: 'Значение должно быть максимум ${max} символов' },
});

yup.addMethod<NumberSchema>(yup.number, 'toNumOrNull', function () {
  return this.nullable().transform((value: any, originalValue: string) => {
    if (typeof originalValue === 'string' && originalValue.trim() === '') {
      return null;
    }

    if (isNaN(value)) {
      return null;
    }

    return value;
  });
});

export default yup;
