import {
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DatePicker } from '../shared/DatePicker';
import { DiscrepancyInfoFields } from '../shared/DiscrepancyInfoFields';
import { FormControl } from '../form-controls/FormControl';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

export const AcceptanceTestReportMainFields = ({ close, type }) => {
  const { formState } = useFormContext();
  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'manage-psi',
  });

  return (
    <>
      <Divider>Основное</Divider>

      <FormControl
        label="Номер акта"
        name="actNumber"
        as={<Input autoComplete="new-password" />}
      />

      {type === 'BendAcceptanceTestReport' && (
        <>
          <FormControl
            label="Твёрдость HV"
            name="vickersHardness"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Толщина стенки, внутр."
            name="wallThicknessInner"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Толщина стенки, внешн."
            name="wallThicknessOuter"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Радиус торец 1"
            name="radius1end"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Радиус торец 2"
            name="radius2end"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Остаточная магнитная индукция"
            name="residualFluxDensity"
            as={<Input autoComplete="new-password" />}
          />

          <FormControl
            label="Режим гиба"
            name="bendingMode"
            as={
              <Select
                defaultActiveFirstOption
                options={[
                  { label: 'Соответствует', value: 1 },
                  { label: 'Не соответствует', value: 0 },
                ]}
              />
            }
          />

          <FormControl
            label="Тип фаски"
            name="chamferType"
            as={
              <Select
                defaultActiveFirstOption
                options={[
                  { label: 'Соответствует', value: 1 },
                  { label: 'Не соответствует', value: 0 },
                ]}
              />
            }
          />

          <FormControl
            label="Маркировка изделия"
            name="productMarking"
            as={
              <Select
                defaultActiveFirstOption
                options={[
                  { label: 'Соответствует', value: 1 },
                  { label: 'Не соответствует', value: 0 },
                ]}
              />
            }
          />
        </>
      )}

      <FormControl
        label="Дата проведения"
        name="date"
        as={<DatePicker style={{ width: '100%' }} />}
      />

      <Collapse ghost>
        <Collapse.Panel key="discrepancy" header="Данные о не соответствии">
          <DiscrepancyInfoFields prefix="discrepancyInfo" />
        </Collapse.Panel>
      </Collapse>

      <Row justify="end" gutter={[20, 10]}>
        <Col>
          <Space>
            <Button
              /*loading={formState.isSubmitting}*/
              disabled={formState.isSubmitting || !managePermission}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>

            <Button danger type="dashed" onClick={close}>
              Закрыть
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
