import { useSelector } from 'react-redux';
import { RootStore } from '../redux/root-reducer';
import { useMemo } from 'react';
import {
  AccessAttributeDomain,
  DomainPermissions,
} from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

type AccessRuleParams<Domain, Permission> = {
  domain: Domain;
  requestedPermission: Permission;
};

export const useAccessRule = <
  Domain extends AccessAttributeDomain,
  Permission extends DomainPermissions[Domain]
>({
  domain,
  requestedPermission,
}: AccessRuleParams<Domain, Permission>): boolean => {
  const accessAttributes = useSelector(
    (state: RootStore) => state.auth.user.accessAttributes,
  );

  return useMemo(
    () =>
      accessAttributes.some(
        (attribute) =>
          attribute.domain === domain && attribute.name === requestedPermission,
      ),
    [accessAttributes, domain, requestedPermission],
  );
};
