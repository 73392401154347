import React, { FunctionComponent, useMemo } from 'react';
import { Input } from 'antd';
import { PrefixProp } from '../workpiece/WorkpieceForm';
import { createAddon } from '../../utils/create-addon';
import { RegulationDto } from '@tek-crm/backend/dist/application/regulation/dto/regulation.dto';
import { FormControl } from '../form-controls/FormControl';

export const WeldingParamsFields: FunctionComponent<PrefixProp & {
  regulation?: RegulationDto;
}> = ({ prefix, regulation }) => {
  const namePrefix = useMemo(
    () => (prefix && prefix.length > 0 ? `${prefix}` : ''),
    [prefix],
  );

  return (
    <>
      <FormControl
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `flowStress`)}
          />
        }
        name={`${namePrefix}.flowStress`}
        label="Предел текучести, σ0,2"
      />

      <FormControl
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `tensileStrength`)}
          />
        }
        name={`${namePrefix}.tensileStrength`}
        label="Временное сопротивление разрыву, σвр"
      />

      <FormControl
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `relativeExtension`)}
          />
        }
        name={`${namePrefix}.relativeExtension`}
        label="Относительное удлинение"
      />

      <FormControl
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `toughnessKCU`)}
          />
        }
        name={`${namePrefix}.toughnessKCU`}
        label="Ударная вязкость KCU"
      />

      <FormControl
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `toughnessKCV`)}
          />
        }
        name={`${namePrefix}.toughnessKCV`}
        label="Ударная вязкость KCV"
      />

      <FormControl
        as={
          <Input
            autoComplete="new-password"
            addonBefore={createAddon(regulation, `remanence`)}
          />
        }
        name={`${namePrefix}.remanence`}
        label="Остаточная намагниченность"
      />
    </>
  );
};
