import React, { useEffect, useState } from 'react';
import { Button, Card, List } from 'antd';
import { Link } from 'react-router-dom';
import { ItemCount } from './ItemCount';
import { count } from '../../api/supply.api';
import { SupplyStatus } from '@tek-crm/backend/dist/domain/supply/supply.status';

export const SuppliesCard = () => {
  const [countStatusMap, setCountStatusMap] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const data = await count();
        setCountStatusMap(data);
      } catch (e) {}
    })();
  }, []);

  return (
    <Card title="Поставки">
      <List itemLayout="horizontal">
        <List.Item actions={[<Link to="/supply/list">Перейти</Link>]}>
          <List.Item.Meta
            title="Все"
            description="Список всех заведённых поставок в систему"
          />
          <ItemCount count={countStatusMap[SupplyStatus.WIP]} type="danger" />
        </List.Item>
        <List.Item
          actions={[<Link to="/supply/list?status=await-vkt">Перейти</Link>]}
        >
          <List.Item.Meta
            title="Ожидают входного контроля"
            description="Поставки, ожидающие входного контроля"
          />
          <ItemCount
            count={countStatusMap[SupplyStatus.AWAIT_VKT]}
            type="warning"
          />
        </List.Item>
        <List.Item
          actions={[<Link to="/supply/list?status=archive">Перейти</Link>]}
        >
          <List.Item.Meta
            title="Архив"
            description="Завершенные технические задания на производство"
          />
          <ItemCount count={countStatusMap[SupplyStatus.ARCHIVE]} type="info" />
        </List.Item>
      </List>
    </Card>
  );
};
