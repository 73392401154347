import React from 'react';
import { Page } from '../../components/page/Page';
import { TabProps, TabTypes, useTabs } from '../../hooks/use-tabs';
import { SupplyTable } from '../../components/supply/SupplyTable';
import { usePaginationData } from '../../hooks/use-pagination-data';
import { SupplyForm } from '../../components/supply/SupplyForm';
import { API_URL } from '../../api/routes';
import { TabForms } from '../../components/shared/TabForms';
import { format } from 'date-fns';
import { SupplyWorkpiecesTable } from '../../components/supply/SupplyWorkpiecesTable';
import { DocumentsList } from '../../components/document/DocumentsList';
import { DocumentType } from '@tek-crm/backend/dist/domain/document/interfaces/document.type';
import { useLocation } from 'react-router-dom';
import { LeftoversTable } from '../../components/supply/LeftoversTable';
import { ActsTable } from '../../components/supply/ActsTable';

const defaultTabs: TabProps<TabTypes | 'leftovers' | 'acts'>[] = [
  {
    key: 'list',
    type: 'list',
    label: 'Список',
    closable: false,
  },
  {
    key: 'leftovers',
    type: 'leftovers',
    label: 'Остатки',
    closable: false,
  },
  {
    key: 'acts',
    type: 'acts',
    label: 'Акты',
    closable: false,
  },
];

const createLabel = (item, type) => {
  switch (type) {
    case 'add':
      return 'Новая поставка';
    case 'edit':
      return `Поставка от ${format(new Date(item.date), 'dd.MM.yyyy')}`;
    case 'workpieces':
      return `Заготовки в поставке от ${format(
        new Date(item.date),
        'dd.MM.yyyy',
      )}`;
    case 'documents':
      return `Документы в поставке от ${format(
        new Date(item.date),
        'dd.MM.yyyy',
      )}`;
    default:
      return 'Новая вкладка';
  }
};

const documentTypes = {
  [DocumentType.ReceiptControlAct]: 'Скан акта ВКТ',
  [DocumentType.DetailControlAct]: 'Скан акта ВКД',
  [DocumentType.QualityCertificate]: 'Скан сертификата качества',
};

export const SupplyPage = () => {
  const { search } = useLocation();
  const supplyPagination = usePaginationData(`${API_URL}/supply${search}`);
  const leftoversPagination = usePaginationData(`${API_URL}/supply/workpieces`);
  const actsPagination = usePaginationData(`${API_URL}/supply/acts`);

  const { TabList, activeKey, closeTab, openTab } = useTabs({
    defaultTabs,
    createLabel,
    defaultActiveKey: 'list',
    url: `${API_URL}/supply`,
  });

  return (
    <Page
      title="Поставки"
      subTitle="Просмотр, добавление и редактирование поставок"
      TabList={TabList}
    >
      <SupplyTable
        state={supplyPagination.state}
        onTableChange={supplyPagination.onTableChange}
        show={'list' === activeKey}
        openTab={openTab}
        refresh={supplyPagination.refresh}
      />

      <LeftoversTable
        state={leftoversPagination.state}
        onTableChange={leftoversPagination.onTableChange}
        show={'leftovers' === activeKey}
        openTab={openTab}
        refresh={leftoversPagination.refresh}
      />

      <ActsTable
        state={actsPagination.state}
        onTableChange={actsPagination.onTableChange}
        show={'acts' === activeKey}
        openTab={openTab}
        refresh={actsPagination.refresh}
      />

      <TabForms
        type={['add', 'edit']}
        excludeKeys={['list', 'workpieces', 'documents']}
        renderItem={(item, { key }) => (
          <SupplyForm
            closeTab={closeTab}
            key={key}
            tabKey={key}
            item={item}
            show={key === activeKey}
            refresh={supplyPagination.refresh}
          />
        )}
      />

      <TabForms
        type="workpieces"
        excludeKeys={['list', 'add', 'edit', 'documents']}
        url={`${API_URL}/supply`}
        renderItem={(item, tab, forceRender) => (
          <SupplyWorkpiecesTable
            key={tab.key}
            forceRender={forceRender}
            item={item}
            show={tab.key === activeKey}
          />
        )}
      />

      <TabForms
        type="documents"
        excludeKeys={['list', 'add', 'edit', 'workpieces']}
        url={`${API_URL}/supply`}
        renderItem={(item, tab, forceRender) => (
          <DocumentsList
            target="Supply"
            item={item}
            types={documentTypes}
            show={tab.key === activeKey}
            refresh={forceRender}
          />
        )}
      />
    </Page>
  );
};
