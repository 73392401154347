import { WorkpieceDto } from '@tek-crm/backend/dist/application/supply/dto/workpiece.dto';
import { UpdateWorkpieceDto } from '@tek-crm/backend/dist/application/supply/dto/update-workpiece.dto';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Form, notification, Row } from 'antd';
import { SupplyMainFields } from './SupplyMainFields';
import { WorkpieceForm } from '../workpiece/WorkpieceForm';
import { SupplyDto } from '@tek-crm/backend/dist/application/supply/dto/supply.dto';
import { UpdateSupplyDto } from '@tek-crm/backend/dist/application/supply/dto/update-supply.dto';
import { layout } from '../../config/form';
import { FormProvider, useForm } from 'react-hook-form';
import { usePreservedFormState } from '../../hooks/use-preserved-form-state';
import { TabbedFormProps } from '../../interfaces/tabbed-form.props';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import { create, update } from '../../api/supply.api';
import { createEmptyDetail, createEmptyWorkpiece } from './helpers';
import { QualityCertificateDto } from '@tek-crm/backend/dist/application/supply/dto/quality-certificate.dto';
import { detailSchema, workpieceSchema } from '../../config/validation';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import yup from '../../config/yup';
import { PageWrapper } from '../shared/styled';
import { DetailForm } from '../workpiece/DetailForm';
import { DetailDto } from '@tek-crm/backend/dist/application/supply/dto/detail.dto';
import { UpdateDetailDto } from '@tek-crm/backend/dist/application/supply/dto/update-detail.dto';
import { usePrevious } from '../../hooks/use-previous';
import { SupplyType } from '@tek-crm/backend/dist/domain/supply/supply.type';

const workpieceToFormData = (
  workpieces: WorkpieceDto[],
): UpdateWorkpieceDto[] => {
  if (!Array.isArray(workpieces) || !workpieces) {
    return [];
  }

  return Object.values(
    workpieces.reduce<{ [key: string]: UpdateWorkpieceDto }>((acc, value) => {
      const {
        receiptControlAct,
        regulation,
        steelRegulation,
        strengthClass,
        steelGrade,
        lineId,
        type,
        id,
        ...itemParams
      } = value;

      if (!acc[value.lineId]) {
        acc[value.lineId] = {
          id,
          lineId,
          type,
          items: [],
          count: 0,
          serialNumbers: [],
          regulationId: regulation.id,
          steelRegulationId: steelRegulation.id,
          steelGradeId: steelGrade.id,
          strengthClassId: strengthClass.id,
        };
      }

      acc[value.lineId] = {
        ...acc[value.lineId],
        serialNumbers: acc[value.lineId].serialNumbers.concat(
          itemParams.serialNumber,
        ),
        items: acc[value.lineId].items.concat({ id, ...itemParams }),
        count: acc[value.lineId].count + 1,
      };

      return acc;
    }, {}),
  );
};

const detailToFormData = (details: DetailDto[]): UpdateDetailDto[] => {
  if (!Array.isArray(details) || !details) {
    return [];
  }

  return Object.values(
    details.reduce<{ [key: string]: UpdateDetailDto }>((acc, value) => {
      const {
        regulation,
        steelRegulation,
        strengthClass,
        steelGrade,
        lineId,
        id,
        ...itemParams
      } = value;

      if (!acc[value.lineId]) {
        acc[value.lineId] = {
          lineId,
          ...itemParams,
          ids: [],
          count: 0,
          regulationId: (regulation || {}).id,
          steelRegulationId: (steelRegulation || {}).id,
          steelGradeId: (steelGrade || {}).id,
          strengthClassId: (strengthClass || {}).id,
        };
      }

      acc[value.lineId] = {
        ...acc[value.lineId],
        count: acc[value.lineId].count + 1,
        ids: acc[value.lineId].ids.concat(id),
      };

      return acc;
    }, {}),
  );
};

const qualityCertificateToFormData = (
  certificate: QualityCertificateDto,
): any => {
  if (!certificate) {
    return {};
  }

  return { ...certificate, date: new Date(certificate.date) };
};

const supplyToFormData = (supply: SupplyDto): UpdateSupplyDto => {
  return {
    id: supply.id,
    type: supply.type,
    status: supply.status,
    responsible: supply.responsible,
    workpieces: workpieceToFormData(supply.workpieces),
    details: detailToFormData(supply.details),
    supplierId: supply.supplier.id,
    manufacturerId: supply.manufacturer.id,
    qualityCertificate: qualityCertificateToFormData(supply.qualityCertificate),
    date: new Date(supply.date),
  };
};

const schema = yup.object().shape(
  {
    date: yup.string().required('Обязательное поле'),
    supplierId: yup.string().required('Необходимо выбрать поставщика'),
    manufacturerId: yup
      .string()
      .required('Необходимо выбрать завод-изготовитель'),
    workpieces: yup
      .array()
      .of(workpieceSchema)
      .when('details', (val, schema) =>
        Array.isArray(val) && val.reduce((a, v) => a + (v.count ?? 0), 0) > 0
          ? yup.mixed()
          : schema.required(),
      ),
    details: yup
      .array()
      .of(detailSchema)
      .when('workpieces', (val, schema) =>
        Array.isArray(val) && val.reduce((a, v) => a + (v.count ?? 0), 0) > 0
          ? yup.mixed()
          : schema.required(),
      ),
    qualityCertificate: yup.object({
      date: yup.string().required('Обязательное поле'),
      number: yup
        .string()
        .max(128)
        .required('Обязательное поле'),
    }),
  },
  [
    ['details', 'workpieces'],
    ['workpieces', 'details'],
  ],
);

const useValidationResolver = createYupValidationResolverHook(schema);

export const SupplyForm: FunctionComponent<TabbedFormProps> = ({
  show,
  item,
  tabKey,
  closeTab,
  refresh,
}) => {
  const resolver = useValidationResolver();
  const [index, setIndex] = useState(0);
  const initialValues = useMemo(
    () =>
      item
        ? supplyToFormData(item)
        : {
            date: '',
            status: 'work-in-progress',
            type: 'workpieces',
            counterpartyId: '',
            responsible: '',
            workpieces: [createEmptyWorkpiece()],
            details: [createEmptyDetail()],
          },
    [item],
  );

  const methods = useForm<any>({
    defaultValues: initialValues,
    shouldUnregister: false,
    resolver,
  });
  console.log(methods.errors);

  const type = methods.watch('type');
  const previousType = usePrevious(type);

  usePreservedFormState({ show, watch: methods.watch, reset: methods.reset });

  const onValid = useCallback(async data => {
    if (!item) {
      await create({ ...data });
    } else {
      await update(item.id, { ...data });
    }

    notification.success({
      message: 'Успешно!',
      description: `Поставка была успешно ${item ? 'сохранена' : 'обновлена'}`,
    });
  }, []);

  const submitForm = useSubmitFormHandler({
    formState: methods.formState,
    handleSubmit: methods.handleSubmit,
    onValid,
    closeTab: async () => {
      methods.reset();
      await refresh();
      closeTab(tabKey)();
    },
  });

  useEffect(() => {
    if (previousType && type !== previousType) {
      if (type === SupplyType.DETAILS) {
        methods.setValue('workpieces', []);
        methods.setValue('details', [createEmptyDetail()]);
      }

      if (type === SupplyType.WORKPIECES) {
        methods.setValue('workpieces', [createEmptyWorkpiece()]);
        methods.setValue('details', []);
      }
    }
  }, [type]);

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Form autoComplete="new-password" {...layout} onFinish={submitForm}>
          <Row gutter={24}>
            <Col
              span={9}
              style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}
            >
              <SupplyMainFields
                tabKey={tabKey}
                closeTab={closeTab}
                setIndex={setIndex}
              />
            </Col>
            <Col span={15}>
              {type === 'workpieces' && <WorkpieceForm index={index} />}
              {type === 'details' && <DetailForm index={index} />}
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </PageWrapper>
  );
};
