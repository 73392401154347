import { captureException } from '@sentry/browser';

export const extractErrorInfo = (error) => {
  captureException(error);

  if (isAxiosError(error)) {
    if (error.response.status === 502) {
      return {
        message: 'Ошибка',
        description:
          'Сервер находится в процессе обновления и скоро снова станет доступен',
      };
    }

    return {
      message: 'Ошибка:',
      description:
        error.response.data.message ||
        'Что-то пошло не так, повторите попытку позднее',
    };
  }

  if (isConnectionFailure(error)) {
    return {
      message: 'Ошибка подключения',
      description: 'Проверьте ваше подключение к сети',
    };
  }

  return {
    message: 'Произошла непредвиденная ошибка',
    description:
      'Если ошибка возникла снова - свяжитесь с технической поддержкой',
  };
};

const isAxiosError = (error) => {
  return !!error.response && error.isAxiosError;
};

const isConnectionFailure = (error) => {
  return !error.response && error.isAxiosError;
};
