import { createSlice } from '@reduxjs/toolkit';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

export interface MenuItem {
  title: string;
  root?: boolean;
  icon?: JSX.Element;
  page: string;
  roles?: string[];
  items?: MenuItem[];
  domain?: AccessAttributeDomain | boolean;
}

export interface MenuConfigItem {
  items: MenuItem[];
}

export type MenuConfig = Record<'header', MenuConfigItem>;

export interface ConfigStore {
  menu: MenuConfig;
  breadcrumbs: any;
}

const initialState: ConfigStore = {
  menu: undefined,
  breadcrumbs: undefined,
};

const configSlice = createSlice({
  initialState,
  name: 'config',
  reducers: {},
});

export const config = configSlice.reducer;
export const {} = configSlice.actions;
