import React, { FunctionComponent, useMemo } from 'react';
import { Input } from 'antd';
import { PrefixProp } from '../workpiece/WorkpieceForm';
import { RegulationDto } from '@tek-crm/backend/dist/application/regulation/dto/regulation.dto';
import { createAddon } from '../../utils/create-addon';
import { FormControl } from '../form-controls/FormControl';
import { InputGroup } from '../form-controls/InputGroup';

export const ParamsFields: FunctionComponent<PrefixProp & {
  regulation?: RegulationDto;
}> = ({ prefix, regulation }) => {
  const namePrefix = useMemo(
    () => (prefix && prefix.length > 0 ? `${prefix}` : ''),
    [prefix],
  );

  return (
    <>
      <InputGroup
        label="Предел текучести, σ0,2"
        prefix={`${namePrefix}.flowStress`}
      />

      <InputGroup
        label="Временное сопротивление разрыву, σвр"
        prefix={`${namePrefix}.tensileStrength`}
      />

      <InputGroup
        label="Относительное удлинение"
        prefix={`${namePrefix}.relativeExtension`}
      />

      <InputGroup
        label="Ударная вязкость KCU"
        prefix={`${namePrefix}.toughnessKCU`}
      />

      <InputGroup
        label="Ударная вязкость KCV"
        prefix={`${namePrefix}.toughnessKCV`}
      />

      <InputGroup
        label="Предел текучести / Временное сопротивление разрыву (σ0,2 / σвр)"
        prefix={`${namePrefix}.FStoT`}
      />

      <InputGroup
        label="Остаточная намагниченность"
        prefix={`${namePrefix}.remanence`}
      />
    </>
  );
};
