import React, { FunctionComponent } from 'react';
import { CreateSupplyDto } from '@tek-crm/backend/dist/application/supply/dto/create-supply.dto';
import { SearchSelect } from '../form-controls/SearchSelect';
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { FormMainProps } from '../../interfaces/form-main-props.interface';
import { API_URL } from '../../api/routes';
import { useManageChildList } from '../../hooks/use-manage-child-list';
import { CreateWorkpieceDto } from '@tek-crm/backend/dist/application/supply/dto/create-workpiece.dto';
import { useFormContext } from 'react-hook-form';
import { DatePicker } from '../shared/DatePicker';
import { ChildList } from '../shared/ChildList';
import { createEmptyWorkpiece } from './helpers';
import { FormControl } from '../form-controls/FormControl';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { SupplyStatus } from '@tek-crm/backend/dist/domain/supply/supply.status';

export const SupplyMainFields: FunctionComponent<FormMainProps & {
  uploadedCertificate?: any;
}> = ({ tabKey, setIndex, closeTab, uploadedCertificate }) => {
  const { watch, formState } = useFormContext<CreateSupplyDto>();
  const { workpieces, details, type } = watch([
    'workpieces',
    'details',
    'type',
  ]);

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Supply,
    requestedPermission: 'manage',
  });

  const workpiecesManageMethods = useManageChildList<CreateWorkpieceDto>({
    key: 'workpieces',
    setIndex,
    createEmpty: createEmptyWorkpiece,
    createItemTitle: (item, index) => `Труба №${index + 1}`,
  });

  const detailsManageMethods = useManageChildList<CreateWorkpieceDto>({
    key: 'details',
    setIndex,
    createEmpty: createEmptyWorkpiece,
    createItemTitle: (item, index) => `Деталь №${index + 1}`,
  });

  return (
    <>
      <Divider>Информация о поставке</Divider>

      <FormControl
        label="Статус"
        name="status"
        as={
          <Select
            options={[
              { label: 'В работе', value: SupplyStatus.WIP },
              {
                label: 'Ожидает ВКТ',
                value: SupplyStatus.AWAIT_VKT,
              },
              { label: 'В архиве', value: SupplyStatus.ARCHIVE },
            ]}
          />
        }
      />

      <FormControl
        label="Тип"
        name="type"
        as={
          <Select
            options={[
              { value: 'workpieces', label: 'Поставка труб' },
              { value: 'details', label: 'Поставка деталей' },
            ]}
          />
        }
      />

      <FormControl
        label="Дата поступления"
        name="date"
        as={<DatePicker style={{ width: '100%' }} />}
      />

      <FormControl
        label="Поставщик"
        name="supplierId"
        as={
          <SearchSelect
            url={`${API_URL}/counterparty/find-by-query?type[]=supplier&type[]=manufacturer`}
          />
        }
      />

      <FormControl
        label="Завод-изготовитель"
        name="manufacturerId"
        as={
          <SearchSelect
            url={`${API_URL}/counterparty/find-by-query?type=manufacturer`}
          />
        }
      />

      <FormControl
        label="Ответственный"
        name="responsible"
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Номер сертификата качества"
        name="qualityCertificate.number"
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Дата сертификата качества"
        name="qualityCertificate.date"
        as={<DatePicker style={{ width: '100%' }} />}
      />

      <Row justify="end" gutter={[20, 10]}>
        {type === 'workpieces' && (
          <Col flex="1 0">
            <Button
              onClick={workpiecesManageMethods.handleAdd}
              style={{ marginRight: 'auto' }}
            >
              Добавить трубу
            </Button>
          </Col>
        )}

        {type === 'details' && (
          <Col flex="1 0">
            <Button
              onClick={detailsManageMethods.handleAdd}
              style={{ marginRight: 'auto' }}
            >
              Добавить деталь
            </Button>
          </Col>
        )}

        <Col>
          <Space>
            <Button
              /*loading={formState.isSubmitting}*/
              disabled={formState.isSubmitting || !managePermission}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>
            {closeTab && (
              <Button danger type="dashed" onClick={closeTab(tabKey)}>
                Закрыть
              </Button>
            )}
          </Space>
        </Col>
      </Row>

      {type === 'workpieces' && (
        <ChildList
          index={workpiecesManageMethods.index}
          dataSource={workpieces}
          handleEdit={workpiecesManageMethods.handleEdit}
          handleRemove={workpiecesManageMethods.handleRemove}
          createItemTitle={workpiecesManageMethods.itemTitleFactory}
        >
          {item => (
            <>
              <Descriptions.Item label={<b>Номер плавки</b>}>
                {item.items &&
                  Array.from(
                    new Set(
                      item.items.map(({ meltingNumber }) => meltingNumber),
                    ),
                  ).join(', ')}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Количество, шт</b>}>
                {item.count}
              </Descriptions.Item>
            </>
          )}
        </ChildList>
      )}

      {type === 'details' && (
        <ChildList
          index={detailsManageMethods.index}
          dataSource={details}
          handleEdit={detailsManageMethods.handleEdit}
          handleRemove={detailsManageMethods.handleRemove}
          createItemTitle={detailsManageMethods.itemTitleFactory}
        >
          {item => (
            <>
              <Descriptions.Item label={<b>Название</b>}>
                {item.name}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Количество, шт</b>}>
                {item.count}
              </Descriptions.Item>
            </>
          )}
        </ChildList>
      )}
    </>
  );
};
