import { useModal } from '../../hooks/use-modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input, InputNumber, Modal, Space, Table } from 'antd';
import { WorkpiecesList } from '../workpiece/WorkpieceList';
import { useFormContext } from 'react-hook-form';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { OrderLineType } from '@tek-crm/backend/dist/domain/order/interfaces/types';
import { DetailList } from '../workpiece/DetailList';

const useOrderItemTableColumns = ({
  openWorkpieceModal,
  openDetailsModal,
  name,
  type,
  lineNumber,
  techProjectNumber,
  index,
  selectOrderItem,
  handleDeleteItem,
}) => {
  return useMemo(() => {
    const detailColumn = [];

    if (type === OrderLineType.WELDING) {
      detailColumn.push({
        title: 'Детали',
        dataIndex: 'detailIds',
        render(value, record) {
          return !Array.isArray(value) || value.length === 0 ? (
            <Button
              type="link"
              onClick={e => {
                e.stopPropagation();
                selectOrderItem([record.id]);
                openDetailsModal();
              }}
            >
              Не указаны
            </Button>
          ) : (
            'Указаны'
          );
        },
      });
    }

    return [
      {
        title: 'Название',
        dataIndex: 'name',
        render: () => name,
      },
      {
        title: 'Заводской номер',
        dataIndex: 'serialNumber',
        render: value =>
          `${techProjectNumber
            .toString()
            .padStart(3, '0')}-${lineNumber
            .toString()
            .padStart(3, '0')}-${value.toString().padStart(3, '0')}`,
      },
      {
        title: 'Акт ПСИ',
        dataIndex: 'acceptanceTestReport',
        render: value => (value ? value.actNumber : 'Не создан'),
      },
      {
        title: 'Труба',
        dataIndex: 'workpieceId',
        render(value, record) {
          return !value ? (
            <Button
              type="link"
              onClick={e => {
                e.stopPropagation();
                selectOrderItem([record.id]);
                openWorkpieceModal();
              }}
            >
              Не указана
            </Button>
          ) : (
            'Указана'
          );
        },
      },
      ...detailColumn,
      {
        title: 'Действия',
        dataIndex: 'action',
        render: (_, record) => (
          <Button
            danger
            type="dashed"
            icon={<DeleteOutlined />}
            onClick={e => {
              e.stopPropagation();
              handleDeleteItem(record.id);
            }}
          />
        ),
      },
    ];
  }, [index, type, name, lineNumber, techProjectNumber, handleDeleteItem]);
};

const nextSerialNumber = (dataSource: { serialNumber: number }[], idx) =>
  (dataSource.length
    ? Math.max(...dataSource.map(({ serialNumber }) => serialNumber))
    : 0) +
  idx +
  1;

const useManageItemsMethods = ({
  namePrefix,
  dataSource,
  itemsCount,
  setItemsCount,
}) => {
  const { setValue, watch } = useFormContext();
  const type = watch(`${namePrefix}.type`);
  const count = watch(`${namePrefix}.params.count`);

  const handleDeleteItem = useCallback(
    id => {
      const newDataSource = dataSource.filter(item => item.id !== id);
      setValue(`${namePrefix}.items`, newDataSource);
      setValue(`${namePrefix}.params.count`, newDataSource.length);
    },
    [dataSource],
  );

  const handleAddItems = useCallback(() => {
    const newDataSource = [];
    newDataSource.push(...dataSource);
    newDataSource.push(
      ...Array(itemsCount)
        .fill(undefined)
        .map((_, idx) => ({
          id: Math.random()
            .toString(36)
            .substring(2, 15),
          type,
          serialNumber: nextSerialNumber(dataSource, idx),
          workpieceId: undefined,
        })),
    );
    setValue(`${namePrefix}.items`, newDataSource);
    setValue(`${namePrefix}.params.count`, newDataSource.length);
    setItemsCount(0);
  }, [itemsCount, type, namePrefix]);

  useEffect(() => {
    const newDataSource = [];

    if (!dataSource || count === dataSource.length) {
      return;
    }

    if (count > dataSource.length) {
      newDataSource.push(...dataSource);
      newDataSource.push(
        ...Array(count - dataSource.length)
          .fill(undefined)
          .map((_, idx) => ({
            id: Math.random()
              .toString(36)
              .substring(2, 15),
            type,
            serialNumber: nextSerialNumber(dataSource, idx),
            workpieceId: undefined,
          })),
      );
    }

    if (count < dataSource.length) {
      newDataSource.push(...dataSource.slice(0, count));
    }

    setValue(`${namePrefix}.items`, newDataSource);
    setValue(`${namePrefix}.params.count`, newDataSource.length);
  }, [count]);

  return useMemo(() => [handleAddItems, handleDeleteItem], [
    handleAddItems,
    handleDeleteItem,
  ]);
};

export const OrderItemTable = ({ index, namePrefix, itemLength }) => {
  const workpieceModalMethods = useModal();
  const detailsModalMethods = useModal();
  const { getValues, setValue, watch } = useFormContext();
  const [selectedOrderItems, handleSelectedOrderItemsChange] = useState([]);
  const [selectedWorkpiece, handleSelectedWorkpiece] = useState([]);
  const [selectedDetails, handleSelectedDetails] = useState([]);
  const dataSource = watch(`${namePrefix}.items`);
  const name = watch(`${namePrefix}.name`);
  const type = watch(`${namePrefix}.type`);
  const order = watch(`order`);
  const lineNumber = watch(`${namePrefix}.lineNumber`);
  const techProjectNumber = watch(`number`);
  const detailIds = useMemo(
    () =>
      order
        .map(orderItem => orderItem.items.map(item => item.detailIds))
        .flat(2),
    [JSON.stringify(order)],
  );
  const [itemsCount, setItemsCount] = useState(0);

  const [handleAddItems, handleDeleteItem] = useManageItemsMethods({
    namePrefix,
    dataSource,
    itemsCount,
    setItemsCount,
  });

  const handleAddWorkpiece = useCallback(() => {
    for (const orderItemId of selectedOrderItems) {
      const index = dataSource.findIndex(({ id }) => id === orderItemId);
      const path = `${namePrefix}.items[${index}].workpieceId`;

      setValue(path, selectedWorkpiece[0] || undefined);
    }

    workpieceModalMethods.close();
  }, [selectedWorkpiece, selectedOrderItems, dataSource]);

  const handleAddDetails = useCallback(() => {
    for (const orderItemId of selectedOrderItems) {
      const index = dataSource.findIndex(({ id }) => id === orderItemId);
      const path = `${namePrefix}.items[${index}].detailIds`;
      const details = getValues(path);

      setValue(path, (details || []).concat(...selectedDetails));
    }

    detailsModalMethods.close();
  }, [selectedWorkpiece, selectedOrderItems, dataSource]);

  const handleOnRow = useCallback(
    record => ({
      onClick: () =>
        handleSelectedOrderItemsChange(prev => {
          if (record.workpieceId) {
            return prev;
          }

          if (prev.includes(record.id)) {
            return prev.filter(id => id !== record.id);
          }

          return prev.concat(record.id);
        }),
    }),
    [],
  );

  const onAfterClose = useCallback(() => {
    handleSelectedDetails([]);
    handleSelectedWorkpiece([]);
    handleSelectedOrderItemsChange([]);
  }, []);

  const columns = useOrderItemTableColumns({
    openWorkpieceModal: workpieceModalMethods.open,
    openDetailsModal: detailsModalMethods.open,
    name,
    type,
    lineNumber,
    techProjectNumber,
    index,
    selectOrderItem: handleSelectedOrderItemsChange,
    handleDeleteItem,
  });

  return (
    <>
      <Space style={{ marginBottom: 20 }}>
        <Input.Group compact>
          <InputNumber
            min={0}
            value={itemsCount}
            onChange={(count: number) => {
              setItemsCount(count);
            }}
          />
          <Button
            type="primary"
            disabled={itemsCount === 0}
            icon={<PlusOutlined />}
            onClick={handleAddItems}
          >
            Добавить
          </Button>
        </Input.Group>

        <Button
          disabled={selectedOrderItems.length === 0}
          onClick={() => workpieceModalMethods.open()}
        >
          Выбрать трубу для {selectedOrderItems.length} изделий
        </Button>
      </Space>

      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey={record => record.id}
        dataSource={dataSource}
        onRow={handleOnRow}
        rowSelection={{
          preserveSelectedRowKeys: true,
          onChange: handleSelectedOrderItemsChange,
          selectedRowKeys: selectedOrderItems,
          getCheckboxProps: record => ({
            disabled: record.workpieceId,
          }),
        }}
      />

      <Modal
        maskClosable
        destroyOnClose
        width="80%"
        visible={workpieceModalMethods.visible}
        footer={null}
        onCancel={workpieceModalMethods.close}
        afterClose={onAfterClose}
      >
        <WorkpiecesList
          itemLength={itemLength}
          selectedOrderItems={selectedOrderItems}
          selectedRowKeys={selectedWorkpiece}
          handleChange={handleSelectedWorkpiece}
        />
        <Button onClick={handleAddWorkpiece}>Сохранить</Button>
      </Modal>

      <Modal
        maskClosable
        destroyOnClose
        width="70%"
        visible={detailsModalMethods.visible}
        footer={null}
        onCancel={detailsModalMethods.close}
        afterClose={onAfterClose}
      >
        <DetailList
          excludeIds={detailIds}
          selectedRowKeys={selectedDetails}
          handleChange={handleSelectedDetails}
        />
        <Button onClick={handleAddDetails}>Сохранить</Button>
      </Modal>
    </>
  );
};
