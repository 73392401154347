import { API_URL } from './routes';
import axios from 'axios';
import { CreateUserDto } from '@tek-crm/backend/dist/application/user/dto/create-user.dto';
import { UpdateUserDto } from '@tek-crm/backend/dist/application/user/dto/update-user.dto';

export const create = async (formData: CreateUserDto) => {
  const { data } = await axios.post(`${API_URL}/user/create`, formData);
  return data;
};

export const update = async (id, formData: UpdateUserDto) => {
  const { data } = await axios.post(`${API_URL}/user/${id}`, formData);
  return data;
};

export const remove = async (id: string) => {
  const { data } = await axios.delete(`${API_URL}/user/${id}`);
  return data;
};
