import React, {
  FunctionComponent,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Divider, Form, notification, Row } from 'antd';
import { layout } from '../../config/form';
import { TechnicalProjectMainFields } from './TechnicalProjectMainFields';
import { BendOrderLineFields } from './BendOrderLineFields';
import { WeldingOrderLineFields } from './WeldingOrderLineFields';
import { TabbedFormProps } from '../../interfaces/tabbed-form.props';
import { FormProvider, useForm } from 'react-hook-form';
import {
  createAdditionalInfoLine,
  createOrderLine,
  createTechnicalProject,
  technicalProjectToFormData,
} from './helpers';
import { usePrevious } from '../../hooks/use-previous';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import { create, update } from '../../api/technical-project.api';
import yup from '../../config/yup';
import {
  additionalInfoLineSchema,
  pipelineBendSchema,
  pipelineWeldingSchema,
} from '../../config/validation';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import { PageWrapper } from '../shared/styled';

const schema = yup.object({
  date: yup.string().required(),
  number: yup
    .string()
    .max(128)
    .required(),
  type: yup.string().required('Необходимо указать тип технического задания'),
  basis: yup
    .string()
    .max(128)
    .required(),
  notes: yup.string(),
  counterpartyId: yup.string().required('Необходимо указать контрагента'),
  orderItems: yup
    .array()
    .when('type', (type, schema) =>
      type === 'BendTechnicalProject'
        ? schema.of(pipelineBendSchema)
        : schema.of(pipelineWeldingSchema),
    )
    .min(1, 'Необходимо заполнить хотя-бы один продукт')
    .required(),
  additionalInfo: yup
    .array()
    .of(additionalInfoLineSchema)
    .notRequired(),
});

const useValidationResolver: any = () => {}; //createYupValidationResolverHook(schema);

export const TechnicalProjectForm: FunctionComponent<TabbedFormProps> = memo(
  ({ show, item, closeTab, tabKey, refresh }) => {
    const resolver = useValidationResolver();
    const [view, setView] = useState('order');
    const initialValues = useMemo(
      () =>
        item
          ? {
              ...technicalProjectToFormData(item),
              date: new Date(item.date),
            }
          : createTechnicalProject('bend'),
      [item],
    );

    const [index, setIndex] = useState(0);
    const methods = useForm<any>({
      defaultValues: initialValues,
      shouldUnregister: false,
      resolver,
    });
    const type = methods.watch('type');
    const previousType = usePrevious(type);
    console.log(methods.errors);

    const submitForm = useSubmitFormHandler({
      formState: methods.formState,
      handleSubmit: methods.handleSubmit,
      onValid: async data => {
        if (!item) {
          await create(data);
        } else {
          await update(item.id, data);
        }

        notification.success({
          message: 'Успешно!',
          description: `Тех. задание было успешно ${
            item ? 'сохранено' : 'обновлено'
          }`,
        });
      },
      closeTab: async () => {
        methods.reset();
        await refresh();
        closeTab(tabKey)();
      },
    });

    useEffect(() => {
      if (previousType && previousType !== type) {
        methods.setValue('order', [
          createOrderLine(
            type === 'BendTechnicalProject' ? 'bend' : 'welding',
            1,
          ),
        ]);
        methods.setValue('additionalInfo', [
          type === 'BendTechnicalProject'
            ? undefined
            : [createAdditionalInfoLine()],
        ]);
        setIndex(0);
        setView('order');
      }
    }, [type, previousType]);

    if (!show) {
      return null;
    }

    return (
      <PageWrapper>
        <FormProvider {...methods}>
          <Form autoComplete="new-password" {...layout} onFinish={submitForm}>
            <Row gutter={24}>
              <Col
                span={9}
                style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}
              >
                <TechnicalProjectMainFields
                  setIndex={setIndex}
                  view={view}
                  setView={setView}
                  closeTab={closeTab}
                  tabKey={tabKey}
                />
              </Col>
              <Col span={15}>
                {view === 'order' && (
                  <>
                    {type === 'BendTechnicalProject' && (
                      <BendOrderLineFields index={index} />
                    )}

                    {type === 'WeldingTechnicalProject' && (
                      <WeldingOrderLineFields index={index} />
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </PageWrapper>
    );
  },
);
