import {
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Input,
  List,
  Menu,
  notification,
  Select,
} from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RangePicker } from '../shared/DatePicker';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControl } from '../form-controls/FormControl';
import Fuse from 'fuse.js';
import { intersectionBy } from 'lodash';
import { isWithinInterval } from 'date-fns';
import { UploadOutlined } from '@ant-design/icons';
import { TabbedFormProps } from '../shared/TabForms';
import { API_URL } from '../../api/routes';
import { DocumentDto } from '@tek-crm/backend/dist/application/document/dto/document.dto';
import { UploadButton } from './UploadButton';
import { ImageItem } from './ImageItem';
import { extractErrorInfo } from '../../utils/extractError';
import { remove, restore } from '../../api/document.api';
import { DocumentType } from '@tek-crm/backend/dist/domain/document/interfaces/document.type';

const options = {
  includeScore: true,
  threshold: 0.2,
  keys: ['name'],
};

export interface DocumentsListProps extends TabbedFormProps {
  types: { [key in DocumentType]?: string };
  target: string;
}

const useListFilter = (initial: any[], watch) => {
  const initialData = useMemo(() => initial, [JSON.stringify(initial)]);
  const [filtered, setFiltered] = useState(initial);
  const { name, type, dateRange } = watch();

  useEffect(() => {
    const results = [];

    if (name && name !== '') {
      const fuse = new Fuse(initialData, options);
      results.push(fuse.search(name).map(({ item }) => item));
    }

    if (type) {
      results.push(initialData.filter(item => item.type === type));
    }

    if (Array.isArray(dateRange) && dateRange.length) {
      const [start, end] = dateRange;
      results.push(
        initialData.filter(({ createdAt }) =>
          isWithinInterval(new Date(createdAt), { start, end }),
        ),
      );
    }

    if (results.length) {
      setFiltered(intersectionBy(...results, 'id'));
    } else {
      setFiltered(initialData);
    }
  }, [initialData, name, type, dateRange]);

  return filtered;
};

const useListActions = (initial: any[], setInitial) => {
  const handleDelete = useCallback(
    async item => {
      const found = initial.indexOf(item);

      if (!~found) {
        notification.error({
          message: 'Ошибка!',
          description:
            'Невозможно удалить документ, не находящийся в списке документов',
        });
      }

      try {
        await remove(item.id);

        setInitial(prev =>
          Object.assign([], prev, {
            [found]: { ...item, deleted: true },
          }),
        );

        notification.success({
          message: 'Успешно!',
          description: 'Документ удалён',
        });
      } catch (e) {
        notification.error(extractErrorInfo(e));
      }
    },
    [initial],
  );

  const handleRestore = useCallback(
    async item => {
      const found = initial.indexOf(item);

      if (!~found) {
        notification.error({
          message: 'Ошибка!',
          description:
            'Невозможно восстановить документ, не находящийся в списке документов',
        });
      }

      try {
        await restore(item.id);

        setInitial(prev =>
          Object.assign([], prev, {
            [found]: { ...item, deleted: false },
          }),
        );

        notification.success({
          message: 'Успешно!',
          description: 'Документ восстановлен',
        });
      } catch (e) {
        notification.error(extractErrorInfo(e));
      }
    },
    [initial],
  );

  const handleSuccessfulUpload = useCallback((data: DocumentDto[]) => {
    setInitial(prev => [...data, ...prev]);
  }, []);

  return useMemo(
    () => ({
      handleSuccessfulUpload,
      handleRestore,
      handleDelete,
    }),
    [handleDelete, handleRestore, handleSuccessfulUpload],
  );
};

export const DocumentsList: FunctionComponent<DocumentsListProps> = ({
  item,
  types,
  target,
  show,
}) => {
  const methods = useForm({
    defaultValues: {
      name: '',
      type: '',
      dateRange: [],
    },
  });

  const [loading, setLoading] = useState(false);
  const [initial, setInitial] = useState(item.documents || []);
  const dataSource = useListFilter(initial, methods.watch);

  const {
    handleDelete,
    handleRestore,
    handleSuccessfulUpload,
  } = useListActions(initial, setInitial);

  const options = useMemo(
    () => [
      { label: 'Все', value: '' },
      ...Object.entries(types).map(([type, text]) => ({
        value: type,
        label: text,
      })),
    ],
    [types],
  );

  if (!show) {
    return null;
  }

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              {Object.entries(types).map(([type, text]) => (
                <Menu.Item key={type}>
                  <UploadButton
                    loading={loading}
                    setLoading={setLoading}
                    target={target}
                    action={`${API_URL}/document/${item.id}/upload`}
                    documentType={type as DocumentType}
                    onSuccessfulUpload={handleSuccessfulUpload}
                  >
                    {text}
                  </UploadButton>
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button block type="primary">
            Загрузить <UploadOutlined />
          </Button>
        </Dropdown>

        <Divider />

        <FormProvider {...methods}>
          <Form autoComplete="new-password" layout="inline">
            <FormControl
              label="Название"
              name="name"
              as={
                <Input
                  autoComplete="new-password"
                  placeholder="Начните вводить название"
                />
              }
            />

            <FormControl
              label="Тип"
              name="type"
              as={
                <Select
                  allowClear
                  defaultValue={null}
                  placeholder="Все"
                  options={options}
                  style={{ width: 200 }}
                />
              }
            />

            <FormControl label="Дата" name="dateRange" as={<RangePicker />} />

            <Button
              danger
              type="dashed"
              onClick={() =>
                methods.reset({
                  name: '',
                  type: '',
                  dateRange: [],
                })
              }
            >
              Очистить
            </Button>
          </Form>
        </FormProvider>
      </Card>

      <List
        grid={{ gutter: 16, column: 6 }}
        itemLayout="horizontal"
        loading={loading}
        pagination={{
          pageSize: 12,
        }}
        dataSource={dataSource}
        renderItem={item => (
          <ImageItem
            item={item}
            onDelete={handleDelete}
            onRestore={handleRestore}
          />
        )}
      />
    </>
  );
};
