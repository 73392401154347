import yup from './yup';

export const elementSchema = yup.object({
  name: yup
    .string()
    .max(128)
    .nullable()
    .notRequired(),
  value: yup
    .number()
    .toNumOrNull()
    .nullable()
    .notRequired(),
});

export const steelParamsSchema = yup.object({
  flowStress: yup
    .number()
    .toNumOrNull()
    .required('Обязательное поле'),
  tensileStrength: yup
    .number()
    .toNumOrNull()
    .required('Обязательное поле'),
  relativeExtension: yup
    .number()
    .toNumOrNull()
    .required('Обязательное поле'),
  toughnessKCU: yup
    .number()
    .toNumOrNull()
    .required('Обязательное поле'),
  toughnessKCV: yup
    .number()
    .toNumOrNull()
    .required('Обязательное поле'),
  FStoT: yup
    .number()
    .toNumOrNull()
    .required('Обязательное поле'),
});

export const createSteelParamsSchema = testFn => {
  return yup.object({
    flowStress: yup
      .number()
      .toNumOrNull()
      .required('Обязательное поле')
      .test(
        'flowStress',
        'Не соответствует выбранному классу прочности',
        testFn,
      ),
    tensileStrength: yup
      .number()
      .toNumOrNull()
      .required('Обязательное поле')
      .test(
        'tensileStrength',
        'Не соответствует выбранному классу прочности',
        testFn,
      ),
    relativeExtension: yup
      .number()
      .toNumOrNull()
      .required('Обязательное поле')
      .test(
        'relativeExtension',
        'Не соответствует выбранному классу прочности',
        testFn,
      ),
    toughnessKCU: yup
      .number()
      .toNumOrNull()
      .required('Обязательное поле')
      .test(
        'toughnessKCU',
        'Не соответствует выбранному классу прочности',
        testFn,
      ),
    toughnessKCV: yup
      .number()
      .toNumOrNull()
      .required('Обязательное поле')
      .test(
        'toughnessKCV',
        'Не соответствует выбранному классу прочности',
        testFn,
      ),
    FStoT: yup
      .number()
      .toNumOrNull()
      .required('Обязательное поле')
      .test('FStoT', 'Не соответствует выбранному классу прочности', testFn),
  });
};

export const weldingParamsSchema = yup.object({
  flowStress: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  tensileStrength: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  relativeExtension: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  toughnessKCU: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  toughnessKCV: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  remanence: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
});

const comparator = (reverse?) => (type, schema) =>
  (type === 'product') === !reverse
    ? schema.required('Обязательное поле')
    : schema.nullable().notRequired();

export const geometryParamsSchema = yup.object({
  maxDiameter: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  minDiameter: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  ovality1end: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  ovality2end: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  nominalDiameter: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  bendingAngle: yup
    .string()
    .max(128)
    .when('$type', comparator()),
  bendingRadius: yup
    .string()
    .max(128)
    .when('$type', comparator()),
  corrugationSize: yup
    .string()
    .max(128)
    .when('$type', comparator()),
  obliqueCut: yup
    .string()
    .max(128)
    .when('$type', comparator()),
  ovalityBend: yup
    .string()
    .max(128)
    .when('$type', comparator()),
  faceToFaceLengthA: yup
    .string()
    .max(128)
    .when('$type', comparator()),
  faceToFaceLengthB: yup
    .string()
    .max(128)
    .when('$type', comparator()),
  straightSectionA: yup
    .string()
    .max(128)
    .when('$type', comparator()),
  straightSectionB: yup
    .string()
    .max(128)
    .when('$type', comparator()),
});

export const steelGradeSchema = yup.object({
  name: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  elements: yup
    .array()
    .min(1)
    .of(elementSchema),
});

const workpieceItemSchema = yup.object({
  serialNumber: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  hydraulicPressure: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  wallThickness: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  nominalDiameter: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  length: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  meltingNumber: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
});

export const workpieceSchema = yup.object({
  count: yup
    .number()
    .toNumOrNull()
    .min(1, 'Количество заготовок должно быть больше нуля')
    .required('Обязательное поле'),
  serialNumbers: (yup as any)
    .array()
    .of(yup.string().max(128))
    .length(
      yup.ref('count'),
      'Количество серийных номеров должно быть равно количеству заготовок',
    )
    .min(1, 'Количество серийных номеров должно быть больше нуля')
    .required('Обязательное поле'),
  regulationId: yup.string().required('Обязательное поле'),
  type: yup.string().required('Необходимо выбрать тип заготовки'),
  steelRegulationId: yup.string().required('Обязательное поле'),
  steelGradeId: yup.string().required('Обязательное поле'),
  strengthClassId: yup.string().required('Обязательное поле'),
  items: yup
    .array()
    .of(workpieceItemSchema)
    .min(1, 'Количество серийных номеров должно быть больше нуля'),
});

export const detailSchema = yup.object({
  count: yup
    .number()
    .toNumOrNull()
    .min(1, 'Количество деталей должно быть больше нуля')
    .required('Обязательное поле'),
  regulationId: yup.string(),
  steelRegulationId: yup.string(),
  steelGradeId: yup.string(),
  strengthClassId: yup.string(),
});

export const discrepancyInfoSchema = yup.object({
  reason: yup
    .string()
    .max(128)
    .nullable()
    .notRequired(),
  causer: yup
    .string()
    .max(128)
    .nullable()
    .notRequired(),
  correction: yup
    .string()
    .max(128)
    .nullable()
    .notRequired(),
  correctiveActions: yup
    .string()
    .max(128)
    .nullable()
    .notRequired(),
  correctionMark: yup
    .string()
    .max(128)
    .nullable()
    .notRequired(),
  repeatedQAMark: yup
    .string()
    .max(128)
    .nullable()
    .notRequired(),
  correctiveActionsScore: yup
    .string()
    .max(128)
    .nullable()
    .notRequired(),
});

export const pipelineBendSchema = yup.object({
  count: yup
    .number()
    .toNumOrNull()
    .min(1, 'Значение не может быть меньше 1')
    .required(),
  regulationId: yup.string().required('Необходимо указать регламент'),
  name: yup
    .string()
    .max(128)
    .required(),
  roughSizeDB: yup
    .number()
    .toNumOrNull()
    .required(),
  roughSizeSH: yup
    .number()
    .toNumOrNull()
    .required(),
  finishSizeDB: yup
    .number()
    .toNumOrNull()
    .required(),
  finishSizeSH: yup
    .number()
    .toNumOrNull()
    .required(),
  straightSectionA: yup
    .number()
    .toNumOrNull()
    .required(),
  bendingAngle: yup
    .number()
    .toNumOrNull()
    .required(),
  bendingRadius: yup
    .number()
    .toNumOrNull()
    .required(),
  nominalDiameter: yup
    .number()
    .toNumOrNull()
    .required(),
  allowance: yup
    .number()
    .toNumOrNull()
    .required(),
});

export const pipelineWeldingSchema = yup.object({
  count: yup
    .number()
    .toNumOrNull()
    .min(1, 'Значение не может быть меньше нуля')
    .required(),
  regulationId: yup.string().required('Необходимо указать регламент'),
  name: yup
    .string()
    .max(128)
    .required(),
  jointsCount: yup
    .number()
    .toNumOrNull()
    .required(),
  arrivalOfParts: yup.string().required(),
  workDescription: yup.string().required(),
});

export const additionalInfoLineSchema = yup.object({
  name: yup
    .string()
    .max(128)
    .required(),
  availability: yup
    .string()
    .max(128)
    .required(),
  length: yup
    .number()
    .toNumOrNull()
    .required(),
  branchPipeCount: yup
    .number()
    .toNumOrNull()
    .required(),
  notes: yup.string().required(),
});

export const dpiQaInfoSchema = yup.object({
  regulationId: yup.string().required('Необходимо выбрать регламент'),
  purifierId: yup.string().required('Необходимо выбрать очиститель'),
  penetrantId: yup.string().required('Необходимо выбрать пенетрант'),
  developerId: yup.string().required('Необходимо выбрать проявитель'),
  sampleId: yup.string().required('Необходимо выбрать образец'),
  compliance: yup.string().required(),
});

export const ultrasonicQaInfoSchema = yup.object({
  regulationId: yup.string().required('Необходимо выбрать регламент'),
  deviceId: yup.string().required('Необходимо выбрать прибор'),
  sampleId: yup.string().required('Необходимо выбрать СОП'),
  transducerId: yup.string(),
  defectArea: yup.string(),
  compliance: yup.string().required(),
});

export const qaResultSchema = yup.object({
  batchDetailNumber: yup.string().required(),
  jointNumber: yup.string().required(),
  welderName: yup.string().required(),
  standardSize: yup.string().required(),
  weldDate: yup.string().required(),
  weldType: yup.string().required(),
  defects: yup.string().required(),
  qualityControl: yup.string().required(),
});
