import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Divider, Input, InputNumber, Select, Tabs } from 'antd';
import { SearchSelect } from '../form-controls/SearchSelect';
import { API_URL } from '../../api/routes';
import { useFormContext } from 'react-hook-form';
import { FormControl } from '../form-controls/FormControl';
import { FormTabs } from '../shared/FormTabs';

export interface PrefixProp {
  prefix?: string;
}

const WorkpieceMainFields: FunctionComponent<PrefixProp> = ({ prefix }) => {
  const { watch, errors, setValue, getValues } = useFormContext();
  const [view, setView] = useState(undefined);
  const namePrefix = useMemo(
    () => (prefix && prefix.length > 0 ? `${prefix}.` : ''),
    [prefix],
  );
  const regulationId = watch(`${namePrefix}regulationId`);
  const steelRegulationId = watch(`${namePrefix}steelRegulationId`);
  const serialNumbers = watch(`${namePrefix}serialNumbers`);

  const handleDeselect = useCallback(
    key => {
      const items = getValues(`${namePrefix}items`);
      const deleteIndex = serialNumbers.indexOf(key);
      const activeIndex = serialNumbers.indexOf(view);

      if (deleteIndex === activeIndex) {
        if (deleteIndex === 0) {
          setView(serialNumbers[deleteIndex + 1]);
        } else {
          setView(serialNumbers[deleteIndex - 1]);
        }
      }

      setTimeout(() => {
        setValue(
          `${namePrefix}items`,
          items.filter((_, index) => index !== deleteIndex),
        );
      }, 100);
    },
    [view, serialNumbers],
  );

  return (
    <>
      <FormControl
        label="ГОСТ/ТУ на поставку"
        name={`${namePrefix}regulationId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/find-by-query?type=workpiece`}
          />
        }
      />

      <FormControl
        label="Класс прочности"
        name={`${namePrefix}strengthClassId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/find-strength-class-by-query`}
          />
        }
      />

      <FormControl
        label="ГОСТ/ТУ на сталь"
        name={`${namePrefix}steelRegulationId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/find-by-query?type=steel-grade`}
          />
        }
      />

      <FormControl
        label="Марка стали"
        name={`${namePrefix}steelGradeId`}
        as={
          <SearchSelect
            url={`${API_URL}/regulation/${steelRegulationId}/find-steel-grade-by-query`}
          />
        }
      />

      <FormControl
        label="Тип"
        name={`${namePrefix}type`}
        as={
          <Select
            options={[
              { value: 'seamless', label: 'Бесшовная труба' },
              { value: 'welded', label: 'Сварная труба' },
            ]}
          />
        }
      />

      <FormControl
        label="Количество, шт"
        name={`${namePrefix}count`}
        as={
          <InputNumber autoComplete="new-password" style={{ width: '100%' }} />
        }
      />

      <FormControl
        label="Серийные номера"
        name={`${namePrefix}serialNumbers`}
        as={
          <Select
            allowClear
            filterOption
            mode="tags"
            open={false}
            onDeselect={handleDeselect}
            tokenSeparators={[' ', ',']}
            placeholder="Введите серийные номера заготовок"
          />
        }
      />

      {serialNumbers && (
        <FormTabs activeKey={view} onChange={setView} errors={errors}>
          {serialNumbers.map((serialNumber, index) => (
            <Tabs.TabPane
              forceRender
              key={`${prefix}items[${index}]`}
              tab={`Труба №${serialNumber}`}
            >
              <WorkpieceItemFields
                index={index}
                prefix={namePrefix}
                serialNumber={serialNumber}
              />
            </Tabs.TabPane>
          ))}
        </FormTabs>
      )}
    </>
  );
};

const WorkpieceItemFields = ({ index, prefix, serialNumber }) => {
  const { setValue } = useFormContext();
  const namePrefix = `${prefix}items[${index}]`;

  useEffect(() => {
    setValue(`${namePrefix}.serialNumber`, serialNumber);
  }, []);

  return (
    <>
      <FormControl
        label="Серийный номер"
        name={`${namePrefix}.serialNumber`}
        as={<Input autoComplete="new-password" disabled />}
      />

      <FormControl
        label="Номер плавки"
        name={`${namePrefix}.meltingNumber`}
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Гарантированное давление"
        name={`${namePrefix}.hydraulicPressure`}
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Номинальный диаметр"
        name={`${namePrefix}.nominalDiameter`}
        as={<Input autoComplete="new-password" />}
      />

      <FormControl
        label="Длина, мм"
        name={`${namePrefix}.length`}
        as={
          <InputNumber autoComplete="new-password" style={{ width: '100%' }} />
        }
      />

      <FormControl
        label="Толщина стенки"
        name={`${namePrefix}.wallThickness`}
        as={<Input autoComplete="new-password" />}
      />
    </>
  );
};

export const WorkpieceForm = ({ index }) => {
  return (
    <>
      <Divider>Редактирование позиции поставки №{index + 1}</Divider>

      <WorkpieceMainFields prefix={`workpieces[${index}]`} />
    </>
  );
};
