import { Button, Menu, notification } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ButtonProps } from 'antd/es/button';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { extractErrorInfo } from '../../utils/extractError';
import { MenuItemProps } from 'antd/es/menu/MenuItem';

const useDownloadHandle = ({ fileName, href }) => {
  const [loading, setLoading] = useState(false);
  const handleDownload = useCallback(async () => {
    try {
      document.body.style.cursor = 'wait';
      setLoading(true);
      const { data } = await axios.get(href, { responseType: 'blob' });
      document.body.style.cursor = 'default';
      setLoading(false);
      fileDownload(data, fileName);
    } catch (e) {
      document.body.style.cursor = 'default';
      setLoading(false);
      notification.error(extractErrorInfo(e));
    }
  }, [fileName, href]);

  return useMemo(() => ({ loading, handleDownload }), [
    loading,
    handleDownload,
  ]);
};

export const DownloadButton: FunctionComponent<Omit<
  ButtonProps,
  'target' | 'type'
> & {
  type: 'text' | 'link';
  fileName: string;
}> = ({ fileName, href, children, ...props }) => {
  const { loading, handleDownload } = useDownloadHandle({ href, fileName });

  return (
    <Button {...props} loading={loading} onClick={handleDownload}>
      {children}
    </Button>
  );
};

export const DownloadMenuItem: FunctionComponent<MenuItemProps & {
  href: string;
  fileName: string;
}> = ({ href, fileName, children, ...props }) => {
  const { loading, handleDownload } = useDownloadHandle({ href, fileName });

  return (
    <Menu.Item {...props} disabled={loading} onClick={handleDownload}>
      {children}
    </Menu.Item>
  );
};
