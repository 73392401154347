import { useCallback } from 'react';
import { notification } from 'antd';
import { extractErrorInfo } from '../utils/extractError';

export const useRemoveHandler = ({ remove, refresh, description }) => {
  return useCallback(
    (id) => async () => {
      try {
        await remove(id);
        await refresh();
        notification.success({
          message: 'Успешно!',
          description,
        });
      } catch (e) {
        notification.error(extractErrorInfo(e));
      }
    },
    [remove, refresh, description],
  );
};
