import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Form, notification, Row } from 'antd';
import { fullWidthLayout, layout } from '../../config/form';
import { RegulationMainFields } from './RegulationMainFields';
import { SteelGradeForm } from '../steel-grade/SteelGradeForm';
import { FormProvider, useForm } from 'react-hook-form';
import { createEmptyRegulation } from './helpers';
import { usePreservedFormState } from '../../hooks/use-preserved-form-state';
import { TabbedFormProps } from '../../interfaces/tabbed-form.props';
import { useSubmitFormHandler } from '../../hooks/use-submit-form.handler';
import { create, update } from '../../api/regulation.api';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import { steelGradeSchema } from '../../config/validation';
import yup from '../../config/yup';
import { PageWrapper } from '../shared/styled';
import { useFormIndex, withFormProvider } from '../../hooks/use-form-index';
import {
  CreateRegulationDto,
  RegulationDto,
  UpdateRegulationDto,
} from '@tek-crm/backend/dist/application/regulation/dto';
import { StringValues } from '../../interfaces/string-values.type';

const schema = yup.object().shape({
  name: yup
    .string()
    .max(128)
    .required('Обязательное поле'),
  type: yup.string().required('Необходимо выбрать тип регламента'),
  steelGrades: yup.mixed().when('type', {
    is: type => type === 'steel-grade',
    then: yup
      .array()
      .min(1)
      .of(steelGradeSchema),
    otherwise: yup.mixed().notRequired(),
  }),
});

const useValidationResolver = createYupValidationResolverHook(schema);

const processData = (data: CreateRegulationDto): any => {
  if (data.type === 'steel-grade') {
    return {
      ...data,
      steelGrades: data.steelGrades.map(steelGrade => ({
        ...steelGrade,
        elements: steelGrade.elements.map(
          ({ addon, valueOther, ...element }: any) => {
            if (!element.value || element.value === '' || !addon) {
              return element;
            }

            return {
              ...element,
              value:
                addon !== '-'
                  ? `${addon}${element.value}`
                  : `${element.value}-${valueOther}`,
            };
          },
        ),
      })),
    };
  }

  return data;
};

const regulationToFormData = (
  regulation: RegulationDto,
): StringValues<UpdateRegulationDto> => {
  if (regulation.type !== 'steel-grade') {
    return regulation as any;
  }

  return {
    ...regulation,
    steelGrades: regulation.steelGrades.map(steelGrade => ({
      ...steelGrade,
      elements: Array.isArray(steelGrade.elements)
        ? steelGrade.elements.map(element => {
            if (!element.value) {
              return element as any;
            }

            let value, valueOther, addon;
            let valueMatches;
            const addonMatches = element.value.match(/([=<>-]+)/);

            if (addonMatches) {
              addon = addonMatches[0];
            }

            if (addon === '-') {
              valueMatches = element.value.match(/([\d+.?]+)-([\d+.?]+)/);
            } else {
              valueMatches = element.value.match(/[\d+.?]+/);
            }

            if (valueMatches && addon === '-') {
              [, value, valueOther] = valueMatches;
            } else if (valueMatches) {
              [value] = valueMatches;
            }

            return {
              id: element.id,
              name: element.name,
              value,
              addon,
              valueOther,
            };
          })
        : [],
    })),
  };
};

const RegForm: FunctionComponent<TabbedFormProps> = ({
  show,
  item,
  closeTab,
  tabKey,
  refresh,
}) => {
  const resolver = useValidationResolver();
  const [steelIndex] = useFormIndex('steelGrades');
  const [context, setContext] = useState({ type: 'product' });
  const initialValues = useMemo(
    () =>
      item
        ? regulationToFormData(item)
        : { ...createEmptyRegulation(), type: 'product' },
    [item],
  );
  const methods = useForm<any>({
    defaultValues: initialValues,
    shouldUnregister: false,
    resolver,
    context,
  });
  console.log(methods.errors);

  usePreservedFormState({
    show,
    watch: methods.watch,
    reset: methods.reset,
    origin: item ? 'edit' : 'add',
  });
  const type = methods.watch('type');

  useEffect(() => {
    setContext({ type });
  }, [type]);

  const submitForm = useSubmitFormHandler({
    formState: methods.formState,
    handleSubmit: methods.handleSubmit,
    onValid: useCallback(
      async data => {
        const processedData = processData(data);
        if (!item) {
          await create(processedData);
        } else {
          await update(item.id, processedData);
        }

        notification.success({
          message: 'Успешно!',
          description: `Регламент был успешно ${
            item ? 'сохранён' : 'обновлён'
          }`,
        });
      },
      [item],
    ),
    closeTab: () => {
      methods.reset();
      refresh();
      closeTab(tabKey)();
    },
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Form
          autoComplete="new-password"
          {...(type === 'product' ? fullWidthLayout : layout)}
          onFinish={submitForm}
        >
          {!['product', 'workpiece'].includes(type) && (
            <Row gutter={24}>
              <Col
                span={9}
                style={{
                  borderRight: '1px solid rgba(0, 0, 0, 0.06)',
                }}
              >
                <RegulationMainFields
                  item={item}
                  closeTab={closeTab}
                  tabKey={tabKey}
                />
              </Col>

              <Col span={15}>
                {['steel-grade', 'detail-steel-grade'].includes(type) &&
                steelIndex > -1 ? (
                  <SteelGradeForm index={steelIndex} />
                ) : null}
              </Col>
            </Row>
          )}

          {['product', 'workpiece'].includes(type) && (
            <RegulationMainFields
              item={item}
              closeTab={closeTab}
              tabKey={tabKey}
            />
          )}
        </Form>
      </FormProvider>
    </PageWrapper>
  );
};

export const RegulationForm = withFormProvider(RegForm);
