import React from 'react';
import { Page } from '../../components/page/Page';
import { TabProps, useTabs } from '../../hooks/use-tabs';
import { usePaginationData } from '../../hooks/use-pagination-data';
import { API_URL } from '../../api/routes';
import { TabForms } from '../../components/shared/TabForms';
import { StrengthClasssTable } from '../../components/strength-class/StrengthClasssTable';
import { StrengthClassForm } from '../../components/strength-class/StrengthClassForm';

const defaultTabs: TabProps[] = [
  {
    key: 'list',
    type: 'list',
    label: 'Список',
    closable: false,
  },
];

export const StrengthClassPage = () => {
  const { state, onTableChange, refresh } = usePaginationData(
    `${API_URL}/strength-class`,
  );

  const { TabList, activeKey, closeTab, openTab } = useTabs({
    defaultTabs,
    defaultActiveKey: 'list',
    createLabel: item =>
      item ? `Класс прочности "${item.name}"` : 'Новый класс прочности',
    url: `${API_URL}/strength-class`,
  });

  return (
    <Page
      title="Классы прочности"
      subTitle="Просмотр, добавление и редактирование классов прочности"
      TabList={TabList}
    >
      <StrengthClasssTable
        state={state}
        onTableChange={onTableChange}
        show={'list' === activeKey}
        openTab={openTab}
        refresh={refresh}
      />

      <TabForms
        type={['add', 'edit']}
        excludeKeys={['list']}
        renderItem={(item, { key }) => (
          <StrengthClassForm
            refresh={refresh}
            closeTab={closeTab}
            key={key}
            item={item}
            tabKey={key}
            show={key === activeKey}
          />
        )}
      />
    </Page>
  );
};
