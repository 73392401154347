import { API_URL } from './routes';
import axios from 'axios';
import { CreateSupplyDto } from '@tek-crm/backend/dist/application/supply/dto/create-supply.dto';
import { UpdateSupplyDto } from '@tek-crm/backend/dist/application/supply/dto/update-supply.dto';
import { CreateReceiptControlActDto } from '@tek-crm/backend/dist/application/supply/dto/create-receipt-control-act.dto';
import { UpdateReceiptControlActDto } from '@tek-crm/backend/dist/application/supply/dto/update-receipt-control-act.dto';
import { CreateDetailControlActDto } from '@tek-crm/backend/dist/application/supply/dto/create-detail-control-act.dto';
import { UpdateDetailControlActDto } from '@tek-crm/backend/dist/application/supply/dto/update-detail-control-act.dto';

export const count = async () => {
  const { data } = await axios.get(`${API_URL}/supply/count`);
  return data;
};

export const create = async (formData: CreateSupplyDto) => {
  const { data } = await axios.post(`${API_URL}/supply/create`, formData);
  return data;
};

export const update = async (id, formData: UpdateSupplyDto) => {
  const { data } = await axios.post(`${API_URL}/supply/${id}/update`, formData);
  return data;
};

export const createReceiptControlAct = async (
  id,
  formData: CreateReceiptControlActDto,
) => {
  const { data } = await axios.post(
    `${API_URL}/supply/${id}/receipt-control-act/create`,
    formData,
  );
  return data;
};

export const updateReceiptControlAct = async (
  supplyId,
  certificateId,
  formData: UpdateReceiptControlActDto,
) => {
  const { data } = await axios.post(
    `${API_URL}/supply/${supplyId}/receipt-control-act/${certificateId}`,
    formData,
  );
  return data;
};

export const createDetailControlAct = async (
  id,
  formData: CreateDetailControlActDto,
) => {
  const { data } = await axios.post(
    `${API_URL}/supply/${id}/detail-control-act/create`,
    formData,
  );
  return data;
};

export const updateDetailControlAct = async (
  supplyId,
  certificateId,
  formData: UpdateDetailControlActDto,
) => {
  const { data } = await axios.post(
    `${API_URL}/supply/${supplyId}/detail-control-act/${certificateId}`,
    formData,
  );
  return data;
};
