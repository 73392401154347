import { API_URL } from './routes';
import axios from 'axios';
import { CreateSupplyDto } from '@tek-crm/backend/dist/application/supply/dto/create-supply.dto';
import { UpdateSupplyDto } from '@tek-crm/backend/dist/application/supply/dto/update-supply.dto';

export const count = async () => {
  const { data } = await axios.get(`${API_URL}/technical-project/count`);
  return data;
};

export const findAcceptanceTestReport = async id => {
  const { data } = await axios.get(
    `${API_URL}/technical-project/${id}/acceptance-test-report`,
  );
  return data;
};

export const getProject = async id => {
  const { data } = await axios.get(`${API_URL}/technical-project/${id}`);
  return data;
};

export const create = async (formData: CreateSupplyDto) => {
  const { data } = await axios.post(
    `${API_URL}/technical-project/create`,
    formData,
  );
  return data;
};

export const update = async (id, formData: UpdateSupplyDto) => {
  const { data } = await axios.post(
    `${API_URL}/technical-project/${id}/update`,
    formData,
  );
  return data;
};

export const createCertificate = async (id, formData: UpdateSupplyDto) => {
  const { data } = await axios.post(
    `${API_URL}/technical-project/${id}/acceptance-test-report/create`,
    formData,
  );
  return data;
};

export const updateCertificate = async (
  techProjectId: string,
  id: string,
  formData: UpdateSupplyDto,
) => {
  const { data } = await axios.post(
    `${API_URL}/technical-project/${techProjectId}/acceptance-test-report/${id}`,
    formData,
  );
  return data;
};
