import styled from 'styled-components';
import { Form, Layout } from 'antd';

export const LoginContainer = styled(Layout)`
  height: 100vh;
  background-image: url(/img/bg-login-1.jpg);
  background-size: cover;
  position: relative;
  z-index: 0;
  :after {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(70, 70, 70, 0.41);
    content: '';
    z-index: -1;
  }

  .ant-layout-footer {
    background: transparent;
  }
`;

export const Title = styled.div`
  color: #d9d9d9;
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  padding-bottom: 15px;
`;

export const LoginForm = styled(Form)`
  & {
    padding: 50px;
    border-radius: 200px;
    height: 400px;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    border: 10px solid rgba(88, 88, 88, 1);
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 19%,
      rgba(88, 88, 88, 1) 99%
    );

    input,
    span {
      filter: none;
      color: #d9d9d9;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 0;
      -webkit-text-fill-color: #ссс;
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 174, 255, 0.04) 50%,
        rgba(255, 255, 255, 0) 51%,
        rgba(0, 174, 255, 0.03) 100%
      );
    }

    .anticon-user,
    .anticon-lock,
    .anticon-eye-invisible,
    .anticon-eye {
      color: #8b231d;
    }

    .ant-input-affix-wrapper,
    .ant-checkbox-inner,
    .ant-input {
      background-color: rgba(88, 88, 88, 1);
      border-color: #994139;
    }
  }
`;
export const LoginHeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;
export const LoginHeader = styled.img``;
export const LoginDescription = styled.div`
  color: gray;
  font-size: 1.25rem;
`;
