import React, { FunctionComponent, useMemo } from 'react';
import { Modal, Table } from 'antd';
import { TableComponentProps } from '../../interfaces/table-component-props.interface';
import { SupplyDto } from '@tek-crm/backend/dist/application/supply/dto/supply.dto';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { useModal } from '../../hooks/use-modal';
import { useColumnSearchProps } from '../../hooks/use-column-search-props';
import { DownloadButton } from '../shared/DownloadButton';
import { format } from 'date-fns';
import { API_URL } from '../../api/routes';

const useActColumns = ({ state, openTab, open, close, refresh }) => {
  const getColumnSearchProps = useColumnSearchProps();

  const printPermission = useAccessRule({
    domain: AccessAttributeDomain.Act,
    requestedPermission: 'print-vkt',
  });

  return useMemo(() => {
    return [
      {
        title: 'Номер Акта',
        ...getColumnSearchProps('actNumber'),
      },
      {
        title: 'Дата',
        ...getColumnSearchProps('date', 'date'),
      },
      {
        title: 'Дата поставки',
        ...getColumnSearchProps(['supply', 'date'], 'date'),
      },
      {
        title: 'Поставщик',
        ...getColumnSearchProps(['supply', 'supplier', 'name']),
      },
      {
        title: 'Производитель',
        ...getColumnSearchProps(['supply', 'manufacturer', 'name']),
      },
      {
        title: 'Количество труб',
        dataIndex: 'workpieceCount',
      },
      {
        title: 'Действие',
        dataIndex: 'action',
        render: (value, record) => {
          return (
            <DownloadButton
              type="link"
              disabled={!printPermission}
              fileName={`Акт ВКТ №${record.actNumber} на поставку от ${format(
                new Date(record.supply.date),
                'dd.MM.yyyy',
              )}.docx`}
              href={`${API_URL}/supply/${record.supply.id}/docx/${record.id}`}
            >
              Скачать
            </DownloadButton>
          );
        },
      },
    ];
  }, [state, openTab]);
};

export const ActsTable: FunctionComponent<TableComponentProps<SupplyDto>> = ({
  state,
  onTableChange,
  show,
  openTab,
  refresh,
}) => {
  const { content, visible, open, close } = useModal();
  const columns = useActColumns({
    state,
    openTab,
    open,
    close,
    refresh,
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <Table
        scroll={{ x: 700 }}
        columns={columns}
        rowKey={record => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={state.loading}
        onChange={onTableChange}
      />

      <Modal
        maskClosable
        destroyOnClose
        width="90%"
        visible={visible}
        footer={null}
        onCancel={close}
      >
        {content}
      </Modal>
    </PageWrapper>
  );
};
