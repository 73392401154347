import { Button, Input, Space } from 'antd';
import React, { Key, useCallback, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { get, has } from 'lodash';
import { DatePicker } from '../components/shared/DatePicker';
import { format, isSameDay } from 'date-fns';

const filterDate = (filterValue: string, recordValue: string): boolean =>
  isSameDay(new Date(filterValue), new Date(recordValue));

const filterText = (filterValue: string, recordValue: string): boolean =>
  recordValue
    .toString()
    .toLowerCase()
    .includes(filterValue.toLowerCase());

export interface SearchInputHandlerArgs {
  confirm: (props?: { closeDropdown?: boolean }) => void;
  dataIndex: string | string[];
  selectedKeys: Key[];
}

export const useColumnSearchProps = () => {
  let inputRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState<string | string[]>('');

  const handleSearch = useCallback(
    ({ selectedKeys, confirm, dataIndex }: SearchInputHandlerArgs) => {
      confirm();
      setSearchText(selectedKeys[0] as string);
      setSearchedColumn(dataIndex);
    },
    [],
  );

  const handleReset = useCallback((clearFilters: () => void): void => {
    clearFilters();
    setSearchText('');
  }, []);

  const handleFilter = useCallback(
    ({ selectedKeys, confirm, dataIndex }: SearchInputHandlerArgs) => {
      confirm({ closeDropdown: false });
      setSearchText(selectedKeys[0] as string);
      setSearchedColumn(dataIndex);
    },
    [],
  );

  return useCallback(
    (dataIndex: string | string[], type: 'text' | 'date' = 'text') => ({
      dataIndex,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        ...rest
      }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          {type === 'text' && (
            <Input
              ref={node => {
                inputRef.current = node;
              }}
              placeholder="Введите фразу для поиска..."
              value={selectedKeys[0]}
              onChange={e => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() =>
                handleSearch({ selectedKeys, confirm, dataIndex })
              }
              style={{ marginBottom: 8, display: 'block' }}
            />
          )}
          {type === 'date' && (
            <DatePicker
              ref={node => {
                inputRef.current = node;
              }}
              placeholder="Введите фразу для поиска..."
              value={new Date(selectedKeys[0])}
              onChange={value => setSelectedKeys([value.toString()])}
              style={{ marginBottom: 8, display: 'block' }}
            />
          )}
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch({ selectedKeys, confirm, dataIndex })}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Найти
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Сбросить
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                handleFilter({ selectedKeys, confirm, dataIndex });
              }}
            >
              Фильтровать
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        if (has(record, dataIndex)) {
          const recordValue = get(record, dataIndex);

          return type === 'text'
            ? filterText(value, recordValue)
            : filterDate(value, recordValue);
        }

        return false;
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible && type === 'text') {
          setTimeout(() => inputRef.current.select(), 100);
        }
      },
      render: text => {
        const display =
          type === 'date' ? format(new Date(text), 'dd.MM.yyyy') : text;

        if (
          !searchText ||
          searchText === '' ||
          searchedColumn.toString() !== dataIndex.toString()
        ) {
          return display;
        }

        const search =
          type === 'date'
            ? format(new Date(searchText), 'dd.MM.yyyy')
            : searchText;

        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={display ? display : ''}
          />
        );
      },
    }),
    [searchText, searchedColumn],
  );
};
