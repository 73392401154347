import generatePicker, {
  PickerProps,
  RangePickerProps,
} from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import React, { forwardRef } from 'react';
import locale from 'antd/es/date-picker/locale/ru_RU';
import generateConfig from '../../utils/fixGenerateDatePicker';

const BaseDatePicker = generatePicker<Date>(generateConfig);

export const DatePicker = forwardRef<HTMLInputElement, PickerProps<Date>>(
  (props, ref: any) => (
    <BaseDatePicker
      locale={locale}
      defaultPickerValue={new Date()}
      {...props}
      ref={ref}
    />
  ),
);

export const RangePicker = forwardRef<HTMLInputElement, RangePickerProps<Date>>(
  (props, ref: any) => (
    <BaseDatePicker.RangePicker locale={locale} ref={ref} {...props} />
  ),
);
