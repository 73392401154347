import { MenuConfig } from '../redux/features/config';
import React from 'react';
import {
  BarsOutlined,
  DeploymentUnitOutlined,
  HomeOutlined,
  InboxOutlined,
  PaperClipOutlined,
  PicCenterOutlined,
  RadiusSettingOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

export const menu: MenuConfig = {
  header: {
    items: [
      {
        title: 'Главная',
        page: '/',
        icon: <HomeOutlined />,
        domain: true,
      },
      {
        title: 'Тех. задания',
        page: '/technical-project',
        icon: <RadiusSettingOutlined />,
        domain: AccessAttributeDomain.TechnicalProject,
      },
      {
        title: 'Поставки',
        page: '/supply',
        icon: <InboxOutlined />,
        domain: AccessAttributeDomain.Supply,
      },
      {
        title: 'Справочники',
        page: 'dictionary',
        icon: <BarsOutlined />,
        items: [
          {
            title: 'Регламенты',
            page: '/regulation',
            icon: <PaperClipOutlined />,
            domain: AccessAttributeDomain.Regulation,
          },
          {
            title: 'Классы прочности',
            page: '/strength-class',
            icon: <PicCenterOutlined />,
            domain: AccessAttributeDomain.Tool,
          },
          {
            title: 'Контрагенты',
            page: '/counterparty',
            icon: <DeploymentUnitOutlined />,
            domain: AccessAttributeDomain.Counterparty,
          },
          {
            title: 'Инструменты НК',
            page: '/tools',
            icon: <ToolOutlined />,
            domain: AccessAttributeDomain.Tool,
          },
          {
            title: 'Пользователи',
            page: '/users',
            icon: <UserOutlined />,
            domain: AccessAttributeDomain.User,
          },
        ],
      },
    ],
  },
};
