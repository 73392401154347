let url;
const { REACT_APP_ENV, NODE_ENV } = process.env;
const env = REACT_APP_ENV || NODE_ENV;

switch (env) {
  case 'development':
    url = 'http://backend.localhost';
    break;
  case 'staging':
    url = 'http://192.168.88.174:4010';
    break;
  case 'production':
    url = '';
    break;
  default:
    url = 'http://192.168.88.174:4010';
    break;
}

export const URL = url;
export const API_URL = `${url}/api/v1`;
