import React, { FunctionComponent, useMemo } from 'react';
import { Button, Dropdown, Menu, Modal, Space, Table } from 'antd';
import { TableComponentProps } from '../../interfaces/table-component-props.interface';
import { SupplyDto } from '@tek-crm/backend/dist/application/supply/dto/supply.dto';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';
import { useModal } from '../../hooks/use-modal';
import { useColumnSearchProps } from '../../hooks/use-column-search-props';
import { DownloadButton, DownloadMenuItem } from '../shared/DownloadButton';
import { format } from 'date-fns';
import { API_URL } from '../../api/routes';
import { AcceptanceTestReportForm } from './AcceptanceTestReportForm';
import { EllipsisOutlined } from '@ant-design/icons';

const useColumns = ({ state, openTab, open, close, refresh }) => {
  const getColumnSearchProps = useColumnSearchProps();

  const printPermission = useAccessRule({
    domain: AccessAttributeDomain.TechnicalProject,
    requestedPermission: 'print',
  });

  const printPassportPermission = useAccessRule({
    domain: AccessAttributeDomain.TechnicalProject,
    requestedPermission: 'print-passport',
  });

  return useMemo(() => {
    return [
      {
        title: 'Номер Акта',
        ...getColumnSearchProps('actNumber'),
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        filters: [
          {
            text: 'Отводы гнутые',
            value: 'BendAcceptanceTestReport',
          },
          {
            text: 'Приварка/нарезка',
            value: 'WeldingAcceptanceTestReport',
          },
        ],
        filterMultiple: true,
      },
      {
        title: 'Номер технического задания',
        ...getColumnSearchProps(['techProject', 'number']),
      },
      {
        title: 'Контрагент',
        ...getColumnSearchProps(['techProject', 'counterparty', 'name']),
      },
      {
        title: 'Количество позиций',
        dataIndex: 'productCount',
      },
      {
        title: 'Действия',
        dataIndex: 'productCount',
        render: (value, record) => {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <DownloadMenuItem
                    key="1"
                    disabled={!printPermission}
                    fileName={`Акт ПСИ №${record.actNumber} для ТЗ №${record.techProject.number}.docx`}
                    href={`${API_URL}/technical-project/${record.techProject.id}/docx/${record.id}`}
                  >
                    Скачать акт ПСИ
                  </DownloadMenuItem>
                  <DownloadMenuItem
                    key="3"
                    disabled={!printPermission}
                    fileName={`Техническое задание №${record.techProject.number}.docx`}
                    href={`${API_URL}/technical-project/${record.techProject.id}/docx`}
                  >
                    Скачать тех. задание
                  </DownloadMenuItem>
                </Menu>
              }
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          );
        },
      },
    ];
  }, [state, openTab]);
};

export const PSITable: FunctionComponent<TableComponentProps<SupplyDto>> = ({
  state,
  onTableChange,
  show,
  openTab,
  refresh,
}) => {
  const { content, visible, open, close } = useModal();
  const columns = useColumns({
    state,
    openTab,
    open,
    close,
    refresh,
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <Table
        scroll={{ x: 700 }}
        columns={columns}
        rowKey={record => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={state.loading}
        onChange={onTableChange}
      />

      <Modal
        maskClosable
        destroyOnClose
        width="90%"
        visible={visible}
        footer={null}
        onCancel={close}
      >
        {content}
      </Modal>
    </PageWrapper>
  );
};
