import React, { FunctionComponent, useMemo } from 'react';
import { Button, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons/lib';
import { RegulationDto } from '@tek-crm/backend/dist/application/regulation/dto/regulation.dto';
import { TableComponentProps } from '../../interfaces/table-component-props.interface';
import { useRemoveHandler } from '../../hooks/use-remove-handler';
import { remove } from '../../api/regulation.api';
import { PageWrapper } from '../shared/styled';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

const useRegulationColumns = ({ pagination, openTab, refresh }) => {
  const createRemoveHandler = useRemoveHandler({
    remove,
    refresh,
    description: 'Регламент был успешно удалён',
  });

  const viewPermission = useAccessRule({
    domain: AccessAttributeDomain.Regulation,
    requestedPermission: 'view',
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Regulation,
    requestedPermission: 'manage',
  });

  return useMemo(
    () => [
      {
        title: 'Название',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        render: text => {
          switch (text) {
            case 'product':
              return 'Продукция';
            case 'workpiece':
              return 'Заготовки';
            case 'steel-grade':
              return 'ГОСТ на сталь';
            case 'detail-steel-grade':
              return 'ГОСТ на сталь детали';

            default:
              return 'Неизвестно';
          }
        },
      },
      {
        title: 'Действия',
        key: 'action',
        fixed: 'right' as const,
        width: 100,
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="dashed"
              disabled={!viewPermission}
              icon={<EditOutlined />}
              onClick={() => openTab({ type: 'edit', key: record.id })}
            />
            <Button
              danger
              type="dashed"
              disabled={!managePermission}
              icon={<DeleteOutlined />}
              onClick={createRemoveHandler(record.id)}
            />
          </Space>
        ),
      },
    ],
    [pagination, openTab],
  );
};

export const RegulationTable: FunctionComponent<TableComponentProps<
  RegulationDto
>> = ({ state, onTableChange, show, openTab, refresh }) => {
  const columns = useRegulationColumns({
    pagination: state.pagination,
    openTab,
    refresh,
  });

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Regulation,
    requestedPermission: 'manage',
  });

  if (!show) {
    return null;
  }

  return (
    <PageWrapper>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Button
          block
          type="primary"
          disabled={!managePermission}
          onClick={() => openTab({ key: 'add' })}
        >
          Добавить
        </Button>
        <Table
          scroll={{ x: 700 }}
          columns={columns}
          rowKey={record => record.id}
          dataSource={state.data}
          pagination={state.pagination}
          loading={state.loading}
          onChange={onTableChange}
        />
      </Space>
    </PageWrapper>
  );
};
