import React, { FunctionComponent } from 'react';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { FormMainProps } from '../../interfaces/form-main-props.interface';
import { CreateRegulationDto } from '@tek-crm/backend/dist/application/regulation/dto/create-regulation.dto';
import { useManageChildList } from '../../hooks/use-manage-child-list';
import { CreateSteelGradeDto } from '@tek-crm/backend/dist/application/regulation/dto/create-steel-grade.dto';
import { createEmptySteelGrade, createEmptyStrengthClass } from './helpers';
import { useFormContext } from 'react-hook-form';
import { ChildList } from '../shared/ChildList';
import { FormControl } from '../form-controls/FormControl';
import { useFormIndex } from '../../hooks/use-form-index';
import { useAccessRule } from '../../hooks/use-access-rule';
import { AccessAttributeDomain } from '@tek-crm/backend/dist/domain/user/types/access-attribute.type';

const useSteelGradesList = setIndex =>
  useManageChildList<CreateSteelGradeDto>({
    key: 'steelGrades',
    setIndex,
    createEmpty: createEmptySteelGrade,
    createItemTitle: (item, index) => `Марка стали "${item.name}"`,
  });

export const RegulationMainFields: FunctionComponent<FormMainProps & {
  item: any;
}> = ({ item, tabKey, closeTab }) => {
  const [, setSteelIndex] = useFormIndex('steelGrades');
  const steelGradesListMethods = useSteelGradesList(setSteelIndex);
  const { watch, formState } = useFormContext<CreateRegulationDto>();
  const { steelGrades, type } = watch(['steelGrades', 'type']);

  const managePermission = useAccessRule({
    domain: AccessAttributeDomain.Regulation,
    requestedPermission: 'manage',
  });

  return (
    <>
      <Divider>Информация о регламенте</Divider>

      <FormControl
        as={<Input autoComplete="new-password" />}
        name="name"
        label="Название"
      />

      {!item && (
        <FormControl
          as={
            <Select
              options={[
                { value: 'product', label: 'Продукция' },
                { value: 'workpiece', label: 'Заготовка' },
                { value: 'steel-grade', label: 'Марка стали' },
                { value: 'detail-steel-grade', label: 'Марка стали детали' },
              ]}
            />
          }
          name="type"
          label="Тип"
        />
      )}

      {!['product', 'workpiece'].includes(type) && (
        <Row justify="end" gutter={[20, 10]}>
          {['steel-grade', 'detail-steel-grade'].includes(type) && (
            <Col flex="1 0">
              <Button
                onClick={steelGradesListMethods.handleAdd}
                style={{ marginRight: 'auto' }}
              >
                Добавить марку стали
              </Button>
            </Col>
          )}
          <Col>
            <Space>
              <Button
                /*loading={formState.isSubmitting}*/
                disabled={formState.isSubmitting || !managePermission}
                htmlType="submit"
                type="primary"
              >
                Сохранить
              </Button>
              {closeTab && (
                <Button danger type="dashed" onClick={closeTab(tabKey)}>
                  Закрыть
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      )}

      {['product', 'workpiece'].includes(type) && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Button
            block
            /*loading={formState.isSubmitting}*/
            disabled={formState.isSubmitting || !managePermission}
            htmlType="submit"
            type="primary"
          >
            Сохранить
          </Button>
          {closeTab && (
            <Button block danger type="dashed" onClick={closeTab(tabKey)}>
              Закрыть
            </Button>
          )}
        </Space>
      )}

      {['steel-grade', 'detail-steel-grade'].includes(type) && (
        <>
          <Divider />
          <ChildList
            index={steelGradesListMethods.index}
            dataSource={steelGrades}
            handleEdit={steelGradesListMethods.handleEdit}
            handleRemove={steelGradesListMethods.handleRemove}
            createItemTitle={steelGradesListMethods.itemTitleFactory}
          >
            {item => (
              <>
                <Descriptions.Item label={<b>Название</b>}>
                  {item.name}
                </Descriptions.Item>
                {Array.isArray(item.elements) && (
                  <Descriptions.Item label={<b>Элементы</b>}>
                    {item.elements.map(element => element.name).join(', ')}
                  </Descriptions.Item>
                )}
              </>
            )}
          </ChildList>
        </>
      )}
    </>
  );
};
