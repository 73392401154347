import { CreateToolDto } from '@tek-crm/backend/dist/application/tools/dto/create-tool.dto';
import { API_URL } from './routes';
import axios from 'axios';
import { UpdateToolDto } from '@tek-crm/backend/dist/application/tools/dto/update-tool.dto';

export const create = async (formData: CreateToolDto) => {
  const { data } = await axios.post(`${API_URL}/tools/create`, formData);
  return data;
};

export const update = async (id, formData: UpdateToolDto) => {
  const { data } = await axios.post(`${API_URL}/tools/${id}/update`, formData);
  return data;
};

export const remove = async (id: string) => {
  const { data } = await axios.delete(`${API_URL}/tools/${id}`);
  return data;
};
