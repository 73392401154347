import { Button, Col, Form, Input, Layout, Row } from 'antd';
import React, { useCallback } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { requestPasswordReset, resetPassword } from '../../api/auth.api';
import { createYupValidationResolverHook } from '../../hooks/create-yup-validation-resolver-hook';
import yup from '../../config/yup';
import { useHistory } from 'react-router';
import {
  LoginContainer,
  LoginDescription,
  LoginForm,
  LoginHeader,
  LoginHeaderContainer,
  Title,
} from './styled';
import { openErrorNotification, openSuccessNotification } from './utils';
import { extractErrorInfo } from '../../utils/extractError';
import { useQuery } from '../../utils/use-query';

const { Content, Footer } = Layout;

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .max(255, 'Максимальная величина поля 255 символов')
    .required('Обязательное поле'),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Пароли не совпадают')
    .max(255, 'Максимальная величина поля 255 символов')
    .required('Обязательное поле'),
});

const useYupValidationResolver = createYupValidationResolverHook(
  validationSchema,
);

export const ResetPasswordPage = () => {
  const query = useQuery();
  const token = query.get('token');
  const history = useHistory();
  const resolver = useYupValidationResolver();
  const { control, handleSubmit, errors } = useForm({ resolver });

  const submitForm = useCallback(
    async data => {
      try {
        await resetPassword(token, data.password, data.passwordRepeat);
        openSuccessNotification('Пароль был успешно изменён');
        history.push('/login');
      } catch (e) {
        const { description } = extractErrorInfo(e);
        openErrorNotification(description);
      }
    },
    [token],
  );

  if (!token) {
    history.replace('/login');
  }

  return (
    <LoginContainer>
      <Content style={{ padding: '100px 0' }}>
        <Row justify="center">
          <Col span={24}>
            <LoginHeaderContainer>
              <LoginHeader src="/img/logo-mini.png" />
              <LoginDescription>Система управления</LoginDescription>
            </LoginHeaderContainer>
          </Col>
          <Col span={24}>
            <div style={{ width: 400, margin: '0 auto' }}>
              <LoginForm onFinish={handleSubmit(submitForm)}>
                <Title>Укажите новый пароль</Title>
                <Form.Item
                  help={errors.password}
                  validateStatus={errors.password ? 'error' : 'success'}
                  style={{ width: '100%' }}
                >
                  <Controller
                    name="password"
                    control={control}
                    as={
                      <Input.Password
                        size="large"
                        placeholder="Введите пароль"
                        prefix={<LockOutlined />}
                      />
                    }
                  />
                </Form.Item>

                <Form.Item
                  help={errors.passwordRepeat}
                  validateStatus={errors.passwordRepeat ? 'error' : 'success'}
                  style={{ width: '100%' }}
                >
                  <Controller
                    name="passwordRepeat"
                    control={control}
                    as={
                      <Input.Password
                        size="large"
                        placeholder="Повторите пароль"
                        prefix={<LockOutlined />}
                      />
                    }
                  />
                </Form.Item>

                <Button block type="primary" htmlType="submit">
                  Сохарнить
                </Button>
              </LoginForm>
            </div>
          </Col>
        </Row>
      </Content>
      <Footer>
        <div style={{ color: 'white', textAlign: 'center' }}>
          TEK-CRM © Разработано в{' '}
          <a href="https://linkodium.com/" target="_blank">
            Linkodium
          </a>{' '}
          (2020)
        </div>
      </Footer>
    </LoginContainer>
  );
};
