import axios from 'axios';
import { API_URL } from './routes';

export const remove = async (id: string) => {
  const { data } = await axios.delete(`${API_URL}/document/${id}`);
  return data;
};

export const restore = async (id: string) => {
  const { data } = await axios.post(`${API_URL}/document/${id}/restore`);
  return data;
};
