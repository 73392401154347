import { StringValues } from '../../interfaces/string-values.type';
import { createGeometryParams } from '../../utils/helpers';
import { CreateDiscrepancyInfoDto } from '@tek-crm/backend/dist/application/shared/dto/create-discrepancy-info.dto';
import { CreateWeldingQaResultDto } from '@tek-crm/backend/dist/application/technical-project/dto/acceptance-test-report/create/create-welding-qa-result.dto';
import { CreateTechnicalProjectDto } from '@tek-crm/backend/dist/application/technical-project/dto/create/create-technical-project.dto';
import { CreateDpiQaInfoDto } from '@tek-crm/backend/dist/application/technical-project/dto/acceptance-test-report/create/create-dpi-qa-info.dto';
import { CreateUltrasonicQaDto } from '@tek-crm/backend/dist/application/technical-project/dto/acceptance-test-report/create/create-ultrasonic-qa.dto';
import { CreateWeldingAcceptanceTestReportDto } from '@tek-crm/backend/dist/application/technical-project/dto/acceptance-test-report/create/create-welding-acceptance-test-report.dto';
import { TechnicalProjectDto } from '@tek-crm/backend/dist/application/technical-project/dto/technical-project.dto';
import { CreateAdditionalInfoLineDto } from '@tek-crm/backend/dist/application/technical-project/dto/create/create-additional-info-line.dto';
import { CreateOrderLineDto } from '@tek-crm/backend/dist/application/technical-project/dto/create/create-order-line.dto';
import { CreateOrderItemDto } from '@tek-crm/backend/dist/application/technical-project/dto/create/create-order-item.dto';
import { CreateBendOrderParamsDto } from '@tek-crm/backend/dist/application/technical-project/dto/create/create-bend-order-params.dto';
import { CreateWeldingOrderParamsDto } from '@tek-crm/backend/dist/application/technical-project/dto/create/create-welding-order-params.dto';
import { OrderLineDto } from '@tek-crm/backend/dist/application/technical-project/dto/order-line.dto';
import { UpdateOrderLineDto } from '@tek-crm/backend/dist/application/technical-project/dto/update/update-order-line.dto';
import { UpdateTechnicalProjectDto } from '@tek-crm/backend/dist/application/technical-project/dto/update/update-technical-project.dto';
import { CreateBendAcceptanceTestReportDto } from '@tek-crm/backend/dist/application/technical-project/dto/acceptance-test-report/create/create-bend-acceptance-test-report.dto';
import {
  BendAcceptanceTestReportDto,
  DpiQaInfoDto,
  OrderItemDto,
  UltrasonicQaDto,
  WeldingAcceptanceTestReportDto,
  WeldingOrderParamsDto,
} from '@tek-crm/backend/dist/application/technical-project/dto';

export const orderParamsToFormData = params =>
  params.type === 'bend'
    ? params
    : {
        ...params,
        arrivalOfTube: new Date(params.arrivalOfTube),
        arrivalOfParts: new Date(params.arrivalOfParts),
      };

export const orderItemsToFormData = (
  order: OrderLineDto[],
): UpdateOrderLineDto[] => {
  return order.map(({ regulation, items, params, ...orderLine }) => ({
    ...orderLine,
    params: orderParamsToFormData(params),
    regulationId: regulation.id,
    items: items.map(({ workpiece, details, ...orderItem }) => ({
      ...orderItem,
      workpieceId: (workpiece || {}).id,
      detailIds: (details || []).map(({ id }) => id),
    })),
  }));
};

export const technicalProjectToFormData = ({
  counterparty,
  order,
  ...dto
}: TechnicalProjectDto): UpdateTechnicalProjectDto => {
  return {
    ...dto,
    counterpartyId: counterparty.id,
    order: orderItemsToFormData(order),
  };
};

// @ts-ignore
export const createTechnicalProject = (
  type = 'bend',
): StringValues<CreateTechnicalProjectDto> => ({
  date: new Date(),
  number: '',
  type: 'BendTechnicalProject',
  basis: '',
  responsible: '',
  notes: '',
  status: 'work-in-progress',
  counterpartyId: '',
  order: [createOrderLine(type)],
});

export const createAdditionalInfoLine = (): StringValues<CreateAdditionalInfoLineDto> => ({
  name: '',
  availability: '',
  length: '',
  branchPipeCount: '',
  notes: '',
});

export const createOrderLine = (
  type,
  order = 1,
): StringValues<CreateOrderLineDto> => ({
  type,
  params:
    type === 'bend'
      ? createBendOrderParams(type)
      : createWeldingOrderParams(type),
  name: '',
  lineNumber: order as any,
  regulationId: '',
  items: [],
});

export const createWeldingOrderParams = (
  type,
): StringValues<CreateWeldingOrderParamsDto> => ({
  type,
  jointsCount: '',
  branchPipeCount: '',
  count: '',
  length: '',
  arrivalOfParts: new Date(),
  arrivalOfTube: '',
  workDescription: '',
  notes: '',
});

export const createBendOrderParams = (
  type,
): StringValues<CreateBendOrderParamsDto> => ({
  type,
  pureWeight: '',
  count: '',
  consumptionRateWeight: '',
  consumptionRateLength: '',
  usedOnOrderWeight: '',
  usedOnOrderLength: '',
  roughSizeDB: '',
  roughSizeSH: '',
  roughSizeL: '',
  finishSizeDB: '',
  finishSizeSH: '',
  finishSizeL: '',
  straightSectionA: '',
  straightSectionB: '',
  faceToFaceLengthA: '',
  faceToFaceLengthB: '',
  bendingAngle: '',
  bendingRadius: '',
  bentPartLength: '',
  totalBentPartLength: '',
  nominalDiameter: '',
  lengthOfSweep: '',
  allowance: '',
});

export const createOrderItem = (type): StringValues<CreateOrderItemDto> => ({
  type,
  serialNumber: '',
  workpieceId: '',
  detailIds: [],
});

export const createDiscrepancyInfo = (): StringValues<CreateDiscrepancyInfoDto> => ({
  reason: '',
  causer: '',
  correction: '',
  correctiveActions: '',
  correctionMark: '',
  repeatedQAMark: '',
  correctiveActionsScore: '',
});

export const createDpiQaInfo = (
  type: string = 'material',
): StringValues<CreateDpiQaInfoDto> => ({
  type,
  compliance: '',
  regulationId: '',
  purifierId: '',
  penetrantId: '',
  sampleId: '',
  developerId: '',
});

export const createUltrasonicQa = (
  type: string = 'material',
): StringValues<CreateUltrasonicQaDto> => ({
  type,
  compliance: '',
  regulationId: '',
  deviceId: '',
  sampleId: '',
  transducerId: '',
  defectArea: '',
});

export const createBendAcceptanceTestReport = (): StringValues<CreateBendAcceptanceTestReportDto> => ({
  actNumber: '',
  itemId: '',
  wallThicknessInner: '',
  wallThicknessOuter: '',
  radius1end: '',
  radius2end: '',
  type: 'BendAcceptanceTestReport',
  date: new Date(),
  vickersHardness: '',
  residualFluxDensity: '',
  chamferType: '',
  bendingMode: '',
  productMarking: '',
  discrepancyInfo: createDiscrepancyInfo(),
  dpiQaInfo: [createDpiQaInfo()],
  ultrasonicQaInfo: [createUltrasonicQa()],
  geometryParams: createGeometryParams(),
});

export const createWeldingAcceptanceTestReport = (
  item: TechnicalProjectDto,
): StringValues<CreateWeldingAcceptanceTestReportDto> => ({
  actNumber: '',
  itemId: '',
  type: 'WeldingAcceptanceTestReport',
  date: new Date(),
  discrepancyInfo: createDiscrepancyInfo(),
  qaResult: createQaResult(item),
  ultrasonicQaInfo: [
    createUltrasonicQa('material'),
    createUltrasonicQa('welding'),
  ],
  dpiQaInfo: [createDpiQaInfo('material'), createDpiQaInfo('welding')],
});

const padNumber = (number: number | string) =>
  number.toString().padStart(3, '0');

const createQaResult = (
  item: TechnicalProjectDto,
  initialJointNumber = 0,
): any => {
  let i = initialJointNumber;
  return item.order
    .map((orderLine: OrderLineDto) => {
      const params = orderLine.params as WeldingOrderParamsDto;
      if (orderLine.items.every(orderItem => !orderItem.acceptanceTestReport)) {
        return orderLine.items.map(orderItem =>
          Array(params.jointsCount)
            .fill(undefined)
            .map(() =>
              createWeldingQaResult({
                batchDetailNumber: `${padNumber(item.number)}-${padNumber(
                  orderLine.lineNumber,
                )}-${padNumber(orderItem.serialNumber)}`,
                jointNumber: ++i,
                itemId: orderItem.id,
              }),
            ),
        );
      } else {
        return [];
      }
    })
    .flat(3);
};

export const createWeldingQaResult = ({
  jointNumber,
  batchDetailNumber,
  itemId,
}): StringValues<CreateWeldingQaResultDto> => ({
  ...({
    batchDetailNumber,
  } as any),
  jointNumber,
  itemId,
  welderName: '',
  standardSize: '',
  weldDate: new Date(),
  weldType: '',
  defects: '',
  qualityControl: '',
});

export const createAcceptanceTestReport = (
  item: TechnicalProjectDto,
): StringValues<
  CreateBendAcceptanceTestReportDto | CreateWeldingAcceptanceTestReportDto
> => {
  switch (item.type) {
    case 'BendTechnicalProject':
      return createBendAcceptanceTestReport();
    case 'WeldingTechnicalProject':
      return createWeldingAcceptanceTestReport(item);
  }

  return undefined;
};

const dpiQaToFormData = ({
  regulation,
  purifier,
  developer,
  penetrant,
  sample,
  compliance,
  ...data
}: DpiQaInfoDto) => ({
  ...data,
  regulationId: regulation.id,
  purifierId: purifier.id,
  developerId: developer.id,
  sampleId: sample.id,
  penetrantId: penetrant.id,
  compliance: Number(compliance),
});

const ultrasonicQaToFormData = ({
  regulation,
  sample,
  device,
  transducer,
  compliance,
  ...data
}: UltrasonicQaDto) => ({
  ...data,
  regulationId: regulation.id,
  sampleId: sample.id,
  deviceId: device.id,
  transducerId: (transducer || {}).id,
  compliance: Number(compliance),
});

export const acceptanceTestReportToFormData = (
  item: TechnicalProjectDto,
  dto: WeldingAcceptanceTestReportDto | BendAcceptanceTestReportDto,
) => {
  const orderItems: [OrderLineDto, OrderItemDto][] = item.order
    .map(orderLine => orderLine.items.map(itm => [orderLine, itm]))
    .flat() as any;
  if (dto.type === 'BendAcceptanceTestReport') {
    const {
      residualFluxDensity,
      chamferType,
      bendingMode,
      productMarking,
      vickersHardness,
      date,
      ...report
    } = dto as BendAcceptanceTestReportDto;

    return {
      ...report,
      residualFluxDensity: Number(residualFluxDensity),
      chamferType: Number(chamferType),
      bendingMode: Number(bendingMode),
      productMarking: Number(productMarking),
      vickersHardness: Number(vickersHardness),
      date: new Date(date),
      dpiQaInfo: report.dpiQaInfo.map(dto => dpiQaToFormData(dto)),
      ultrasonicQaInfo: report.ultrasonicQaInfo.map(dto =>
        ultrasonicQaToFormData(dto),
      ),
    };
  }

  if (dto.type === 'WeldingAcceptanceTestReport') {
    const { date, qaResult, ...report } = dto as WeldingAcceptanceTestReportDto;

    return {
      ...report,
      date: new Date(date),
      qaResult: qaResult
        .map(({ item: itemId, ...result }) => {
          const [orderLine, orderItem] = orderItems.find(
            ([, { id }]) => id === ((itemId as unknown) as string),
          );

          return {
            ...result,
            itemId,
            batchDetailNumber: `${padNumber(item.number)}-${padNumber(
              orderLine.lineNumber,
            )}-${padNumber(orderItem.serialNumber)}`,
          };
        })
        .concat(
          ...createQaResult(
            item,
            Math.max(...qaResult.map(res => res.jointNumber)),
          ),
        ),
      dpiQaInfo: report.dpiQaInfo.map(dto => dpiQaToFormData(dto)),
      ultrasonicQaInfo: report.ultrasonicQaInfo.map(dto =>
        ultrasonicQaToFormData(dto),
      ),
    };
  }

  throw new Error('Неизвестный тип акта ПСИ');
};
