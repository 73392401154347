import { Skeleton, Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router';
import { parse } from 'path-to-regexp';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../redux/root-reducer';
import {
  addTab,
  fetchTabData,
  openInitialTab,
  removeTab,
  reset,
  setActiveKey,
} from '../redux/features/tabs-data';

const { TabPane } = Tabs;
const TabContainer = styled.div`
  & p {
    margin: 0;
  }
  &&& .ant-tabs-tab {
    padding: 10px 10px;
    border: none;
  }
  & > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
  }
  & > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  & > .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] & > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  & > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] & > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #fff;
    background: rgb(240, 242, 245);
  }
  #components-tabs-demo-card-top .code-box-demo {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
  }
  [data-theme='compact'] & > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
  [data-theme='dark'] & > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
  }
  [data-theme='dark'] & > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
  }
  [data-theme='dark'] & > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #141414;
    background: #141414;
  }
`;

export type TabTypes = 'add' | 'list' | 'edit' | 'psi' | 'order' | 'workpieces';

export type OpenTabArg<Types = TabTypes> = Pick<TabProps<Types>, 'key'> &
  Partial<Pick<TabProps<Types>, 'type'>>;

export interface TabProps<T = TabTypes> {
  id?: string;
  key: string;
  label: string;
  closable?: boolean;
  type: T;
  item?: any;
}

export interface UseTabsParams<Types = TabTypes> {
  defaultTabs: TabProps<Types>[];
  defaultActiveKey: string;
  createLabel: (item: any, type?: Types) => string;
  url?: string;
}

export interface TabLabelFactory<Types = TabTypes> {
  (item: any, type?: Types): string;

  loading(): JSX.Element;
}

const selector = createSelector(
  (state: RootStore) => state.tabsData,
  (data) => data,
);

export const useTabs = <Types extends any = TabTypes>({
  defaultTabs,
  defaultActiveKey,
  createLabel,
  url,
}: UseTabsParams<Types>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ key: string; id: string }>();
  const state = useSelector(selector);
  const labelFactory = useMemo(() => {
    (createLabel as any).loading = () => (
      <Skeleton
        active
        loading={true}
        paragraph={false}
        title={{ style: { width: 200, margin: 0 } }}
      />
    );
    return createLabel as TabLabelFactory;
  }, []);
  const [basePath] = useMemo(() => parse(match.path), []);
  const defaultKeys = useMemo(() => defaultTabs.map(({ key }) => key), []);

  useEffect(() => {
    const { key, id } = match.params;

    dispatch(
      openInitialTab({
        url,
        key,
        id,
        labelFactory,
        defaultActiveKey,
        defaultTabs,
      }),
    );

    return () => {
      dispatch(reset());
    };
  }, []);

  const handleClick = useCallback((key) => {
    dispatch(setActiveKey(key));
    history.push(`${basePath}/${key}`);
  }, []);

  const handleEdit = useCallback(
    (key, action) => {
      if (action !== 'remove') {
        return;
      }

      dispatch(removeTab(key));
      if (state.activeKey === key) {
        dispatch(setActiveKey(defaultActiveKey));
        history.push(`${basePath}/${defaultActiveKey}`);
      }
    },
    [state.activeKey],
  );

  const closeTab = useCallback(
    (key) => () => {
      if (state.activeKey === key) {
        dispatch(setActiveKey(defaultActiveKey));
        history.push(`${basePath}/${defaultActiveKey}`);
      }

      if (!defaultKeys.includes(key)) {
        dispatch(removeTab(key));
      }
    },
    [state.activeKey],
  );

  const openTab = useCallback(
    (props: OpenTabArg<Types>) => {
      const tabProps = { ...props, type: (props.type ?? props.key) as Types };
      const id = props.type ? props.key : undefined;
      const key = props.type ? `${props.type}/${props.key}` : props.key;
      const found = state.tabs.find((tab) => tab.key === key);

      if (!found) {
        id
          ? dispatch(
              fetchTabData({ key: tabProps.type, id, url, labelFactory }),
            )
          : dispatch(
              addTab({
                ...tabProps,
                closable: true,
                label: labelFactory(undefined, key as any),
              }),
            );
      }

      dispatch(setActiveKey(key));
      history.push(`${basePath}/${key}`);
    },
    [state.tabs],
  );

  const TabList = useMemo(() => {
    return (
      !!state.tabs.length && (
        <TabContainer>
          <Tabs
            hideAdd
            type="editable-card"
            activeKey={state.activeKey as any}
            onTabClick={handleClick}
            onEdit={handleEdit}
          >
            {state.tabs.map(({ key, label, closable }) => (
              <TabPane key={key} tab={label} closable={closable} />
            ))}
          </Tabs>
        </TabContainer>
      )
    );
  }, [state.tabs, state.activeKey, handleClick, handleEdit]);

  return useMemo(
    () => ({
      TabList,
      setActiveKey: (key) => dispatch(setActiveKey(key)),
      activeKey: state.activeKey,
      closeTab,
      openTab,
    }),
    [TabList, closeTab, openTab, state.activeKey],
  );
};
