import React from 'react';
import { Page } from '../../components/page/Page';
import { Col, Modal, Row, Space } from 'antd';
import { TechnicalProjectsCard } from '../../components/dashboard/TechnicalProjectsCard';
import { SuppliesCard } from '../../components/dashboard/SuppliesCard';

export const DashboardPage = () => {
  return (
    <Page title="Главная" subTitle="Оперативная информация о системе">
      <Row gutter={[25, 25]}>
        <Col span={12}>
          <TechnicalProjectsCard />
        </Col>
        <Col span={12}>
          <SuppliesCard />
        </Col>
      </Row>
    </Page>
  );
};
