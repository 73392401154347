import styled from 'styled-components';
import { Button, Card, Col, Image, List, Row, Typography } from 'antd';
import { URL } from '../../api/routes';
import { format } from 'date-fns';
import React from 'react';
import { DocumentType } from '@tek-crm/backend/dist/domain/document/interfaces/document.type';
import { DownloadButton } from '../shared/DownloadButton';

const toHumanReadableType = type => {
  switch (type) {
    case DocumentType.QualityCertificate:
      return 'Сертификат качества';
    case DocumentType.TechnicalProject:
      return 'Техническое задание';
    case DocumentType.ReceiptControlAct:
      return 'Акт ВКТ';
    case DocumentType.DetailControlAct:
      return 'Акт ВКД';
    case DocumentType.AcceptanceTestReport:
      return 'Акт ПСИ';
    case DocumentType.ProductPassport:
      return 'Паспорт продукции';
  }
};

const ImageCover = styled(Image)`
  object-fit: cover;
`;

const DeletedWrapper = styled.div`
  position: absolute;
  height: calc(100% + 1px);
  width: 100%;
  z-index: 1;
  top: -1px;
`;

const DeletedContent = styled.div`
  width: calc(100% - 15px);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const getFallback = type => {
  switch (type) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return undefined;
    case 'pdf':
      return '/img/pdf-placeholder.png';
    case 'doc':
      return '/img/doc-placeholder.png';
    case 'docx':
      return '/img/docx-placeholder.png';
    case 'xls':
      return '/img/xls-placeholder.png';
    case 'xlsx':
      return '/img/xlsx-placeholder.png';
    default:
      return '/img/generic-placeholder.png';
  }
};

export const ImageItem = ({ item, onDelete, onRestore }) => {
  const [itemType] = item.url.split('.').reverse();

  return (
    <List.Item>
      {item.deleted && (
        <DeletedWrapper>
          <DeletedContent>
            <Typography.Title level={4}>Удалено</Typography.Title>
            <Button type="link" onClick={() => onRestore(item)}>
              Восстановить
            </Button>
          </DeletedContent>
        </DeletedWrapper>
      )}
      <Card
        hoverable
        actions={[
          <DownloadButton
            fileName={`${item.name}.${itemType}`}
            type="link"
            href={`${URL}/${item.url}`}
            disabled={item.deleted}
          >
            Скачать
          </DownloadButton>,
          <Button
            type="link"
            onClick={() => onDelete(item)}
            disabled={item.deleted}
          >
            Удалить
          </Button>,
        ]}
        cover={
          <ImageCover
            placeholder
            fallback={getFallback(itemType)}
            height={200}
            src={`${URL}/${item.url}`}
          />
        }
      >
        <Row>
          <Col flex="100%">
            <b>Название:</b> {item.name}
          </Col>
          <Col flex="100%">
            <b>Тип:</b> {toHumanReadableType(item.type)}
          </Col>
          <Col flex="100%">
            <b>Дата:</b> {format(new Date(item.createdAt), 'dd.MM.yyyy')}
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
